import { createSelector } from 'reselect';
import { AppState } from 'src/app/app.state';

import * as subjectreducer from './common.reducer';

// *************************** PUBLIC API's ****************************
/**
 * Signup store functions
 */
export const getSubState = (state: AppState) => state.common;
export const userDetail = createSelector(getSubState, subjectreducer.getUserDetail);
export const schoolDetail = createSelector(getSubState, subjectreducer.getSchoolDetail);
export const schoolDetailLoading = createSelector(getSubState, subjectreducer.getSchoolDetailLoading);
export const schoolDetailLoaded = createSelector(getSubState, subjectreducer.getSchoolDetailLoaded);
export const schoolDetailFailed = createSelector(getSubState, subjectreducer.getSchoolDetailFailed);

export const passwordLoading = createSelector(getSubState, subjectreducer.getPasswordLoading);
export const passwordLoaded = createSelector(getSubState, subjectreducer.getPasswordLoaded);
export const passwordFailed = createSelector(getSubState, subjectreducer.getPasswordFailed);
export const bulkupload = createSelector(getSubState, subjectreducer.getBulkupload);
export const getUploadDetail = createSelector(getSubState, subjectreducer.getUploadDetail);


export const staffBulkUploadLoading = createSelector(getSubState, subjectreducer.staffBulkUploadLoading);
export const staffBulkUploadLoaded = createSelector(getSubState, subjectreducer.staffBulkUploadLoaded);
export const staffBulkUploadFailed = createSelector(getSubState, subjectreducer.staffBulkUploadFailed);
export const staffBulkUpload = createSelector(getSubState, subjectreducer.staffBulkUpload);
export const staffBulkUploadSuccess = createSelector(getSubState, subjectreducer.staffBulkUploadSuccess);

export const getMonthList = createSelector(getSubState, subjectreducer.getMonthList);

export const academicYearList = createSelector(getSubState, subjectreducer.academicYearList);

export const academicYearMonthList = createSelector(getSubState, subjectreducer.academicYearMonthList);

export const academicYearDetails = createSelector(getSubState, subjectreducer.academicYearDetails);

export const changeAcademicYearSetting = createSelector(getSubState, subjectreducer.changeAcademicYearSetting);

export const academicYear = createSelector(getSubState, subjectreducer.academicYear);

export const downloadStudentTemplate = createSelector(getSubState, subjectreducer.downloadStudentTemplate);
export const downloadStudentTemplateLoading = createSelector(getSubState, subjectreducer.downloadStudentTemplateLoading);


export const downloadfees = createSelector(getSubState, subjectreducer.downloadfees);
export const downloadfeesLoading = createSelector(getSubState, subjectreducer.downloadfeesLoading);