import {Map, Record} from 'immutable';

export interface FilesReportState extends Map<string, any> {

    getFilesReportList: any;
    getFilesReportDetails: any;
    tempGetFilesReportDetails: any;
    fileSentGroupList: any;
    exportFileList: any;
    memberExportList: any;
    reportCount: any;

    reportCountLoading: boolean;
    reportCountLoaded: boolean;
    reportCountFailed: boolean;

    memberExportListLoading: boolean;
    memberExportListLoaded: boolean;
    memberExportListFailed: boolean;

    exportFileListLoading: boolean;
    exportFileListLoaded: boolean;
    exportFileListFailed: boolean;

    getFilesReportListLoading: boolean;
    getFilesReportListLoaded: boolean;
    getFilesReportListFailed: boolean;

    getFilesReportDetailsLoading: boolean;
    getFilesReportDetailsLoaded: boolean;
    getFilesReportDetailsFailed: boolean;

    fileSentGroupListLoading: boolean;
    fileSentGroupListLoaded: boolean;
    fileSentGroupListFailed: boolean;
}

export const filesReportStateRecord = Record({

    getFilesReportList: [],
    getFilesReportDetails: [],
    fileSentGroupList: [],
    exportFileList: [],
    memberExportList: [],
    tempGetFilesReportDetails: [],
    reportCount: [],

    reportCountLoading: false,
    reportCountLoaded: false,
    reportCountFailed: false,

    memberExportListLoading: false,
    memberExportListLoaded: false,
    memberExportListFailed: false,

    exportFileListLoading: false,
    exportFileListLoaded: false,
    exportFileListFailed: false,

    getFilesReportListLoading: false,
    getFilesReportListLoaded: false,
    getFilesReportListFailed: false,

    getFilesReportDetailsLoading: false,
    getFilesReportDetailsLoaded: false,
    getFilesReportDetailsFailed: false,

    fileSentGroupListLoading: false,
    fileSentGroupListLoaded: false,
    fileSentGroupListFailed: false,

});

