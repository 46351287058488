import { Map, Record } from 'immutable';

export interface CommonState extends Map<string, any> {
    userDetail: any;
    schoolDetail: any;
    academicYear: any;
    downloadStudentTemplate: any;

    downloadStudentTemplateLoading: boolean;


    schoolDetailLoading: boolean;
    schoolDetailLoaded: boolean;
    schoolDetailFailed: boolean;

    changePasswordLoading: boolean;
    changePasswordLoaded: boolean;
    changePasswordFailed: boolean;
    bulkUploadLoading: boolean;

    staffBulkUploadLoading: boolean;
    staffBulkUploadLoaded: boolean;
    staffBulkUploadFailed: boolean;

    staffBulkUpload: any;
    staffBulkUploadSuccess: any;
    uploadDetail: any;
    monthList: Array<any>;
    academicYearList: Array<any>;
    academicYearMonthList: Array<any>;
    academicYearDetails: any;
    changeAcademicYearSetting: any;

    downloadfees: any;

    downloadfeesLoading: boolean;

}

export const commonStateRecord = Record({
    downloadfees: [],

    downloadfeesLoading: false,

    userDetail: {},
    schoolDetail: {},
    academicYear: {},
    downloadStudentTemplate: [],

    downloadStudentTemplateLoading: false,

    schoolDetailLoading: false,
    schoolDetailLoaded: false,
    schoolDetailFailed: false,

    changePasswordLoaded: false,
    changePasswordLoading: false,
    changePasswordFailed: false,
    bulkUploadLoading: false,
    uploadDetail: {},


    staffBulkUploadLoading: false,
    staffBulkUploadLoaded: false,
    staffBulkUploadFailed: false,
    staffBulkUpload: [],
    staffBulkUploadSuccess: [],
    monthList: [],
    academicYearList: [],
    academicYearMonthList: [],
    academicYearDetails: {},
    changeAcademicYearSetting: {},


});
