import { Action } from '@ngrx/store';
import {type} from '../../shared/utility';
import {LoginRequestModel} from '../models/login-request.model';
import {ResponseInterface} from '../../shared/interfaces/interface';

export const ActionTypes = {
    DO_LOGIN: type('[Common] do login'),
    DO_LOGIN_SUCCESS: type('[Common] login Success'),
    DO_LOGIN_FAIL: type('[Common] login Fail'),

    DO_RECOVER_PASSWORD: type('[Common] recover'),
    DO_RECOVER_PASSWORD_SUCCESS: type('[Common] recover Success'),
    DO_RECOVER_PASSWORD_FAIL: type('[Common] recover Fail'),
};

/**
 *  login actions.
 */
export class DoLoginAction implements Action {
    type = ActionTypes.DO_LOGIN;

    constructor(public payload: LoginRequestModel) {
    }
}

export class DoLoginSuccess implements Action {
    type = ActionTypes.DO_LOGIN_SUCCESS;

    constructor(public payload: ResponseInterface) {
    }
}

export class DoLoginFail implements Action {
    type = ActionTypes.DO_LOGIN_FAIL;

    constructor(public payload: any) {
    }
}

/**
 *  recover password actions.
 */
export class RecoverPassword implements Action {
    type = ActionTypes.DO_RECOVER_PASSWORD;

    constructor(public payload: LoginRequestModel) {
    }
}

export class RecoverPasswordSuccess implements Action {
    type = ActionTypes.DO_RECOVER_PASSWORD_SUCCESS;

    constructor(public payload: ResponseInterface) {
    }
}

export class RecoverPasswordFail implements Action {
    type = ActionTypes.DO_RECOVER_PASSWORD_FAIL;

    constructor(public payload: any) {
    }
}

export type Actions =
    | DoLoginAction
    | DoLoginSuccess
    | DoLoginFail
    | RecoverPassword
    | RecoverPasswordSuccess
    | RecoverPasswordFail;

