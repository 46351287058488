import { Action } from '@ngrx/store';
import { type } from '../../../../shared/utility';
import { ResponseInterface } from '../../../../shared/interfaces/interface';

export const ActionTypes = {
    VIDEO_REPORT_LIST: type('[VIDEO_REPORT] VIDEO_REPORT_LIST'),
    VIDEO_REPORT_LIST_SUCCESS: type('[VIDEO_REPORT] VIDEO_REPORT_LIST_SUCCESS'),
    VIDEO_REPORT_LIST_FAIL: type('[VIDEO_REPORT] VIDEO_REPORT_LIST_FAIL'),

    VIDEO_REPORT_COUNT: type('[VIDEO_REPORT] VIDEO_REPORT_COUNT'),
    VIDEO_REPORT_COUNT_SUCCESS: type('[VIDEO_REPORT] VIDEO_REPORT_COUNT_SUCCESS'),
    VIDEO_REPORT_COUNT_FAIL: type('[VIDEO_REPORT] VIDEO_REPORT_COUNT_FAIL'),

    GET_VIDEO_DETAILS: type('[VIDEO_REPORT] GET_VIDEO_DETAILS'),
    GET_VIDEO_DETAILS_SUCCESS: type('[VIDEO_REPORT] GET_VIDEO_DETAILS_SUCCESS'),
    GET_VIDEO_DETAILS_FAIL: type('[VIDEO_REPORT] GET_VIDEO_DETAILS_FAIL'),

    VIDEO_MEMBER_DETAILS: type('[VIDEO_REPORT] VIDEO_MEMBER_DETAILS'),
    VIDEO_MEMBER_DETAILS_SUCCESS: type('[VIDEO_REPORT] VIDEO_MEMBER_DETAILS_SUCCESS'),
    VIDEO_MEMBER_DETAILS_FAIL: type('[VIDEO_REPORT] VIDEO_MEMBER_DETAILS_FAIL'),

    Export_VIDEO_DETAILS: type('[VIDEO_REPORT] Export_VIDEO_DETAILS'),
    Export_VIDEO_DETAILS_SUCCESS: type('[VIDEO_REPORT] Export_VIDEO_DETAILS_SUCCESS'),
    Export_VIDEO_DETAILS_FAIL: type('[VIDEO_REPORT] Export_VIDEO_DETAILS_FAIL'),

    SEARCH_VIDEO_REPORT: type('[VIDEO_REPORT] SEARCH_VIDEO_REPORT'),

    Export_VIDEO_REPORT: type('[VIDEO_REPORT] Export_VIDEO_REPORT'),
    Export_VIDEO_REPORT_SUCCESS: type('[VIDEO_REPORT] Export_VIDEO_REPORT_SUCCESS'),
    Export_VIDEO_REPORT_FAIL: type('[VIDEO_REPORT] Export_VIDEO_REPORT_FAIL'),

};
// voice report List
export class VideoReportAction implements Action {
    type = ActionTypes.VIDEO_REPORT_LIST;
    constructor(public payload: any) {
    }
}
export class VideoReportSuccess implements Action {
    type = ActionTypes.VIDEO_REPORT_LIST_SUCCESS;
    constructor(public payload: ResponseInterface) {
    }
}
export class VideoReportFail implements Action {
    type = ActionTypes.VIDEO_REPORT_LIST_FAIL;
    constructor(public payload: any) {
    }
}
// voice report count
export class VideoReportCountAction implements Action {
    type = ActionTypes.VIDEO_REPORT_COUNT;
    constructor(public payload: any) {
    }
}
export class VideoReportCountSuccess implements Action {
    type = ActionTypes.VIDEO_REPORT_COUNT_SUCCESS;
    constructor(public payload: ResponseInterface) {
    }
}
export class VideoReportCountFail implements Action {
    type = ActionTypes.VIDEO_REPORT_COUNT_FAIL;
    constructor(public payload: any) {
    }
}
// get voice details
export class GetVideoDetailsAction implements Action {
    type = ActionTypes.GET_VIDEO_DETAILS;
    constructor(public payload: any) {
    }
}
export class GetVideoDetailsSuccess implements Action {
    type = ActionTypes.GET_VIDEO_DETAILS_SUCCESS;
    constructor(public payload: ResponseInterface) {
    }
}
export class GetVideoDetailsFail implements Action {
    type = ActionTypes.GET_VIDEO_DETAILS_FAIL;
    constructor(public payload: any) {
    }
}
// voice member details
export class VideoMemberDetailsAction implements Action {
    type = ActionTypes.VIDEO_MEMBER_DETAILS;
    constructor(public payload: any) {
    }
}
export class VideoMemberDetailsSuccess implements Action {
    type = ActionTypes.VIDEO_MEMBER_DETAILS_SUCCESS;
    constructor(public payload: ResponseInterface) {
    }
}
export class VideoMemberDetailsFail implements Action {
    type = ActionTypes.VIDEO_MEMBER_DETAILS_FAIL;
    constructor(public payload: any) {
    }
}
// export voice Details
export class ExportVideoDetailsAction implements Action {
    type = ActionTypes.Export_VIDEO_DETAILS;
    constructor(public payload: any) {
    }
}
export class ExportVideoDetailsSuccess implements Action {
    type = ActionTypes.Export_VIDEO_DETAILS_SUCCESS;
    constructor(public payload: ResponseInterface) {
    }
}
export class ExportVideoDetailsFail implements Action {
    type = ActionTypes.Export_VIDEO_DETAILS_FAIL;
    constructor(public payload: any) {
    }
}
export class SearchVideoReportDetails implements Action {
    type = ActionTypes.SEARCH_VIDEO_REPORT;
    constructor(public payload: any) {
    }
}
// voice Export
export class ExportVideoAction implements Action {
    type = ActionTypes.Export_VIDEO_REPORT;
    constructor(public payload: any) {
    }
}
export class ExportVideoSuccess implements Action {
    type = ActionTypes.Export_VIDEO_REPORT_SUCCESS;
    constructor(public payload: ResponseInterface) {
    }
}
export class ExportVideoFail implements Action {
    type = ActionTypes.Export_VIDEO_REPORT_FAIL;
    constructor(public payload: any) {
    }
}

export type Actions =
    | VideoReportAction
    | VideoReportSuccess
    | VideoReportFail
    | ExportVideoAction
    | ExportVideoSuccess
    | ExportVideoFail
    | ExportVideoDetailsAction
    | ExportVideoDetailsSuccess
    | ExportVideoDetailsFail
    | VideoReportCountAction
    | VideoReportCountSuccess
    | VideoReportCountFail
    | GetVideoDetailsAction
    | GetVideoDetailsSuccess
    | GetVideoDetailsFail
    | VideoMemberDetailsAction
    | VideoMemberDetailsSuccess
    | VideoMemberDetailsFail
    | SearchVideoReportDetails;

