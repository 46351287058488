import {
  Component,
  OnInit,
  Input,
  SimpleChanges,
  AfterViewInit,
  OnChanges
} from '@angular/core';

@Component({
  selector: 'app-invoice-form',
  templateUrl: './invoice-form.component.html',
  styleUrls: ['./invoice-form.component.scss']
})
export class InvoiceFormComponent implements OnInit, OnChanges {
  @Input() studentDetail: any;
  @Input() sameStudentDetail: any;
  currentStudent: any;
  schoolName = '';
  constructor() {}

  ngOnInit() {
    this.schoolName = JSON.parse(localStorage.getItem('instituteDetails')).institudeName;
  }
  ngOnChanges() {
    console.log('studentDetail', this.studentDetail);
    console.log('samestudentDetail', this.sameStudentDetail);
    if (this.studentDetail) {
      this.currentStudent = this.studentDetail;
      setTimeout(() => {
        let printContents, popupWin;
        printContents = document.getElementById('invoice').innerHTML;
        popupWin = window.open(
          '',
          '_blank',
          'top=0,left=0,height=100%,width=auto'
        );
        popupWin.document.open();
        popupWin.document.write(`
        <html>
          <head>
            <style>
            </style>
          </head>
          <body onload="window.print();window.close()">${printContents}</body>
        </html>`);
        popupWin.document.close();
      }, 400);
    }
  }
}
