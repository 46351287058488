import { ActivityState, activityStateRecord } from './activity.state';
import * as actions from '../actions/activity.action';

export const initialState: ActivityState = (new activityStateRecord() as unknown) as ActivityState;

export function reducer(
  state = initialState,
  { type, payload }: any
): ActivityState {
  if (!type) {
    return state;
  }

  switch (type) {
    // Get Activity List
    case actions.ActionTypes.GET_ACTIVITY_LIST:
    return Object.assign({}, state, {
    });

    case actions.ActionTypes.GET_ACTIVITY_LIST_SUCCESS:
    console.log('activelist',payload.data);
    // payload.data.map(list => {
    //   list.createdDate = new Date (new Date(list.createdDate).setMinutes(new Date(list.createdDate).getMinutes() - 330))
    // });

    return Object.assign({}, state, {
      activityList: payload.data,
    });

   case actions.ActionTypes.GET_ACTIVITY_LIST_FAIL:
    return Object.assign({}, state, {
      activityList: []
    });
    // Get Activity List
    case actions.ActionTypes.GET_ACTIVITY_LIST_COUNT:
    return Object.assign({}, state, {
      activityLoading: true
    });

    case actions.ActionTypes.GET_ACTIVITY_LIST_COUNT_SUCCESS:
    return Object.assign({}, state, {
      activityListCount: payload.data,
      activityLoading: false
    });

   case actions.ActionTypes.GET_ACTIVITY_LIST_COUNT_FAIL:
    return Object.assign({}, state, {
      activityLoading: false,
    });
     // Get Activity List All
     case actions.ActionTypes.GET_ACTIVITY_LIST_ALL:
     return Object.assign({}, state, {
       activityAllLoading: true
     });
     case actions.ActionTypes.GET_ACTIVITY_LIST_ALL_SUCCESS:
     console.log('final', payload.data);
     return Object.assign({}, state, {
       activityListAll: payload.data,
       activityAllLoading: false
     });
    case actions.ActionTypes.GET_ACTIVITY_LIST_ALL_FAIL:
     return Object.assign({}, state, {
       activityAllLoading: false,
       activityListAll: []
     });

    // Get Institute List 
     case actions.ActionTypes.INSTITUTE_LIST:
     return Object.assign({}, state, {
       instituteListLoading: true
     });
     case actions.ActionTypes.INSTITUTE_LIST_SUCCESS:
     console.log('instituteList', payload.data);
     
     return Object.assign({}, state, {
      instituteList: payload.data,
      instituteListLoading: false
     });
    case actions.ActionTypes.INSTITUTE_LIST_FAIL:
     return Object.assign({}, state, {
      instituteListLoading: false,
      instituteList: []
     });
    default: {
      return state;
    }
  }
}

/**
 * export values
 */

export const activityAllLoading = (state: ActivityState) => state.activityAllLoading;
export const activityList = (state: ActivityState) => state.activityList;
export const activityListCount = (state: ActivityState) => state.activityListCount;
export const activityListAll = (state: ActivityState) => state.activityListAll;
export const instituteListLoading = (state: ActivityState) => state.instituteListLoading;
export const instituteList = (state: ActivityState) => state.instituteList;
