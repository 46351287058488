import {Map, Record} from 'immutable';

export interface DashboardState extends Map<string, any> {
    dashboardCount: any;
    renewalDetails: any;

    dashboardCountLoading: boolean;
    dashboardCountLoaded: boolean;
    dashboardCountFailed: boolean;

    renewalDetailsLoading: boolean;
    renewalDetailsLoaded: boolean;
    renewalDetailsFailed: boolean;
}

export const dashBoardStateRecord = Record({
    dashboardCount: [],
    renewalDetails: [],
    dashboardCountLoading: false,
    dashboardCountLoaded: false,
    dashboardCountFailed: false,

    renewalDetailsLoading: false,
    renewalDetailsLoaded: false,
    renewalDetailsFailed: false,
});
