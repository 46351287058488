import { Action } from '@ngrx/store';
import { type } from '../../shared/utility';
import { ResponseInterface } from '../../shared/interfaces/interface';

export const ActionTypes = {
  GET_SCHOOL_DETAIL: type('[common] get subjects'),
  GET_SCHOOL_DETAIL_SUCCESS: type('[common] get subjects Success'),
  GET_SCHOOL_DETAIL_FAIL: type('[common] get subjects Fail'),

  CHANGE_PASSWORD: type('[common] change password'),
  CHANGE_PASSWORD_SUCCESS: type('[common] change password Success'),
  CHANGE_PASSWORD_FAIL: type('[common] change password Fail'),

  BULK_STUDENT_UPLOAD: type('[common] upload student bulk'),
  BULK_STUDENT_UPLOAD_SUCCESS: type('[common] upload student bulk Success'),
  BULK_STUDENT_UPLOAD_FAIL: type('[common] upload student bulk Fail'),

  BULK_STAFF_UPLOAD: type('[common] upload staff bulk'),
  BULK_STAFF_UPLOAD_SUCCESS: type('[common] upload staff bulk Success'),
  BULK_STAFF_UPLOAD_FAIL: type('[common] upload staff bulk Fail'),

  STANDARD_LIST: type('[students] get standards list'),
  STANDARD_LIST_SUCCESS: type('[students] get standards list Success'),
  STANDARD_LIST_FAIL: type('[students] get standards list Fail'),

  GET_RELIGION_LIST: type('[students] get religions list'),
  GET_RELIGION_LIST_SUCCESS: type('[students] get religions list Success'),
  GET_RELIGION_LIST_FAIL: type('[students] get religions list Fail'),

  GET_COMMUNITY_LIST: type('[students] get community lists'),
  GET_COMMUNITY_LIST_SUCCESS: type('[students] get community lists Success'),
  GET_COMMUNITY_LIST_FAIL: type('[students] get community lists Fail'),

  BLOOD_GROUP_LIST: type('[students] blood group lists'),
  BLOOD_GROUP_LIST_SUCCESS: type('[students] blood group lists Success'),
  BLOOD_GROUP_LIST_FAIL: type('[students] blood group lists Fail'),

  SECTION_LIST: type('[students] sections list'),
  SECTION_LIST_SUCCESS: type('[students] sections list Success'),
  SECTION_LIST_FAIL: type('[students] sections list Fail'),

  GET_MONTH_LIST: type('[month] month list'),
  GET_MONTH_LIST_SUCCESS: type('[month] month list Success'),
  GET_MONTH_LIST_FAIL: type('[month] month list Fail'),

  ACADEMIC_YEAR_LIST_ACTION: type('[month] academic year list action'),
  ACADEMIC_YEAR_LIST_ACTION_SUCCESS: type('[month] academic year list action Success'),
  ACADEMIC_YEAR_LIST_ACTION_FAIL: type('[month] academic year list action Fail'),

  ACADEMIC_YEAR_MONTH_LIST_ACTION: type('[month] academic year month list action'),
  ACADEMIC_YEAR_MONTH_LIST_ACTION_SUCCESS: type('[month] academic year month list action Success'),
  ACADEMIC_YEAR_MONTH_LIST_ACTION_FAIL: type('[month] academic year month list action Fail'),

  GET_ACADEMIC_YEAR_SETTING_ACTION: type('[month] get academic year setting action'),
  GET_ACADEMIC_YEAR_SETTING_ACTION_SUCCESS: type('[month] get academic year setting action Success'),
  GET_ACADEMIC_YEAR_SETTING_ACTION_FAIL: type('[month] get academic year setting action Fail'),

  CHANGE_ACADEMIC_YEAR_SETTING: type('[common] change academic year setting'),
  CHANGE_ACADEMIC_YEAR_SETTING_SUCCESS: type('[common] change academic year setting Success'),
  CHANGE_ACADEMIC_YEAR_SETTING_FAIL: type('[common] change academic year setting Fail'),

  DOWNLOAD_STUDENT_TEMPLATE: type('[students] Download student template'),
  DOWNLOAD_STUDENT_TEMPLATE_SUCCESS: type('[students] Download student template Success'),
  DOWNLOAD_STUDENT_TEMPLATE_FAIL: type('[students] Download student template Fail'),

  GET_CALL_FLOW_PDF: type('[common] get call flow pdf'),
  GET_CALL_FLOW_PDF_SUCCESS: type('[common] get call flow pdf Success'),
  GET_CALL_FLOW_PDF_FAIL: type('[common] get call flow pdf Fail'),

  GET_IVR_CODE_PDF: type('[common] get ivr code pdf'),
  GET_IVR_CODE_PDF_SUCCESS: type('[common] get ivr code pdf Success'),
  GET_IVR_CODE_PDF_FAIL: type('[common] get ivr code pdf Fail'),

  GET_APP_USER_PDF: type('[common] get app user pdf'),
  GET_APP_USER_PDF_SUCCESS: type('[common] get app user pdf Success'),
  GET_APP_USER_PDF_FAIL: type('[common] get app user pdf Fail'),

  GET_YOUTUBE_MANUAL_PDF: type('[common] get youtube upload manual pdf'),
  GET_YOUTUBE_MANUAL_PDF_SUCCESS: type('[common] get youtube upload manual pdf Success'),
  GET_YOUTUBE_MANUAL_PDF_FAIL: type('[common] get youtube upload manual pdf Fail'),

  FEE_INVOICE_DATA: type('[common] fee invoice data'),
  FEE_INVOICE_DATA_SUCCESS: type('[common] fee invoice data Success'),
  FEE_INVOICE_DATA_FAIL: type('[common] fee invoice data Fail'),

  DOWNLOAD_FEES: type('[common] Download fees'),
  DOWNLOAD_FEES_SUCCESS: type('[common] Download fees Success'),
  DOWNLOAD_FEES_FAIL: type('[common] Download fees Fail'),


};

/**
 *  get school detail actions.
 */
export class Downloadfees implements Action {
  type = ActionTypes.DOWNLOAD_FEES;

  // constructor(public payload: any) {
  // console.log('inside');
  // }
}
export class DownloadfeesSuccess implements Action {
  type = ActionTypes.DOWNLOAD_FEES_SUCCESS;

  constructor(public payload: ResponseInterface) { }
}
export class DownloadfeesFail implements Action {
  type = ActionTypes.DOWNLOAD_FEES_FAIL;

  constructor(public payload: any) { }
}
export class GetSchoolDetail implements Action {
  type = ActionTypes.GET_SCHOOL_DETAIL;

  constructor(public payload = null) {
    // console.log('inside');
  }
}
export class GetSchoolDetailSuccess implements Action {
  type = ActionTypes.GET_SCHOOL_DETAIL_SUCCESS;

  constructor(public payload: ResponseInterface) { }
}
export class GetSchoolDetailFail implements Action {
  type = ActionTypes.GET_SCHOOL_DETAIL_FAIL;

  constructor(public payload: any) { }
}
/**
 *  change password actions.
 */
export class ChangePassword implements Action {
  type = ActionTypes.CHANGE_PASSWORD;

  constructor(public payload: any) { }
}

export class ChangePasswordSuccess implements Action {
  type = ActionTypes.CHANGE_PASSWORD_SUCCESS;

  constructor(public payload: ResponseInterface) { }
}

export class ChangePasswordFail implements Action {
  type = ActionTypes.CHANGE_PASSWORD_FAIL;

  constructor(public payload: any) { }
}
/**
 *
 */
export class BulkStudentUpload implements Action {
  type = ActionTypes.BULK_STUDENT_UPLOAD;

  constructor(public payload: any) { }
}

export class BulkStudentUploadSuccess implements Action {
  type = ActionTypes.BULK_STUDENT_UPLOAD_SUCCESS;

  constructor(public payload: ResponseInterface) { }
}
export class BulkStudentUploadFail implements Action {
  type = ActionTypes.BULK_STUDENT_UPLOAD_FAIL;

  constructor(public payload: ResponseInterface) { }
}

/**
 *
 */
export class BulkStaffUpload implements Action {
  type = ActionTypes.BULK_STAFF_UPLOAD;
  constructor(public payload: any) { }
}
export class BulkStaffUploadSuccess implements Action {
  type = ActionTypes.BULK_STAFF_UPLOAD_SUCCESS;
  constructor(public payload: ResponseInterface) { }
}
export class BulkStaffUploadFail implements Action {
  type = ActionTypes.BULK_STAFF_UPLOAD_FAIL;
  constructor(public payload: ResponseInterface) { }
}

// students standard(class he is studying) list
export class StandardListAction implements Action {
  type = ActionTypes.STANDARD_LIST;
  constructor(public payload = null) { }
}
export class StandardListSuccess implements Action {
  type = ActionTypes.STANDARD_LIST_SUCCESS;
  constructor(public payload: ResponseInterface) { }
}
export class StandardListFail implements Action {
  type = ActionTypes.STANDARD_LIST_FAIL;
  constructor(public payload: any) { }
}
// students section list
export class SectionListAction implements Action {
  type = ActionTypes.SECTION_LIST;
  constructor(public payload: any) { }
}
export class SectionListSuccess implements Action {
  type = ActionTypes.SECTION_LIST_SUCCESS;
  constructor(public payload: ResponseInterface) { }
}
export class SectionListFail implements Action {
  type = ActionTypes.SECTION_LIST_FAIL;
  constructor(public payload: any) { }
}
// get community list
export class CommunityListAction implements Action {
  type = ActionTypes.GET_COMMUNITY_LIST;
  constructor(public payload = null) { }
}
export class CommunityListSuccess implements Action {
  type = ActionTypes.GET_COMMUNITY_LIST_SUCCESS;
  constructor(public payload: ResponseInterface) { }
}
export class CommunityListFail implements Action {
  type = ActionTypes.GET_COMMUNITY_LIST_FAIL;
  constructor(public payload: any) { }
}

// get blood group list
export class BloodGroupListAction implements Action {
  type = ActionTypes.BLOOD_GROUP_LIST;
  constructor(public payload = null) { }
}
export class BloodGroupListSuccess implements Action {
  type = ActionTypes.BLOOD_GROUP_LIST_SUCCESS;
  constructor(public payload: ResponseInterface) { }
}
export class BloodGroupListFail implements Action {
  type = ActionTypes.BLOOD_GROUP_LIST_FAIL;
  constructor(public payload: any) { }
}
// get religion list
export class ReligionListAction implements Action {
  type = ActionTypes.GET_RELIGION_LIST;
  constructor(public payload = null) { }
}
export class ReligionListSuccess implements Action {
  type = ActionTypes.GET_RELIGION_LIST_SUCCESS;
  constructor(public payload: ResponseInterface) { }
}
export class ReligionListFail implements Action {
  type = ActionTypes.GET_RELIGION_LIST_FAIL;
  constructor(public payload: any) { }
}
// get month
export class MonthListAction implements Action {
  type = ActionTypes.GET_MONTH_LIST;
  constructor(public payload = null) { }
}
export class MonthListActionSuccess implements Action {
  type = ActionTypes.GET_MONTH_LIST_SUCCESS;
  constructor(public payload: ResponseInterface) { }
}
export class MonthListActionFail implements Action {
  type = ActionTypes.GET_MONTH_LIST_FAIL;
  constructor(public payload: any) { }
}
// AcademicYearList

export class AcademicYearListAction implements Action {
  type = ActionTypes.ACADEMIC_YEAR_LIST_ACTION;
  constructor(public payload = null) { }
}
export class AcademicYearListActionSuccess implements Action {
  type = ActionTypes.ACADEMIC_YEAR_LIST_ACTION_SUCCESS;
  constructor(public payload: ResponseInterface) { }
}
export class AcademicYearListActionFail implements Action {
  type = ActionTypes.ACADEMIC_YEAR_LIST_ACTION_FAIL;
  constructor(public payload: any) { }
}

export class AcademicYearMonthListAction implements Action {
  type = ActionTypes.ACADEMIC_YEAR_MONTH_LIST_ACTION;
  constructor(public payload = null) { }
}
export class AcademicYearMonthListActionSuccess implements Action {
  type = ActionTypes.ACADEMIC_YEAR_MONTH_LIST_ACTION_SUCCESS;
  constructor(public payload: ResponseInterface) { }
}
export class AcademicYearMonthListActionFail implements Action {
  type = ActionTypes.ACADEMIC_YEAR_MONTH_LIST_ACTION_FAIL;
  constructor(public payload: any) { }
}

export class GetAcademicYearSettingAction implements Action {
  type = ActionTypes.GET_ACADEMIC_YEAR_SETTING_ACTION;
  constructor(public payload = null) { }
}
export class GetAcademicYearSettingActionSuccess implements Action {
  type = ActionTypes.GET_ACADEMIC_YEAR_SETTING_ACTION_SUCCESS;
  constructor(public payload: ResponseInterface) { }
}
export class GetAcademicYearSettingActionFail implements Action {
  type = ActionTypes.GET_ACADEMIC_YEAR_SETTING_ACTION_FAIL;
  constructor(public payload: any) { }
}
export class ChangeAcademicYearSetting implements Action {
  type = ActionTypes.CHANGE_ACADEMIC_YEAR_SETTING;

  constructor(public payload: any) { }
}

export class ChangeAcademicYearSettingSuccess implements Action {
  type = ActionTypes.CHANGE_ACADEMIC_YEAR_SETTING_SUCCESS;

  constructor(public payload: ResponseInterface) { }
}

export class ChangeAcademicYearSettingFail implements Action {
  type = ActionTypes.CHANGE_ACADEMIC_YEAR_SETTING_FAIL;

  constructor(public payload: any) { }
}
// download student template
export class DownloadStuTemplate implements Action {
  type = ActionTypes.DOWNLOAD_STUDENT_TEMPLATE;

  // constructor(public payload: any) {
  // console.log('inside');
  // }
}
export class DownloadStuTemplateSuccess implements Action {
  type = ActionTypes.DOWNLOAD_STUDENT_TEMPLATE_SUCCESS;

  constructor(public payload: ResponseInterface) { }
}
export class DownloadStuTemplateFail implements Action {
  type = ActionTypes.DOWNLOAD_STUDENT_TEMPLATE_FAIL;

  constructor(public payload: any) { }
}
// Call Flow Pdf
export class GetCallFlowPdf implements Action {
  type = ActionTypes.GET_CALL_FLOW_PDF;

  constructor(public payload: any) { }
}

export class GetCallFlowPdfSuccess implements Action {
  type = ActionTypes.GET_CALL_FLOW_PDF_SUCCESS;

  constructor(public payload: ResponseInterface) { }
}

export class GetCallFlowPdfFail implements Action {
  type = ActionTypes.GET_CALL_FLOW_PDF_FAIL;

  constructor(public payload: any) { }
}
// IVR Code Pdf
export class GetIvrCodePdf implements Action {
  type = ActionTypes.GET_IVR_CODE_PDF;

  constructor(public payload: any) { }
}

export class GetIvrCodePdfSuccess implements Action {
  type = ActionTypes.GET_IVR_CODE_PDF_SUCCESS;

  constructor(public payload: ResponseInterface) { }
}

export class GetIvrCodePdfFail implements Action {
  type = ActionTypes.GET_IVR_CODE_PDF_FAIL;

  constructor(public payload: any) { }
}
// App User Pdf
export class GetAppUserPdf implements Action {
  type = ActionTypes.GET_APP_USER_PDF;

  constructor(public payload: any) { }
}

export class GetAppUserPdfSuccess implements Action {
  type = ActionTypes.GET_APP_USER_PDF_SUCCESS;

  constructor(public payload: ResponseInterface) { }
}

export class GetAppUserPdfFail implements Action {
  type = ActionTypes.GET_APP_USER_PDF_FAIL;

  constructor(public payload: any) { }
}


export class getyoutubemanualPdf implements Action {
  type = ActionTypes.GET_YOUTUBE_MANUAL_PDF;

  constructor(public payload: any) { }
}

export class getyoutubemanualPdfSuccess implements Action {
  type = ActionTypes.GET_YOUTUBE_MANUAL_PDF_SUCCESS;

  constructor(public payload: ResponseInterface) { }
}

export class getyoutubemanualPdfFail implements Action {
  type = ActionTypes.GET_YOUTUBE_MANUAL_PDF_FAIL;

  constructor(public payload: any) { }
}

export class FeeInvoiceData implements Action {
  type = ActionTypes.FEE_INVOICE_DATA;

  constructor(public payload: any) { }
}

export class FeeInvoiceDataSuccess implements Action {
  type = ActionTypes.FEE_INVOICE_DATA_SUCCESS;

  constructor(public payload: ResponseInterface) { }
}

export class FeeInvoiceDataFail implements Action {
  type = ActionTypes.FEE_INVOICE_DATA_FAIL;

  constructor(public payload: any) { }
}

export type Actions =
  | GetSchoolDetail
  | DownloadStuTemplate
  | DownloadStuTemplateSuccess
  | DownloadStuTemplateFail
  | GetSchoolDetailSuccess
  | GetSchoolDetailFail
  | ChangePassword
  | ChangePasswordSuccess
  | ChangePasswordFail
  | BulkStudentUpload
  | BulkStudentUploadSuccess
  | BulkStudentUploadFail
  | StandardListAction
  | StandardListSuccess
  | StandardListFail
  | BulkStaffUpload
  | BulkStaffUploadSuccess
  | BulkStaffUploadFail

  | Downloadfees
  | DownloadfeesSuccess
  | DownloadfeesFail;
