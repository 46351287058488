import { PdfFilesReportState, pdffilesReportStateRecord } from './pdffiles.state';
import * as actions from '../actions/pdffiles.action';
import {filter} from 'lodash';
import { PDfDetailedReportFileResponseModel } from '../models/detailed-report-pdffile-response.model';
import { PDFFileListResponseModel } from '../../pdffiles/models/filelistpdf-response.model';

export const initialState: PdfFilesReportState = (new pdffilesReportStateRecord() as unknown) as PdfFilesReportState;

export function reducer(state = initialState, { type, payload }: any): PdfFilesReportState {
  if (!type) {
    return state;
  }

  switch (type) {

    /**
     * get files list
     */
    case actions.ActionTypes.GET_FILES_REPORT_LIST:
      return Object.assign({}, state, {
        getPdfFilesReportListLoading: true,
        getPdfFilesReportListLoaded: false,
        getPdfFilesReportListFailed: false,
      });
    case actions.ActionTypes.GET_FILES_REPORT_LIST_SUCCESS:
    const fileReportList = payload.data.map(data => {
 
      const tempFileReportList = new PDFFileListResponseModel(data);
       return tempFileReportList;
      });
      return Object.assign({}, state, {
        getPdfFilesReportList: fileReportList,
        getPdfFilesReportListLoading: false,
        getPdfFilesReportListLoaded: true,
        getPdfFilesReportListFailed: false,
      });
    case actions.ActionTypes.GET_FILES_REPORT_LIST_FAIL:
      return Object.assign({}, state, {
        getPdfFilesReportListLoading: false,
        getPdfFilesReportListLoaded: false,
        getPdfFilesReportListFailed: true,

      });

  //     /**
  //    * get files report details
  //    */
  //     case actions.ActionTypes.GET_FILES_REPORT_DETAILS:
  //   return Object.assign({}, state, {
  //       getFilesReportDetails: [],
  //       tempGetFilesReportDetails: [],
  //       getFilesReportDetailsLoading:  true,
  //       getFilesReportDetailsLoaded: false,
  //       getFilesReportDetailsFailed: false,
  //   });
  // case actions.ActionTypes.GET_FILES_REPORT_DETAILS_SUCCESS:
  // const fileReportDetails = payload.data.map(data => {
  //   console.log('reducer', data);
  //   const tempFileReportDetails = new PDfDetailedReportFileResponseModel(data);
  //    return tempFileReportDetails;
  //   });
  //   return Object.assign({}, state, {
  //       getFilesReportDetails: fileReportDetails,
  //       tempGetFilesReportDetails: fileReportDetails,
  //       getFilesReportDetailsLoading:  false,
  //       getFilesReportDetailsLoaded: true,
  //       getFilesReportDetailsFailed: false,
  //   });
  // case actions.ActionTypes.GET_FILES_REPORT_DETAILS_FAIL:
  //   return Object.assign({}, state, {
  //       getFilesReportDetailsLoading:  false,
  //       getFilesReportDetailsLoaded: false,
  //       getFilesReportDetailsFailed: true,

  //   });

  //    /**
  //    * get files report details
  //    */
  //   case actions.ActionTypes.FILE_SENT_GROUP_LIST:
  //   return Object.assign({}, state, {
  //       fileSentGroupListLoading:  true,
  //       fileSentGroupListLoaded: false,
  //       fileSentGroupListFailed: false,
  //   });
  // case actions.ActionTypes.FILE_SENT_GROUP_LIST_SUCCESS:
  // let x = payload.data.value;
  // const groupList = x.split(',');
  // console.log('groupList', groupList);
  //   return Object.assign({}, state, {
  //       fileSentGroupList: groupList,
  //       fileSentGroupListLoading:  false,
  //       fileSentGroupListLoaded: true,
  //       fileSentGroupListFailed: false,
  //   });
  // case actions.ActionTypes.FILE_SENT_GROUP_LIST_FAIL:
  //   return Object.assign({}, state, {
  //       fileSentGroupListLoading:  false,
  //       fileSentGroupListLoaded: false,
  //       fileSentGroupListFailed: true,

  //   });
  //   // file export
  //     case actions.ActionTypes.EXPORT_FILE_LIST:
  //         return Object.assign({}, state, {
  //             exportFileListLoading: true,
  //             exportFileListLoaded: false,
  //             exportFileListFailed: false,
  //         });
  //     case actions.ActionTypes.EXPORT_FILE_LIST_SUCCESS:
  //         return Object.assign({}, state, {
  //             exportFileList: payload.data,
  //             exportFileListLoading: false,
  //             exportFileListLoaded: true,
  //             exportFileListFailed: false,
  //         });
  //     case actions.ActionTypes.EXPORT_FILE_LIST_FAIL:
  //         return Object.assign({}, state, {
  //             exportFileListLoading: false,
  //             exportFileListLoaded: false,
  //             exportFileListFailed: true,
  //         });
  //         // member details
  //     case actions.ActionTypes.MEMBER_EXPORT:
  //         return Object.assign({}, state, {
  //             memberExportListLoading: true,
  //             memberExportListLoaded: false,
  //             memberExportListFailed: false,
  //         });
  //     case actions.ActionTypes.MEMBER_EXPORT_SUCCESS:
  //         return Object.assign({}, state, {
  //             memberExportList: payload.data,
  //             memberExportListLoading: false,
  //             memberExportListLoaded: true,
  //             memberExportListFailed: false,
  //         });
  //     case actions.ActionTypes.MEMBER_EXPORT_FAIL:
  //         return Object.assign({}, state, {
  //             memberExportListLoading: false,
  //             memberExportListLoaded: false,
  //             memberExportListFailed: true,
  //         });
          // report list count
      case actions.ActionTypes.GET_FILES_REPORT_COUNT_LIST:
          return Object.assign({}, state, {
              PdfreportCountLoading: true,
              PdfreportCountLoaded: false,
              PdfreportCountFailed: false,
          });
      case actions.ActionTypes.GET_FILES_REPORT_COUNT_LIST_SUCCESS:
        console.log('payload.count', payload.count);
          return Object.assign({}, state, {
              PdfreportCount: payload.count,
              PdfreportCountLoading: false,
              PdfreportCountLoaded: true,
              PdfreportCountFailed: false,
          });
      case actions.ActionTypes.GET_FILES_REPORT_COUNT_LIST_FAIL:
          return Object.assign({}, state, {
              PdfreportCountLoading: false,
              PdfreportCountLoaded: false,
              PdfreportCountFailed: true,
          });
      //     // search member
      // case actions.ActionTypes.SEARCH_MEMBER_LIST:
      //     let tempMemberSearch = state.tempGetFilesReportDetails;
      //     const search = payload.toLowerCase();
      //     tempMemberSearch = filter(tempMemberSearch, (item) => {
      //             return item.studentName.toLowerCase().includes(search);
      //     });
      //     return Object.assign({}, state, {
      //         getFilesReportDetails: tempMemberSearch,
      //     });
    default: {
      return state;
    }
  }
}

/**
 * export values (files list)
 */
export const getPdfFilesReportList = (state: PdfFilesReportState) => state.getPdfFilesReportList;
export const getPdfFilesReportListLoading = (state: PdfFilesReportState) => state.getPdfFilesReportListLoading;
export const getPdfFilesReportListLoaded = (state: PdfFilesReportState) => state.getPdfFilesReportListLoaded;
export const getPdfFilesReportListFailed = (state: PdfFilesReportState) => state.getPdfFilesReportListFailed;

// /**
//  * export values (files sent details)
//  */
// export const getFilesReportDetails = (state: PdfFilesReportState) => state.getFilesReportDetails;
// export const getFilesReportDetailsLoading = (state: PdfFilesReportState) => state.getFilesReportDetailsLoading;
// export const getFilesReportDetailsLoaded = (state: PdfFilesReportState) => state.getFilesReportDetailsLoaded;
// export const getFilesReportDetailsFailed = (state: PdfFilesReportState) => state.getFilesReportDetailsFailed;

// /**
//  * export values (files sent group list)
//  */
// export const fileSentGroupList = (state: PdfFilesReportState) => state.fileSentGroupList;
// export const fileSentGroupListLoading = (state: PdfFilesReportState) => state.fileSentGroupListLoading;
// export const fileSentGroupListLoaded = (state: PdfFilesReportState) => state.fileSentGroupListLoaded;
// export const fileSentGroupListFailed = (state: PdfFilesReportState) => state.fileSentGroupListFailed;
// /**
//  * export file list
//  */
// export const exportFileList = (state: PdfFilesReportState) => state.exportFileList;
// export const exportFileListLoading = (state: PdfFilesReportState) => state.exportFileListLoading;
// export const exportFileListLoaded = (state: PdfFilesReportState) => state.exportFileListLoaded;
// export const exportFileListFailed = (state: PdfFilesReportState) => state.exportFileListFailed;
// /**
//  * member export file list
//  */
// export const memberExportList = (state: PdfFilesReportState) => state.memberExportList;
// export const memberExportListLoading = (state: PdfFilesReportState) => state.memberExportListLoading;
// export const memberExportListLoaded = (state: PdfFilesReportState) => state.memberExportListLoaded;
// export const memberExportListFailed = (state: PdfFilesReportState) => state.memberExportListFailed;
// /**
//  * member export file list
//  */
export const PdfreportCount = (state: PdfFilesReportState) => state.PdfreportCount;
export const PdfreportCountLoading = (state: PdfFilesReportState) => state.PdfreportCountLoading;
export const PdfreportCountLoaded = (state: PdfFilesReportState) => state.PdfreportCountLoaded;
export const PdfreportCountFailed = (state: PdfFilesReportState) => state.PdfreportCountFailed;
