import { CommonState, commonStateRecord } from './common.state';
import * as actions from '../actions/common.action';
import { SchoolDetailModel } from '../models/school-detail.model';

export const initialState: CommonState = (new commonStateRecord() as unknown) as CommonState;

export function reducer(
  state = initialState,
  { type, payload }: any
): CommonState {
  if (!type) {
    return state;
  }

  switch (type) {
    /**
     * get school detail reducer case function
     */

    case actions.ActionTypes.DOWNLOAD_FEES:
      return Object.assign({}, state, {
        downloadfeesLoading: true,
      });

    case actions.ActionTypes.DOWNLOAD_FEES_SUCCESS:
      return Object.assign({}, state, {
        downloadfees: payload.data,
        downloadfeesLoading: false,
      });

    case actions.ActionTypes.DOWNLOAD_FEES_FAIL:
      return Object.assign({}, state, {
        downloadfeesLoading: false,
      });

    case actions.ActionTypes.GET_SCHOOL_DETAIL:
      return Object.assign({}, state, {
        schoolDetailLoading: true,
        schoolDetailLoaded: false,
        schoolDetailFailed: false
      });

    case actions.ActionTypes.GET_SCHOOL_DETAIL_SUCCESS:
      return Object.assign({}, state, {
        schoolDetailLoading: false,
        schoolDetailLoaded: true,
        schoolDetailFailed: false,
        schoolDetail: payload.institute,
        userDetail: payload.user,
        academicYear: payload.academicYear,
      });

    case actions.ActionTypes.GET_SCHOOL_DETAIL_FAIL:
      return Object.assign({}, state, {
        schoolDetailLoading: false,
        schoolDetailLoaded: true,
        schoolDetailFailed: true
      });
    /**
     * change password reducer case function
     */
    case actions.ActionTypes.CHANGE_PASSWORD:
      return Object.assign({}, state, {
        changePasswordLoading: true,
        changePasswordLoaded: false,
        changePasswordFailed: false
      });

    case actions.ActionTypes.CHANGE_PASSWORD_SUCCESS:
      return Object.assign({}, state, {
        changePasswordLoading: false,
        changePasswordLoaded: true,
        changePasswordFailed: false
      });

    case actions.ActionTypes.CHANGE_PASSWORD_FAIL:
      return Object.assign({}, state, {
        changePasswordLoading: false,
        changePasswordLoaded: true,
        changePasswordFailed: true
      });
    /**
     * upload bulk student reducer case function
     */
    case actions.ActionTypes.BULK_STUDENT_UPLOAD:
      return Object.assign({}, state, {
        bulkUploadLoading: true,
        uploadDetail: {}
      });

    case actions.ActionTypes.BULK_STUDENT_UPLOAD_SUCCESS:
      return Object.assign({}, state, {
        bulkUploadLoading: false,
        uploadDetail: payload
      });

    case actions.ActionTypes.BULK_STUDENT_UPLOAD_FAIL:
      return Object.assign({}, state, {
        bulkUploadLoading: false,
        // uploadDetail: {}
      });

    /**
     * upload bulk staff reducer case function
     */
    case actions.ActionTypes.BULK_STAFF_UPLOAD:
      return Object.assign({}, state, {
        staffBulkUploadSuccess: [],
        staffBulkUpload: [],
        staffBulkUploadLoading: false,
        staffBulkUploadLoaded: false,
        staffBulkUploadFailed: false
      });

    case actions.ActionTypes.BULK_STAFF_UPLOAD_SUCCESS:
      return Object.assign({}, state, {
        staffBulkUploadLoading: false,
        staffBulkUploadLoaded: false,
        staffBulkUploadFailed: false,
        staffBulkUploadSuccess: payload,
        staffBulkUpload: payload.notUploadStaffData
      });

    case actions.ActionTypes.BULK_STAFF_UPLOAD_FAIL:
      return Object.assign({}, state, {
        staffBulkUploadSuccess: [],
        staffBulkUpload: [],
        staffBulkUploadLoading: false,
        staffBulkUploadLoaded: false,
        staffBulkUploadFailed: false
      });
    /**
     *  month list
     */
    case actions.ActionTypes.GET_MONTH_LIST:
      return Object.assign({}, state, {});

    case actions.ActionTypes.GET_MONTH_LIST_SUCCESS:
      return Object.assign({}, state, {
        monthList: payload.data
      });

    case actions.ActionTypes.GET_MONTH_LIST_FAIL:
      return Object.assign({}, state, {
        monthList: []
      });
    case actions.ActionTypes.ACADEMIC_YEAR_LIST_ACTION:
      return Object.assign({}, state, {});

    case actions.ActionTypes.ACADEMIC_YEAR_LIST_ACTION_SUCCESS:
      return Object.assign({}, state, {
        academicYearList: payload.data
      });

    case actions.ActionTypes.ACADEMIC_YEAR_LIST_ACTION_FAIL:
      return Object.assign({}, state, {
        academicYearList: []
      });

    case actions.ActionTypes.ACADEMIC_YEAR_MONTH_LIST_ACTION:
      return Object.assign({}, state, {});

    case actions.ActionTypes.ACADEMIC_YEAR_MONTH_LIST_ACTION_SUCCESS:
      return Object.assign({}, state, {
        academicYearMonthList: payload.data
      });

    case actions.ActionTypes.ACADEMIC_YEAR_MONTH_LIST_ACTION_FAIL:
      return Object.assign({}, state, {
        academicYearMonthList: []
      });
    // Get Academic year setting
    case actions.ActionTypes.GET_ACADEMIC_YEAR_SETTING_ACTION:
      return Object.assign({}, state, {});

    case actions.ActionTypes.GET_ACADEMIC_YEAR_SETTING_ACTION_SUCCESS:
      return Object.assign({}, state, {
        academicYearDetails: payload.data
      });

    case actions.ActionTypes.GET_ACADEMIC_YEAR_SETTING_ACTION_FAIL:
      return Object.assign({}, state, {
      });

    case actions.ActionTypes.CHANGE_ACADEMIC_YEAR_SETTING:
      return Object.assign({}, state, {});

    case actions.ActionTypes.CHANGE_ACADEMIC_YEAR_SETTING_SUCCESS:
      return Object.assign({}, state, {
        changeAcademicYearSetting: payload
      });

    case actions.ActionTypes.CHANGE_ACADEMIC_YEAR_SETTING_FAIL:
      return Object.assign({}, state, {
      });
    // download student template
    case actions.ActionTypes.DOWNLOAD_STUDENT_TEMPLATE:
      return Object.assign({}, state, {
        downloadStudentTemplateLoading: true,
      });

    case actions.ActionTypes.DOWNLOAD_STUDENT_TEMPLATE_SUCCESS:
      return Object.assign({}, state, {
        downloadStudentTemplate: payload.data,
        downloadStudentTemplateLoading: false,
      });

    case actions.ActionTypes.DOWNLOAD_STUDENT_TEMPLATE_FAIL:
      return Object.assign({}, state, {
        downloadStudentTemplateLoading: false,
      });
    case actions.ActionTypes.FEE_INVOICE_DATA:
      return Object.assign({}, state, {
        downloadStudentTemplateLoading: true,
      });

    case actions.ActionTypes.FEE_INVOICE_DATA_SUCCESS:
      return Object.assign({}, state, {
        feeInvoiceDetails: payload,
      });

    case actions.ActionTypes.FEE_INVOICE_DATA_FAIL:
      return Object.assign({}, state, {
      });
    default: {
      return state;
    }
  }
}

/**
 * export values
 */
export const getUserDetail = (state: CommonState) => state.userDetail;
export const getSchoolDetail = (state: CommonState) => state.schoolDetail;
export const getSchoolDetailLoading = (state: CommonState) =>
  state.schoolDetailLoading;
export const getSchoolDetailLoaded = (state: CommonState) =>
  state.schoolDetailLoaded;
export const getSchoolDetailFailed = (state: CommonState) =>
  state.schoolDetailFailed;

export const getPasswordLoading = (state: CommonState) =>
  state.changePasswordLoading;
export const getPasswordLoaded = (state: CommonState) =>
  state.changePasswordLoaded;
export const getPasswordFailed = (state: CommonState) =>
  state.changePasswordFailed;
export const getBulkupload = (state: CommonState) => state.bulkUploadLoading;
export const getUploadDetail = (state: CommonState) => state.uploadDetail;

export const staffBulkUploadLoading = (state: CommonState) =>
  state.staffBulkUploadLoading;
export const staffBulkUploadLoaded = (state: CommonState) =>
  state.staffBulkUploadLoaded;
export const staffBulkUploadFailed = (state: CommonState) =>
  state.staffBulkUploadFailed;
export const staffBulkUpload = (state: CommonState) => state.staffBulkUpload;
export const staffBulkUploadSuccess = (state: CommonState) =>
  state.staffBulkUploadSuccess;

export const getMonthList = (state: CommonState) => state.monthList;
export const academicYearList = (state: CommonState) => state.academicYearList;

export const academicYearMonthList = (state: CommonState) => state.academicYearMonthList;

export const academicYearDetails = (state: CommonState) => state.academicYearDetails;

export const changeAcademicYearSetting = (state: CommonState) => state.changeAcademicYearSetting;
export const academicYear = (state: CommonState) => state.academicYear;

export const downloadStudentTemplate = (state: CommonState) => state.downloadStudentTemplate;
export const downloadStudentTemplateLoading = (state: CommonState) => state.downloadStudentTemplateLoading;



export const downloadfees = (state: CommonState) => state.downloadfees;
export const downloadfeesLoading = (state: CommonState) => state.downloadfeesLoading;