export class StudentAddRequestModel {
  studentName: string;
  classId: string;
  sectionId: string;
  image: File;
  admissionNo: string;
  admissionDate: string;
  gender: string;
  studentType: string;
  medium: string;
  dataOfBirth: string;
  primaryMobile: string;
  secondaryMobile: string;
  whatsappNumber: string;
  email: string;
  emisNumber: string;
  studentAdhaarNumber: string;
  rteNumber: string;
  bhavishyaJyothiNumber: string;
  aplBplCardNumber: string;
  stsNumber: string;
  schoolDiseNumber: string;
  scStFlg: string;
  nccScoutGuide: string;
  religion: string;
  community: string;
  communityCertificateNumber: string;
  nationality: string;
  caste: string;
  subCaste: string;
  residentalAddress: string;
  residentalCity: string;
  residentalState: string;
  residentalCountry: string;
  residentalPincode: string;
  permanentAddress: string;
  permanentCity: string;
  permanentState: string;
  permanentCountry: string;
  permanentPincode: string;
  bloodGroup: string;
  height: string;
  weight: string;
  markIdentificationA: string;
  markIdentificationB: string;
  medicalInspection: string;
  fatherName: string;
  fatherOccupation: string;
  fatherAnnualIncome: string;
  fatherAdhaarNumber: string;
  motherName: string;
  motherOccupation: string;
  motherAnnualIncome: string;
  motherAdhaarNumber: string;
  inComeCerificateNo: string;
  bankAccountNumber: string;
  bankName: string;
  ifscCode: string;
  studentHostel: string;
  religion2: string;
  feesPaymentType: string;
  scholorship: string;
  documents: Array<any>;
  id: any;
  redirect: string;
  preAdmissionId: string;
  isBusStudent: any;
  routeId: any;
  stopId: any;
  transportFeeType: any;
  transportMonthsDetails: any;
  remarks: any;
  constructor(val: any) {
    this.isBusStudent = val.isBusStudent;
    this.routeId = val.routeId;
    this.stopId = val.stopId;
    this.transportFeeType = val.transportFeeType;
    this.transportMonthsDetails = val.transportMonthsDetails;
    this.studentName = val.studentName || '';
    this.classId = val.standard || '';
    this.sectionId = val.section || '';
    this.image = val.image || '';
    this.admissionNo = val.admissionNo || '';
    this.admissionDate = val.admissionDate || '';
    this.gender = val.gender || '';
    this.studentType = val.studentType || '';
    this.medium = val.medium || '';
    this.dataOfBirth = val.dateOfBirth || '';
    this.primaryMobile = val.primaryMobileNo || '';
    this.secondaryMobile = val.secondaryMobileNo || '';
    this.whatsappNumber = val.whatsappNumber || '';
    this.email = val.email || '';
    this.emisNumber = val.emisNumber || '';
    this.studentAdhaarNumber = val.studentAadharNo || '';
    this.rteNumber = val.rteNumber || '';
    this.bhavishyaJyothiNumber = val.bhavishyaJyothiNo || '';
    this.aplBplCardNumber = val.aplNumber || '';
    this.stsNumber = val.stsNumber || '';
    this.schoolDiseNumber = val.schoolDiseNumber || '';
    this.scStFlg = val.scSt || '';
    this.nccScoutGuide = val.nccScoutGuide || '';
    this.religion = val.religion || '';
    this.caste = val.caste || '';
    this.subCaste = val.subCaste || '';
    this.community = val.community || '';
    this.communityCertificateNumber = val.communityCertificateNo || '';
    this.nationality = val.nationality || '';
    this.residentalAddress = val.residentialAddress || '';
    this.residentalCity = val.residentialCity || '';
    this.residentalState = val.residentialState || '';
    this.residentalCountry = val.residentialCountry || '';
    this.residentalPincode = val.residentialPincode || '';
    this.permanentAddress = val.permanentAddress || '';
    this.permanentCity = val.permanentCity || '';
    this.permanentState = val.permanentState || '';
    this.permanentCountry = val.permanentCountry || '';
    this.permanentPincode = val.permanentPincode || '';
    this.bloodGroup = val.bloodGroup || '';
    this.height = val.height || '';
    this.weight = val.weight || '';
    this.markIdentificationA = val.markIdentity1 || '';
    this.markIdentificationB = val.markIdentity2 || '';
    this.medicalInspection = val.medicalInspection || '';
    this.fatherName = val.fatherName || '';
    this.fatherOccupation = val.fatherOccupation || '';
    this.fatherAnnualIncome = val.fatherIncome || '';
    this.fatherAdhaarNumber = val.fatherAadharNo || '';
    this.motherName = val.motherName || '';
    this.motherOccupation = val.motherOccupation || '';
    this.motherAnnualIncome = val.motherIncome || '';
    this.motherAdhaarNumber = val.motherAadharNo || '';
    this.inComeCerificateNo = val.incomeCertificateNo || '';
    this.bankAccountNumber = val.bankAccountNo || '';
    this.bankName = val.bankName || '';
    this.ifscCode = val.ifscCode || '';
    this.studentHostel = val.studentHostel || '';
    this.religion2 = val.religion2 || '';
    this.feesPaymentType = val.feesPaymentType || '';
    this.scholorship = val.scholorship || '';
    this.documents = val.documents || '';
    this.remarks = val.remarks || '';
    if (val.preAdmissionId) {
      this.preAdmissionId = val.preAdmissionId;
    }
    if (val.id) {
      this.id = val.id;
    }
    if (val.redirect) {
      this.redirect = val.redirect;
    }
  }
}
