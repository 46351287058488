export class StudentListRequestModel {
    limit: Number;
    offset: Number;
    keyword: string;
    count: Number;
    instituteClassId: Number;
    instituteSectionId: Number;
    gender: string;
    invalidStudent: any;
    isDelete: any;
    isBusStudent: boolean;
    history: number;
    constructor(value: any) {
        this.limit = value.limit || 0;
        this.offset = value.offset || 0;
        this.keyword = value.keyword || '';
        this.count = value.count || 0;
        this.instituteClassId = value.standardId || '';
        if (value.genderId && value.genderId !== 'both') {
            this.gender = value.genderId || '';
        } else {
            this.gender = '';
        }
        this.instituteSectionId = value.sectionId || '';
        this.isDelete = value.isDelete || '';
        this.isBusStudent = value.isBusStudent || '';
        this.history = value.history || '';
        if (value.invalidStudent) {
            this.invalidStudent = value.invalidStudent;
        }
    }
}
