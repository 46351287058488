export class DetailedReportFileResponseModel {
    public appViewed: any;
    public className: any;
    public delivery_on: any;
    public primaryMobile: any;
    public sectionName: any;
    public status: any;
    public studentName: any;
    public appViewedOn: any;
    public staffType: any;

    constructor(textMsgDetails: any) {
     this.appViewed = textMsgDetails.appViewed || '';
     this.appViewedOn = textMsgDetails.appViewedOn || '';
     this.className = textMsgDetails.className || '';
     this.delivery_on = textMsgDetails.delivery_on || '';
     this.primaryMobile = textMsgDetails.primaryMobile || '';
     this.sectionName = textMsgDetails.sectionName || '';
     this.status = textMsgDetails.status || '';
     if (textMsgDetails.studentName) {
        this.studentName = textMsgDetails.studentName || '';
     }
     if (textMsgDetails.staffName) {
        this.studentName = textMsgDetails.staffName || '';
         this.staffType = textMsgDetails.staffType || '';
     }
    }
}
