import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import * as actions from '../actions/common.action';
import { CommonService } from '../common.service';
import { ToastrService } from 'ngx-toastr';
import { saveAs } from 'file-saver';
import printJS from 'print-js';


/**
 * Effects offer a way to isolate and easily test side-effects within your
 * application. StateUpdates is an observable of the latest state and
 * dispatched action. The `toPayload` helper function returns just
 * the payload of the currently dispatched action, useful in
 * instances where the current state is not necessary.
 *
 * If you are unfamiliar with the operators being used in these examples, please
 * check out the sources below:
 *
 * Official Docs: http://reactivex.io/rxjs/manual/overview.html#categories-of-operators
 * RxJS 5 Operators By Example: https://gist.github.com/btroncone/d6cf141d6f2c00dc6b35
 */

@Injectable()
export class CommonEffect {
  /**
   * subject list effect
   */
  @Effect()
  getSchooldetail$: Observable<Action> = this.action$.pipe(
    ofType(actions.ActionTypes.GET_SCHOOL_DETAIL),
    map((action: actions.GetSchoolDetail) => action.payload),
    switchMap(state => {
      return this.api.getAvatharDetail().pipe(
        map(user => new actions.GetSchoolDetailSuccess(user)),
        catchError(error => of(new actions.GetSchoolDetailFail(error.error)))
      );
    })
  );
  /**
   * change password effect
   */
  @Effect()
  changePassword$: Observable<Action> = this.action$.pipe(
    ofType(actions.ActionTypes.CHANGE_PASSWORD),
    map((action: actions.ChangePassword) => action.payload),
    switchMap(state => {
      return this.api.changePassword(state).pipe(
        tap(res => {
          if (res) {
            localStorage.removeItem('instituteToken');
            this.router.navigate(['/auth']);
          }
        }),
        map(user => new actions.ChangePasswordSuccess(user)),
        catchError(error => of(new actions.ChangePasswordFail(error.error)))
      );
    })
  );
  /**
   * change password effect
   */
  @Effect()
  uploadStudent$: Observable<Action> = this.action$.pipe(
    ofType(actions.ActionTypes.BULK_STUDENT_UPLOAD),
    map((action: actions.BulkStudentUpload) => action.payload),
    switchMap(state => {
      return this.api.studentBulkUpload(state).pipe(
        map(user => new actions.BulkStudentUploadSuccess(user)),
        catchError(error => of(new actions.BulkStudentUploadFail(error.error)))
      );
    })
  );

  /**
   * bulk staff upload
   */
  @Effect()
  uploadStaff$: Observable<Action> = this.action$.pipe(
    ofType(actions.ActionTypes.BULK_STAFF_UPLOAD),
    map((action: actions.BulkStaffUpload) => action.payload),
    switchMap(state => {
      return this.api.staffBulkUpload(state).pipe(
        map(user => new actions.BulkStaffUploadSuccess(user)),
        catchError(error => of(new actions.BulkStaffUploadFail(error.error)))
      );
    })
  );
  // month list
  @Effect()
  monthList$: Observable<Action> = this.action$.pipe(
    ofType(actions.ActionTypes.GET_MONTH_LIST),
    map((action: actions.MonthListAction) => action.payload),
    switchMap(state => {
      return this.api.monthList(state).pipe(
        map(user => new actions.MonthListActionSuccess(user)),
        catchError(error => of(new actions.MonthListActionFail(error.error)))
      );
    })
  );
  @Effect()
  AcademicList$: Observable<Action> = this.action$.pipe(
    ofType(actions.ActionTypes.ACADEMIC_YEAR_LIST_ACTION),
    map((action: actions.AcademicYearListAction) => action.payload),
    switchMap(state => {
      return this.api.AcademicList(state).pipe(
        map(user => new actions.AcademicYearListActionSuccess(user)),
        catchError(error => of(new actions.AcademicYearListActionFail(error.error)))
      );
    })
  );
  @Effect()
  AcademicYearMonthList$: Observable<Action> = this.action$.pipe(
    ofType(actions.ActionTypes.ACADEMIC_YEAR_MONTH_LIST_ACTION),
    map((action: actions.AcademicYearMonthListAction) => action.payload),
    switchMap(state => {
      return this.api.AcademicMonthList(state).pipe(
        map(user => new actions.AcademicYearMonthListActionSuccess(user)),
        catchError(error => of(new actions.AcademicYearMonthListActionFail(error.error)))
      );
    })
  );
  @Effect()
  GetAcademicYearSetting$: Observable<Action> = this.action$.pipe(
    ofType(actions.ActionTypes.GET_ACADEMIC_YEAR_SETTING_ACTION),
    map((action: actions.GetAcademicYearSettingAction) => action.payload),
    switchMap(state => {
      return this.api.GetAcademicYearSetting(state).pipe(
        map(user => new actions.GetAcademicYearSettingActionSuccess(user)),
        catchError(error => of(new actions.GetAcademicYearSettingActionFail(error.error)))
      );
    })
  );
  @Effect()
  changeAcademicYearSetting$: Observable<Action> = this.action$.pipe(
    ofType(actions.ActionTypes.CHANGE_ACADEMIC_YEAR_SETTING),
    map((action: actions.ChangeAcademicYearSetting) => action.payload),
    switchMap(state => {
      return this.api.ChangeAcademicYearSetting(state).pipe(
        // tap(res => {
        //   if (res) {
        //     this.router.navigate(['/master-setup']);
        //   }
        // }),
        map(user => new actions.ChangeAcademicYearSettingSuccess(user)),
        catchError(error => of(new actions.ChangeAcademicYearSettingFail(error.error)))
      );
    })
  );

  @Effect()
  downloadStudentTemplate$: Observable<Action> = this.action$.pipe(
    ofType(actions.ActionTypes.DOWNLOAD_STUDENT_TEMPLATE),
    map((action: actions.DownloadStuTemplate) => action),
    switchMap(state => {
      return this.api.getStudentTemplate().pipe(
        tap(data => {
          // tslint:disable-next-line:max-line-length
          const instituteDetails = localStorage.getItem('instituteDetails') ? JSON.parse(localStorage.getItem('instituteDetails')) : false;
          let fileName = 'StudentUploadTemplate.xls';
          if (instituteDetails) {
            fileName = instituteDetails.institudeCode + '_StudentUploadTemplate.xls';
          }
          // const filename = 'StudentUploadTemplate.xlsx';
          const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
          saveAs(blob, fileName);
        }),
        map(user => new actions.DownloadStuTemplateSuccess(user)),
        catchError(error => of(new actions.DownloadStuTemplateFail(error.error)))
      );
    })
  );

  @Effect()
  callFlowPdf$: Observable<Action> = this.action$.pipe(
    ofType(actions.ActionTypes.GET_CALL_FLOW_PDF),
    map((action: actions.GetCallFlowPdf) => action.payload),
    switchMap(state => {
      return this.api.GetCallFlowPdf(state).pipe(
        tap(res => {
          if (res) {
            this.printPdfFile(res.data);
            // window.location.href = res.data;
          }
        }),

        map(user => new actions.GetCallFlowPdfSuccess(user)),
        catchError(error => of(new actions.GetCallFlowPdfFail(error.error)))
      );
    })
  );
  @Effect()
  ivrCodePdf$: Observable<Action> = this.action$.pipe(
    ofType(actions.ActionTypes.GET_IVR_CODE_PDF),
    map((action: actions.GetIvrCodePdf) => action.payload),
    switchMap(state => {
      return this.api.GetIvrCodePdf(state).pipe(
        tap(res => {
          if (res) {
            this.printPdfFile(res.data);
          }
        }),
        map(user => new actions.GetIvrCodePdfSuccess(user)),
        catchError(error => of(new actions.GetIvrCodePdfFail(error.error)))
      );
    })
  );
  @Effect()
  appUserPdf$: Observable<Action> = this.action$.pipe(
    ofType(actions.ActionTypes.GET_APP_USER_PDF),
    map((action: actions.GetAppUserPdf) => action.payload),
    switchMap(state => {
      return this.api.GetAppUserPdf(state).pipe(
        tap(res => {
          if (res) {
            console.log('logvall', res);
            window.open(res.data, '_blank');
          }
        }),
        map(user => new actions.GetAppUserPdfSuccess(user)),
        catchError(error => of(new actions.GetAppUserPdfFail(error.error)))
      );
    })
  );

  @Effect()
  youtubemanualPdf$: Observable<Action> = this.action$.pipe(
    ofType(actions.ActionTypes.GET_YOUTUBE_MANUAL_PDF),
    map((action: actions.getyoutubemanualPdf) => action.payload),
    switchMap(state => {
      return this.api.GetYoutubeManualPdf(state).pipe(
        tap(res => {
          if (res) {
            console.log('logvall', res);
            window.open(res.data, '_blank');
          }
        }),
        map(user => new actions.getyoutubemanualPdfSuccess(user)),
        catchError(error => of(new actions.getyoutubemanualPdfFail(error.error)))
      );
    })
  );

  @Effect()
  feeInvoice$: Observable<Action> = this.action$.pipe(
    ofType(actions.ActionTypes.FEE_INVOICE_DATA),
    map((action: actions.FeeInvoiceData) => action.payload),
    switchMap(state => {
      return this.api.feesInvoiceData(state).pipe(
        tap(data => {
          this.printPdfFiles(data.data['base64']);
        }),
        map(user => new actions.FeeInvoiceDataSuccess(user)),
        catchError(error => of(new actions.FeeInvoiceDataFail(error.error)))
      );
    })
  );

  @Effect()
  downloadfees$: Observable<Action> = this.action$.pipe(
    ofType(actions.ActionTypes.DOWNLOAD_FEES),
    map((action: actions.Downloadfees) => action),
    switchMap(state => {
      return this.api.getdownloadedfees().pipe(
        tap(data => {
          // tslint:disable-next-line:max-line-length
          const instituteDetails = localStorage.getItem('instituteDetails') ? JSON.parse(localStorage.getItem('instituteDetails')) : false;
          let fileName = 'Feeslist.zip';
          if (instituteDetails) {
            fileName = instituteDetails.institudeCode + '_oldfees.zip';
          }
          // const filename = 'StudentUploadTemplate.xlsx';
          const blob = new Blob([data], { type: 'application/zip' });
          saveAs(blob, fileName);
        }),
        map(user => new actions.DownloadfeesSuccess(user)),
        catchError(error => of(new actions.DownloadfeesFail(error.error)))
      );
    })
  );

  public convertBase64PDFToBlobData(base64Data: string, contentType: string = 'application/pdf', sliceSize = 512) {
    const byteCharacters = atob(base64Data.replace(/^data:([A-Za-z-+\/]+);base64,/, ''));
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }
  printPdfFile(base64content: any) {
    const blobData = this.convertBase64PDFToBlobData(base64content);
    const blob = new Blob([blobData], { type: 'application/pdf' });
    const url = window.URL.createObjectURL(blob);
    console.log(blobData);
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.setAttribute('style', 'display: none');
    a.setAttribute('target', '_blank  ');
    a.href = url;
    a.click();
  }
  printPdfFiles(base64content: any) {
    const blobData = this.convertBase64PDFToBlobData(base64content);
    const blob = new Blob([blobData], { type: 'application/pdf' });
    const url = window.URL.createObjectURL(blob);
    printJS({
      printable: url,
    });
  }
  constructor(
    private action$: Actions,
    protected api: CommonService,
    public toastr: ToastrService,
    public router: Router
  ) { }
}
