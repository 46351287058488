import { Component, OnInit } from '@angular/core';
import { CommonSandbox } from '../common.sandbox';
import { CommonService } from '../common.service';
import {get} from 'lodash';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})

export class LayoutComponent implements OnInit {
  constructor(public commonSandBox: CommonSandbox, private commonService: CommonService) { }
  ngOnInit() {
    // if (localStorage.getItem('instituteToken')) {
      //this.commonSandBox.getSchooldetail();
    // }
  }
}
