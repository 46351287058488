import { FilesReportState, filesReportStateRecord } from './files.state';
import * as actions from '../actions/files.action';
import {filter} from 'lodash';
import { DetailedReportFileResponseModel } from '../models/detailed-report-file-response.model';
import { FileListResponseModel } from '../models/filelist-response.model';

export const initialState: FilesReportState = (new filesReportStateRecord() as unknown) as FilesReportState;

export function reducer(state = initialState, { type, payload }: any): FilesReportState {
  if (!type) {
    return state;
  }

  switch (type) {

    /**
     * get files list
     */
    case actions.ActionTypes.GET_FILES_REPORT_LIST:
      return Object.assign({}, state, {
        getFilesReportListLoading: true,
        getFilesReportListLoaded: false,
        getFilesReportListFailed: false,
      });
    case actions.ActionTypes.GET_FILES_REPORT_LIST_SUCCESS:
    const fileReportList = payload.data.map(data => {
      const tempFileReportList = new FileListResponseModel(data);
       return tempFileReportList;
      });
      return Object.assign({}, state, {
        getFilesReportList: fileReportList,
        getFilesReportListLoading: false,
        getFilesReportListLoaded: true,
        getFilesReportListFailed: false,
      });
    case actions.ActionTypes.GET_FILES_REPORT_LIST_FAIL:
      return Object.assign({}, state, {
        getFilesReportListLoading: false,
        getFilesReportListLoaded: false,
        getFilesReportListFailed: true,

      });

      /**
     * get files report details
     */
      case actions.ActionTypes.GET_FILES_REPORT_DETAILS:
    return Object.assign({}, state, {
        getFilesReportDetails: [],
        tempGetFilesReportDetails: [],
        getFilesReportDetailsLoading:  true,
        getFilesReportDetailsLoaded: false,
        getFilesReportDetailsFailed: false,
    });
  case actions.ActionTypes.GET_FILES_REPORT_DETAILS_SUCCESS:
  const fileReportDetails = payload.data.map(data => {
    console.log('reducer', data);
    const tempFileReportDetails = new DetailedReportFileResponseModel(data);
     return tempFileReportDetails;
    });
    return Object.assign({}, state, {
        getFilesReportDetails: fileReportDetails,
        tempGetFilesReportDetails: fileReportDetails,
        getFilesReportDetailsLoading:  false,
        getFilesReportDetailsLoaded: true,
        getFilesReportDetailsFailed: false,
    });
  case actions.ActionTypes.GET_FILES_REPORT_DETAILS_FAIL:
    return Object.assign({}, state, {
        getFilesReportDetailsLoading:  false,
        getFilesReportDetailsLoaded: false,
        getFilesReportDetailsFailed: true,

    });

     /**
     * get files report details
     */
    case actions.ActionTypes.FILE_SENT_GROUP_LIST:
    return Object.assign({}, state, {
        fileSentGroupListLoading:  true,
        fileSentGroupListLoaded: false,
        fileSentGroupListFailed: false,
    });
  case actions.ActionTypes.FILE_SENT_GROUP_LIST_SUCCESS:
  let x = payload.data.value;
  const groupList = x.split(',');
  console.log('groupList', groupList);
    return Object.assign({}, state, {
        fileSentGroupList: groupList,
        fileSentGroupListLoading:  false,
        fileSentGroupListLoaded: true,
        fileSentGroupListFailed: false,
    });
  case actions.ActionTypes.FILE_SENT_GROUP_LIST_FAIL:
    return Object.assign({}, state, {
        fileSentGroupListLoading:  false,
        fileSentGroupListLoaded: false,
        fileSentGroupListFailed: true,

    });
    // file export
      case actions.ActionTypes.EXPORT_FILE_LIST:
          return Object.assign({}, state, {
              exportFileListLoading: true,
              exportFileListLoaded: false,
              exportFileListFailed: false,
          });
      case actions.ActionTypes.EXPORT_FILE_LIST_SUCCESS:
          return Object.assign({}, state, {
              exportFileList: payload.data,
              exportFileListLoading: false,
              exportFileListLoaded: true,
              exportFileListFailed: false,
          });
      case actions.ActionTypes.EXPORT_FILE_LIST_FAIL:
          return Object.assign({}, state, {
              exportFileListLoading: false,
              exportFileListLoaded: false,
              exportFileListFailed: true,
          });
          // member details
      case actions.ActionTypes.MEMBER_EXPORT:
          return Object.assign({}, state, {
              memberExportListLoading: true,
              memberExportListLoaded: false,
              memberExportListFailed: false,
          });
      case actions.ActionTypes.MEMBER_EXPORT_SUCCESS:
          return Object.assign({}, state, {
              memberExportList: payload.data,
              memberExportListLoading: false,
              memberExportListLoaded: true,
              memberExportListFailed: false,
          });
      case actions.ActionTypes.MEMBER_EXPORT_FAIL:
          return Object.assign({}, state, {
              memberExportListLoading: false,
              memberExportListLoaded: false,
              memberExportListFailed: true,
          });
          // report list count
      case actions.ActionTypes.GET_FILES_REPORT_COUNT_LIST:
          return Object.assign({}, state, {
              reportCountLoading: true,
              reportCountLoaded: false,
              reportCountFailed: false,
          });
      case actions.ActionTypes.GET_FILES_REPORT_COUNT_LIST_SUCCESS:
        console.log('payload.count', payload.count);
          return Object.assign({}, state, {
              reportCount: payload.count,
              reportCountLoading: false,
              reportCountLoaded: true,
              reportCountFailed: false,
          });
      case actions.ActionTypes.GET_FILES_REPORT_COUNT_LIST_FAIL:
          return Object.assign({}, state, {
              reportCountLoading: false,
              reportCountLoaded: false,
              reportCountFailed: true,
          });
          // search member
      case actions.ActionTypes.SEARCH_MEMBER_LIST:
          let tempMemberSearch = state.tempGetFilesReportDetails;
          const search = payload.toLowerCase();
          tempMemberSearch = filter(tempMemberSearch, (item) => {
                  return item.studentName.toLowerCase().includes(search);
          });
          return Object.assign({}, state, {
              getFilesReportDetails: tempMemberSearch,
          });
    default: {
      return state;
    }
  }
}

/**
 * export values (files list)
 */
export const getFilesReportList = (state: FilesReportState) => state.getFilesReportList;
export const getFilesReportListLoading = (state: FilesReportState) => state.getFilesReportListLoading;
export const getFilesReportListLoaded = (state: FilesReportState) => state.getFilesReportListLoaded;
export const getFilesReportListFailed = (state: FilesReportState) => state.getFilesReportListFailed;

/**
 * export values (files sent details)
 */
export const getFilesReportDetails = (state: FilesReportState) => state.getFilesReportDetails;
export const getFilesReportDetailsLoading = (state: FilesReportState) => state.getFilesReportDetailsLoading;
export const getFilesReportDetailsLoaded = (state: FilesReportState) => state.getFilesReportDetailsLoaded;
export const getFilesReportDetailsFailed = (state: FilesReportState) => state.getFilesReportDetailsFailed;

/**
 * export values (files sent group list)
 */
export const fileSentGroupList = (state: FilesReportState) => state.fileSentGroupList;
export const fileSentGroupListLoading = (state: FilesReportState) => state.fileSentGroupListLoading;
export const fileSentGroupListLoaded = (state: FilesReportState) => state.fileSentGroupListLoaded;
export const fileSentGroupListFailed = (state: FilesReportState) => state.fileSentGroupListFailed;
/**
 * export file list
 */
export const exportFileList = (state: FilesReportState) => state.exportFileList;
export const exportFileListLoading = (state: FilesReportState) => state.exportFileListLoading;
export const exportFileListLoaded = (state: FilesReportState) => state.exportFileListLoaded;
export const exportFileListFailed = (state: FilesReportState) => state.exportFileListFailed;
/**
 * member export file list
 */
export const memberExportList = (state: FilesReportState) => state.memberExportList;
export const memberExportListLoading = (state: FilesReportState) => state.memberExportListLoading;
export const memberExportListLoaded = (state: FilesReportState) => state.memberExportListLoaded;
export const memberExportListFailed = (state: FilesReportState) => state.memberExportListFailed;
/**
 * member export file list
 */
export const reportCount = (state: FilesReportState) => state.reportCount;
export const reportCountLoading = (state: FilesReportState) => state.reportCountLoading;
export const reportCountLoaded = (state: FilesReportState) => state.reportCountLoaded;
export const reportCountFailed = (state: FilesReportState) => state.reportCountFailed;
