import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

import {ResponseInterface} from '../../shared/interfaces/interface';
import {Api} from '../../providers/api/api';
import {HttpParams} from '@angular/common/http';


@Injectable()

export class StudentManagementService extends Api {

  private URL: string = this.getBaseUrl();

  addStudent(params): Observable<ResponseInterface> {
    console.log('addstudent', params);
    return this.http.post<ResponseInterface>(this.URL + '/student/add-student', params);
  }
  editStudent(params): Observable<ResponseInterface> {
    console.log('addstudent', params);
    const editId = params.id;
    delete params.id;
    return this.http.put<ResponseInterface>(this.URL + '/student/update-student/' + editId, params);
  }
  studentList(params): Observable<ResponseInterface> {
    return this.http.get<ResponseInterface>(this.URL + '/student/student-list', {params: params});
  }
  leaveRequestList(params): Observable<ResponseInterface> {
    return this.http.get<ResponseInterface>(this.URL + '/LeaveRequest/leaveapplication-list', {params: params});
  }
  getLeaverequestlistCount(params): Observable<ResponseInterface> {
    return this.http.get<ResponseInterface>(this.URL + '/LeaveRequest/leaveapplication-list', {params: params});
  }
  standardList(): Observable<ResponseInterface> {
    return this.http.get<ResponseInterface>(this.URL + '/institute-class/standard-list');
  }
  sectionList(params): Observable<ResponseInterface> {
    return this.http.get<ResponseInterface>(this.URL + '/institute-section/section-list', {params: params});
  }
  mediumOfStudent(): Observable<ResponseInterface> {
    return this.http.get<ResponseInterface>(this.URL + '/master/medium-list');
  }
  religionList(): Observable<ResponseInterface> {
    return this.http.get<ResponseInterface>(this.URL + '/master/religion-list');
  }
  communityList(): Observable<ResponseInterface> {
    return this.http.get<ResponseInterface>(this.URL + '/master/community-list');
  }
  bloodGroupList(): Observable<ResponseInterface> {
    return this.http.get<ResponseInterface>(this.URL + '/master/blood_group-list');
  }
  feesPaymentType(): Observable<ResponseInterface> {
    return this.http.get<ResponseInterface>(this.URL + '/master/payment-type-list');
  }
  getStudentDetails(params): Observable<ResponseInterface> {
    return this.http.get<ResponseInterface>(this.URL + '/student/get-student/' + params);
  }
  getStudentCount(params): Observable<ResponseInterface> {
    return this.http.get<ResponseInterface>(this.URL + '/student/student-list', {params: params});
  }
  deleteStudent(params): Observable<ResponseInterface> {
    console.log('studentid', params.studentId);
    return this.http.delete<ResponseInterface>(this.URL + '/student/delete-student/' + params.studentId, {params: params});
  }
  uploadDocument(params): Observable<ResponseInterface> {
    const formData: FormData = new FormData();
    formData.append('studentDocument', params.studentDocument, params.studentDocument.name);
    return this.http.post<ResponseInterface>(this.URL + '/student/uploads-student-document', formData );
  }
  deletedStudentList(params): Observable<ResponseInterface> {
    console.log('studentid', params.studentId);
    return this.http.get<ResponseInterface>(this.URL + '/student/student-list' , {params: params});
  }

  revertStudent(params): Observable<ResponseInterface> {
    console.log('params', params);
    return this.http.put<ResponseInterface>(this.URL + '/student/re-work-student/' + params.studentId, {params});
  }
  routeList(params): Observable<ResponseInterface> {
    console.log('params', params);
    return this.http.get<ResponseInterface>(this.URL + '/route-master/list-route-master', {params: params});
  }
  stopingPointList(params): Observable<ResponseInterface> {
    console.log('params', params);
    return this.http.get<ResponseInterface>(this.URL + '/route-master/details/' + params.routeId);
  }
  exportStudentList(params): Observable<any> {
    const reqOpts: any = {};
    reqOpts.responseType = 'arraybuffer';
    if (params) {
      reqOpts.params = new HttpParams();
      for (const k in params) {
        if (k) {
          reqOpts.params = reqOpts.params.set(k, params[k]);
        }
      }
    }
    return this.http.get<ResponseInterface>(this.URL + '/student/export-student-list', reqOpts);
  }
  routeMasterFeeList(params): Observable<ResponseInterface> {
    return this.http.get<ResponseInterface>(this.URL + '/route-fees/route-fee-type-list', {params: params});
  }
  monthList(params): Observable<ResponseInterface> {
    return this.http.get<ResponseInterface>(this.URL + '/route-fees/institute-transport-months-list');
  }
  promoteStudent(params): Observable<ResponseInterface> {
    return this.http.get<ResponseInterface>(this.URL + '/student/student-list', {params});
  }
  academicyear(params): Observable<ResponseInterface> {
    return this.http.post<ResponseInterface>(this.URL + '/academic-year/list', params);
  }
  createPromoteStudent(params): Observable<ResponseInterface> {
    return this.http.post<ResponseInterface>(this.URL + '/student/promotion', params);
  }
  studentTextReport(params): Observable<ResponseInterface> {
    return this.http.get<ResponseInterface>(this.URL + '/report/student-message', {params});
  }
  studentMessageReport(params): Observable<ResponseInterface> {
    return this.http.get<ResponseInterface>(this.URL + '/file-report/student-file', {params});
  }
  importInvalidStudent(params): Observable<ResponseInterface> {
    return this.http.post<ResponseInterface>(this.URL + '/import/export-invalid-student', params);
  }
  idCardGenerate(params): Observable<ResponseInterface> {
    console.log('service', params);
    return this.http.post<ResponseInterface>(this.URL + '/student/student-id-card', params);
  }
  studentVoiceReport(params): Observable<ResponseInterface> {
    return this.http.get<ResponseInterface>(this.URL + '/voice/student-voice', {params});
  }
subjectList(params): Observable<ResponseInterface> {
    return this.http.get<ResponseInterface>(this.URL + '/exam/subject-list?sectionId=' + params.sectionId);
  }

  getexamListListApi(): Observable<ResponseInterface> {
    return this.http.get<ResponseInterface>(this.URL + '/exam/exammlist');
  }

  getStdSecApi(params): Observable<ResponseInterface> {
    return this.http.get<ResponseInterface>(this.URL + '/exam/getstdsectionforProgress?examid=' + params);
  }

  getStudentsforprogressApi(params): Observable<ResponseInterface> {

    console.log('url', this.URL + '/exam/sendexamstudentlist?examid=' + params.examid
      + '&sectionid=' + params.sectionId);
    return this.http.get<ResponseInterface>(this.URL + '/exam/sendexamstudentlist?examid=' + params.examid
      + '&sectionid=' + params.sectionId);
  }

  getResultAnalysisApi(params): Observable<ResponseInterface> {
    // debugger;
    console.log('url', this.URL + '/exam/examResultAnalysis?examid=' + params.exid
      + '&standardid=' + params.stdid + '&sectionid=' + params.secid);
    return this.http.get<ResponseInterface>(this.URL + '/exam/examResultAnalysis?examid=' + params.exid
      + '&standardid=' + params.stdid + '&sectionid=' + params.secid);
  }

  // getProgressCardApi(params): Observable<ResponseInterface> {
  //   //debugger;
  //   // console.log("url" , this.TestURL + '/exam/get-progress-card?examid=' + params.exid
  //   // + '&standardid=' +params.stdid + '&sectionid=' +params.secid);
  //   return this.http.get<ResponseInterface>(this.URL + '/exam/get-progress-card?examid=' + params.exid
  //   + '&is_all_student=' +params.type + '&student_id=' +params.memberid
  //  + '&token=' +params.token + '&sectionid=' +params.sectionid);
  // }
  getProgressCardApi(params): Observable<ResponseInterface> {
    debugger;
    // console.log("url" , this.TestURL + '/exam/get-progress-card?examid=' + params.exid
    // + '&standardid=' +params.stdid + '&sectionid=' +params.secid);
    return this.http.get<ResponseInterface>(this.URL + '/exam/get-progress-card?examid=' + params.exid
      + '&sectionid=' + params.sectionid + '&studentid=' + params.memberid + '&standardid='
      + params.standardid + '&is_all_student=' + params.type
      + '&format=' + params.forMat);
  }
  //getStudyCertificateApi
  getStudyCertificateApi(params): Observable<ResponseInterface> {
    debugger;
    return this.http.get<ResponseInterface>(this.URL + '/jasper/getStudyCertificates?sectionid='
     + params.sectionid + '&studentid=' + params.studentid + '&standardid='
      + params.standardid + '&is_all_student=' + params.type
      + '&format=' + params.forMat);
  }

      // calling country List APi
      public countryListApi(): Observable<any> {
        return this.http.get(this.URL + '/location/country-list');
    }
    // calling region List Api
    public regionListApi(params): Observable<any> {
        return this.http.get(this.URL + '/location/region-list', {params});
    }
    // Calling city List Api
    public cityListApi(params): Observable<any> {
        return this.http.get(this.URL + '/location/city-list', {params});
    }
}
