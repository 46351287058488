import * as authReducer from './auth/reducer/auth.reducer';
import * as commonReducer from './common/reducer/common.reducer';
import * as studentManagentReducer from './pages/student-management/reducers/student-management.reducer';
import * as dashBoardreducer from './pages/dashboard/reducer/dashboard.reducer';
import * as activityreducer from './pages/activity-log/reducer/activity.reducer';
import * as autoLogin from './auto-login/reducer/auto-logon.reducer'
import {ActionReducerMap, ActionReducer, MetaReducer} from '@ngrx/store';
import {AppState as State} from './app.state';
import {environment} from '../environments/environment.prod';
import * as assignmentListReducer from './pages/report/Assignment/reducer/assignment.reducer';
import * as LoginListState from './pages/loginlist/reducer/loginlist.reducer';
import * as filesreportReducer from './pages/report/files/reducers/files.reducer';
import * as pdffilesreportReducer from './pages/report/pdffiles/reducers/pdffiles.reducer';
import * as VideoReportReducer from './pages/report/video/reducer/video.reducer';
import * as LoginReducer from './pages/login/reducer/login.reducer';


export const reducers: ActionReducerMap<State> = {
    auth: authReducer.reducer,
    common: commonReducer.reducer,
    studentManagement: studentManagentReducer.reducer,
    dashBoard: dashBoardreducer.reducer,
    activity: activityreducer.reducer,
    autoLogin: autoLogin.reducer,
    AssignmentListState : assignmentListReducer.reducer,
    LoginListState :LoginListState.reducer,
    filesReport: filesreportReducer.reducer,
    pdffilesReport: pdffilesreportReducer.reducer,
    videoReport: VideoReportReducer.reducer,
    Login:LoginReducer.reducer,


};

export function logger(reducer: ActionReducer<State>): ActionReducer<any, any> {
    return function (state: State, action: any): State {
        return reducer(state, action);
    };
}

export const metaReducers: MetaReducer<State>[] = !environment.production
    ? [logger]
    : [];
