import {Map, Record} from 'immutable';

export interface PdfFilesReportState extends Map<string, any> {

    getPdfFilesReportList: any;
   // getPdfFilesReportDetails: any;
   // tempGetPdfFilesReportDetails: any;
    // fileSentGroupList: any;
    // exportFileList: any;
    // memberExportList: any;
    PdfreportCount: any;
    PdfreportCountLoading: boolean;
    PdfreportCountLoaded: boolean;
    PdfreportCountFailed: boolean;

    // memberExportListLoading: boolean;
    // memberExportListLoaded: boolean;
    // memberExportListFailed: boolean;

    // exportFileListLoading: boolean;
    // exportFileListLoaded: boolean;
    // exportFileListFailed: boolean;

    getPdfFilesReportListLoading: boolean;
    getPdfFilesReportListLoaded: boolean;
    getPdfFilesReportListFailed: boolean;

    // getFilesReportDetailsLoading: boolean;
    // getFilesReportDetailsLoaded: boolean;
    // getFilesReportDetailsFailed: boolean;

    // fileSentGroupListLoading: boolean;
    // fileSentGroupListLoaded: boolean;
    // fileSentGroupListFailed: boolean;
}

export const pdffilesReportStateRecord = Record({

    getPdfFilesReportList: [],
    //getPdfFilesReportDetails: [],
    // fileSentGroupList: [],
    // exportFileList: [],
    // memberExportList: [],
   // tempGetPdfFilesReportDetails: [],
    PdfreportCount: [],

    PdfreportCountLoading: false,
    PdfreportCountLoaded: false,
    PdfreportCountFailed: false,

    // memberExportListLoading: false,
    // memberExportListLoaded: false,
    // memberExportListFailed: false,

    // exportFileListLoading: false,
    // exportFileListLoaded: false,
    // exportFileListFailed: false,

    getPdfFilesReportListLoading: false,
    getPdfFilesReportListLoaded: false,
    getPdfFilesReportListFailed: false,

    // getFilesReportDetailsLoading: false,
    // getFilesReportDetailsLoaded: false,
    // getFilesReportDetailsFailed: false,

    // fileSentGroupListLoading: false,
    // fileSentGroupListLoaded: false,
    // fileSentGroupListFailed: false,

});

