import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { CONTAINERS } from './common/common-layout.module';
import { ChangePasswordComponent } from './pages/change-password/change-password.component';
import { AuthGuard } from './providers/guards/auth.guard';


const routes: Routes = [
  {
    path: 'auto-login/:id',
    loadChildren: './auto-login/auto-login.module#AutoLoginModule'
  },
  // {
  //   path: 'login',
  //   loadChildren: './pages/login/login.module#LoginModule'
  // },
  // {
  //   path: 'login/:id',
  //   loadChildren: './pages/login/login.module#LoginModule'
  // },
  {
    path: 'loginlist',
    loadChildren: './pages/loginlist/loginlist.module#LoginListModule'
  },
  {
    path: '',
    loadChildren: './auth/auth.module#AuthModule'  // './pages/login/login.module#LoginModule'
  },
  {
    path: 'login',
    loadChildren: './auth/auth.module#AuthModule'
  },
  {
    path: '',
    component: CONTAINERS.LayoutComponent,
    children: [
      {
        path: 'dashboard',
        //  canActivate: [AuthGuard],
        loadChildren: './pages/dashboard/dashboard.module#DashboardModule'
      },
      // {
      //   path: 'dashboard/:id',
      //   canActivate: [AuthGuard],
      //   loadChildren: './pages/dashboard/dashboard.module#DashboardModule'
      // },
      {
        path: 'assignment',
        //   canActivate: [AuthGuard],
        loadChildren: './pages/report/Assignment/assignmentlist.module#AssignmentModule'
      },
      {
        path: 'files',
        //   canActivate: [AuthGuard],
        loadChildren: './pages/report/files/files.module#FilesModule'
      },
      {
        path: 'pdffiles',
        //   canActivate: [AuthGuard],
        loadChildren: './pages/report/pdffiles/pdffiles.module#PDFModule'
      },
      {
        path: 'video',
        //   canActivate: [AuthGuard],
        loadChildren: './pages/report/video/video.module#VideoModule'
      },

      // {
      //   path: 'loginlist',
      //  canActivate: [AuthGuard],
      //   loadChildren: './pages/loginlist/loginlist.module#LoginListModule'
      // },
      // {
      //   path: 'master-setup',
      //   canActivate: [AuthGuard],
      //   loadChildren:
      //     './pages/master-setup/master-setup.module#MasterSetupModule'
      // },
      // {
      //   path: 'staff-management',
      //   canActivate: [AuthGuard],
      //   loadChildren:
      //     './pages/staff-management/staff-management.module#StaffManagementModule'
      // },
      // {
      //   path: 'student-management',
      //   canActivate: [AuthGuard],
      //   loadChildren:
      //     './pages/student-management/student-management.module#StudentManagementModule'
      // },
      // {
      //   path: 'admission',
      //   canActivate: [AuthGuard],
      //   loadChildren: './pages/admission/admission.module#AdmissionModule'
      // },
      // {
      //   path: 'change-password',
      //   canActivate: [AuthGuard],
      //   component: ChangePasswordComponent
      // },
      // {
      //   path: 'invalid-staffs',
      //   canActivate: [AuthGuard],
      //   component: InvalidStaffsComponent
      // },
      // {
      //   path: 'messages',
      //   canActivate: [AuthGuard],
      //   loadChildren: './pages/messages/messages.module#MesssagesModule'
      // },
      // {
      //   path: 'vehicle-management',
      //   canActivate: [AuthGuard],
      //   loadChildren:
      //     './pages/vehicle-management/vehicle-management.module#VehicleManagementModule'
      // },
      // {
      //   path: 'fees-management',
      //   canActivate: [AuthGuard],
      //   loadChildren:
      //     './pages/fee-management/fee-management.module#FeeManagementModule'
      // },
      // {
      //   path: 'report',
      //   canActivate: [AuthGuard],
      //   loadChildren: './pages/report/report.module#ReportModule'
      // },
      // {
      //   path: 'certificates',
      //   canActivate: [AuthGuard],
      //   loadChildren: './pages/certificates/certificates.module#CertificatesModule'
      // },
      // {
      //   path: 'Mytest',
      //   canActivate: [AuthGuard],
      //   loadChildren: './pages/Mytest/mytest.module#MytestModule'
      // },
      // {
      //   path: 'idcard',
      //   canActivate: [AuthGuard],
      //   loadChildren: './pages/id-card-generator/id-card.module#IdCardModule'
      // },
      // {
      //   path: 'feesreport',
      //   canActivate: [AuthGuard],
      //   loadChildren: './pages/fees-report/fees-report.module#FeesReportModule'
      // },
      // {
      //   path: 'activity-log',
      //   canActivate: [AuthGuard],
      //   loadChildren:
      //     './pages/activity-log/activity.module#ActivityModule'
      // },
      // {
      //   path: 'inventory',
      //   loadChildren: './pages/inventory/inventory.module#InventoryModule'
      // },
      // {
      //   path: 'payroll',
      //   loadChildren: './pages/payroll-management/payroll-management.module#PayrollManagementModule'
      // },
      // {
      //   path: 'attendance',
      //   loadChildren: './pages/attendance/attendance.module#AttendanceModule'
      // },
      // {
      //   path: 'reports',
      //   loadChildren: './pages/reports/reports.module#ReportsModule'
      // },
      // {
      //   path: 'login',
      // //  canActivate: [AuthGuard],
      //   redirectTo: 'login',
      //   pathMatch: 'full'
      // }
    ]
  },
  {
    path: 'auth',
    loadChildren: './auth/auth.module#AuthModule'
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      scrollPositionRestoration: 'enabled'
    }
    )],
  // RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
