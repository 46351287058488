import { VideoReportState, VideoReportStateRecord } from './video.state';
import * as actions from '../action/video.action';
import {filter, get} from 'lodash';

export const initialState: VideoReportState = (new VideoReportStateRecord() as unknown) as VideoReportState;

export function reducer(state = initialState, { type, payload }: any): VideoReportState {
  if (!type) {
    return state;
  }

  switch (type) {
    /**
     * get video list
     */
    case actions.ActionTypes.VIDEO_REPORT_LIST:
      return Object.assign({}, state, {
          videoReportListLoading: true,
          videoReportListLoaded: false,
          videoReportListFailed: false,
      });
    case actions.ActionTypes.VIDEO_REPORT_LIST_SUCCESS:
      console.log('payload.data',payload.data);
        payload.data.map(list => {
            list.scheduleDate = new Date (new Date(list.scheduleDate).setMinutes(new Date(list.scheduleDate).getMinutes() - 330))});
      return Object.assign({}, state, {
          videoReportList: payload.data,
          videoReportListLoading: false,
          videoReportListLoaded: true,
          videoReportListFailed: false,
      });
    case actions.ActionTypes.VIDEO_REPORT_LIST_FAIL:
      return Object.assign({}, state, {
          videoReportListLoading: false,
          videoReportListLoaded: false,
          videoReportListFailed: true,
      });
      /**
     * get video count
     */
    case actions.ActionTypes.VIDEO_REPORT_COUNT:
      return Object.assign({}, state, {
          videoReportListCountLoading: true,
          videoReportListCountLoaded: false,
          videoReportListCountFailed: false,
      });
    case actions.ActionTypes.VIDEO_REPORT_COUNT_SUCCESS:
      console.log('payload.count', payload.count);
      return Object.assign({}, state, {
          videoReportListCount: payload.count,
          videoReportListCountLoading: false,
          videoReportListCountLoaded: true,
          videoReportListCountFailed: false,
      });
    case actions.ActionTypes.VIDEO_REPORT_COUNT_FAIL:
      return Object.assign({}, state, {
          videoReportListCountLoading: false,
          videoReportListCountLoaded: false,
          videoReportListCountFailed: true,
      });

      case actions.ActionTypes.GET_VIDEO_DETAILS:
      return Object.assign({}, state, {
          getvideoDetails: [],
          getvideoDetailsLoading: true,
          getvideoDetailsLoaded: false,
          getvideoDetailsFailed: false,
      });
    case actions.ActionTypes.GET_VIDEO_DETAILS_SUCCESS:
      return Object.assign({}, state, {
          getvideoDetails: payload.data,
          getvideoDetailsLoading: false,
          getvideoDetailsLoaded: true,
          getvideoDetailsFailed: false,
      });
    case actions.ActionTypes.GET_VIDEO_DETAILS_FAIL:
      return Object.assign({}, state, {
          getvideoDetailsLoading: false,
          getvideoDetailsLoaded: false,
          getvideoDetailsFailed: true,
      });

      case actions.ActionTypes.VIDEO_MEMBER_DETAILS:
      return Object.assign({}, state, {
          videoMemberDetails: [],
          tempvideoMemberDetails: [],
          videoMemberDetailsLoading: true,
          videoMemberDetailsLoaded: false,
          videoMemberDetailsFailed: false,
      });
    case actions.ActionTypes.VIDEO_MEMBER_DETAILS_SUCCESS:
    console.log('pay', payload.data);
    
      return Object.assign({}, state, {
          videoMemberDetails: payload.data,
          tempvideoMemberDetails: payload.data,
          videoMemberDetailsLoading: false,
          videoMemberDetailsLoaded: true,
          videoMemberDetailsFailed: false,
      });
    case actions.ActionTypes.VIDEO_MEMBER_DETAILS_FAIL:
      return Object.assign({}, state, {
          videoMemberDetailsLoading: false,
          videoMemberDetailsLoaded: false,
          videoMemberDetailsFailed: true,
      });

      case actions.ActionTypes.SEARCH_VIDEO_REPORT:
          const searchName = payload.toLowerCase();
          let tempvideoMember = state.tempvideoMemberDetails;
          tempvideoMember = tempvideoMember.filter(search => {
            if (search.name ? search.name.toLowerCase().includes(searchName) : '' || search.primaryMobile.includes(payload)) {
              return true;
           }
              // return search.name.toLowerCase().includes(searchName);
          });
          console.log('asaaa', payload);
      return Object.assign({}, state, {
          videoMemberDetails: tempvideoMember,
      });
    default: {
      return state;
    }
  }
}

/**
 * export values (files list)
 */
export const videoReportList = (state: VideoReportState) => state.videoReportList;
export const videoReportListLoading = (state: VideoReportState) => state.videoReportListLoading;
export const videoReportListLoaded = (state: VideoReportState) => state.videoReportListLoaded;
export const videoReportListFailed = (state: VideoReportState) => state.videoReportListFailed;

export const videoReportListCount = (state: VideoReportState) => state.videoReportListCount;
export const videoReportListCountLoading = (state: VideoReportState) => state.videoReportListCountLoading;
export const videoReportListCountLoaded = (state: VideoReportState) => state.videoReportListCountLoaded;
export const videoReportListCountFailed = (state: VideoReportState) => state.videoReportListCountFailed;

export const videoMemberDetails = (state: VideoReportState) => state.videoMemberDetails;
export const videoMemberDetailsLoading = (state: VideoReportState) => state.videoMemberDetailsLoading;
export const videoMemberDetailsLoaded = (state: VideoReportState) => state.videoMemberDetailsLoaded;
export const videoMemberDetailsFailed = (state: VideoReportState) => state.videoMemberDetailsFailed;

export const getvideoDetails = (state: VideoReportState) => state.getvideoDetails;
export const getvideoDetailsLoading = (state: VideoReportState) => state.getvideoDetailsLoading;
export const getvideoDetailsLoaded = (state: VideoReportState) => state.getvideoDetailsLoaded;
export const getvideoDetailsFailed = (state: VideoReportState) => state.getvideoDetailsFailed;
