import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as store from '../app.state';
import * as subjectActions from './actions/common.action';
import { Subscription } from 'rxjs/index';
import { Subject } from 'rxjs';
import {
  schoolDetail,
  schoolDetailFailed,
  schoolDetailLoaded,
  schoolDetailLoading,
  passwordLoading,
  passwordLoaded,
  passwordFailed,
  userDetail,
  bulkupload,
  getUploadDetail,
  staffBulkUpload,
  staffBulkUploadSuccess,
  getMonthList,
  academicYearList,
  academicYearMonthList,
  academicYearDetails,
  changeAcademicYearSetting, academicYear, downloadStudentTemplate, downloadStudentTemplateLoading,
  downloadfees, downloadfeesLoading
} from './reducer/common.selector';

@Injectable()
export class CommonSandbox {
  public refreshStudent = new Subject<any>();
  public refreshStaff = new Subject<any>();
  public userDetail$ = this.appState.select(userDetail);
  public schoolDetail$ = this.appState.select(schoolDetail);
  public schoolDetailLoading$ = this.appState.select(schoolDetailLoading);
  public schoolDetailLoaded$ = this.appState.select(schoolDetailLoaded);
  public schoolDetailFailed$ = this.appState.select(schoolDetailFailed);

  public passwordLoading$ = this.appState.select(passwordLoading);
  public passwordLoaded$ = this.appState.select(passwordLoaded);
  public passwordFailed$ = this.appState.select(passwordFailed);
  public bulkupload$ = this.appState.select(bulkupload);
  public uploadedFile$ = this.appState.select(getUploadDetail);
  public staffBulkUpload$ = this.appState.select(staffBulkUpload);
  public staffBulkUploadSuccess$ = this.appState.select(staffBulkUploadSuccess);
  public monthList$ = this.appState.select(getMonthList);
  public academicYearList$ = this.appState.select(academicYearList);
  public academicYearMonthList$ = this.appState.select(academicYearMonthList);
  public academicYearDetails$ = this.appState.select(academicYearDetails);
  public changeAcademicYearSetting$ = this.appState.select(changeAcademicYearSetting);
  public academicYear$ = this.appState.select(academicYear);

  public downloadStudentTemplate$ = this.appState.select(downloadStudentTemplate);
  public downloadStudentTemplateLoading$ = this.appState.select(downloadStudentTemplateLoading);

  public downloadfees$ = this.appState.select(downloadfees);
  public downloadfeesLoading$ = this.appState.select(downloadfeesLoading);



  private subscriptions: Array<Subscription> = [];

  constructor(protected appState: Store<store.AppState>) {
  }
  public Downloadfees() {
    this.appState.dispatch(new subjectActions.Downloadfees());
  }
  public getSchooldetail() {
    this.appState.dispatch(new subjectActions.GetSchoolDetail());
  }
  public changePassword(params) {
    this.appState.dispatch(new subjectActions.ChangePassword(params));
  }
  public uploadStudentBulk(params) {
    this.appState.dispatch(new subjectActions.BulkStudentUpload(params));
  }
  public uploadStaffBulk(params) {
    this.appState.dispatch(new subjectActions.BulkStaffUpload(params));
  }
  public getMonthList(params) {
    this.appState.dispatch(new subjectActions.MonthListAction(params));
  }
  public getAcademicYearList(params) {
    this.appState.dispatch(new subjectActions.AcademicYearListAction(params));
  }
  public getAcademicYearMonthList(params) {
    this.appState.dispatch(new subjectActions.AcademicYearMonthListAction(params));
  }
  public getAcademicYearSetting(params) {
    this.appState.dispatch(new subjectActions.GetAcademicYearSettingAction(params));
  }
  public changeAcademicYearSetting(params) {
    this.appState.dispatch(new subjectActions.ChangeAcademicYearSetting(params));
  }
  public DownloadStudentTemplate() {
    this.appState.dispatch(new subjectActions.DownloadStuTemplate());
  }
  public getCallFlowPdf(params) {
    this.appState.dispatch(new subjectActions.GetCallFlowPdf(params));
  }
  public getIvrCodePdf(params) {
    this.appState.dispatch(new subjectActions.GetIvrCodePdf(params));
  }
  public getAppUserPdf(params) {
    this.appState.dispatch(new subjectActions.GetAppUserPdf(params));
  }

  public getyoutubemanualPdf(params) {
    this.appState.dispatch(new subjectActions.getyoutubemanualPdf(params));
  }
  public feeInvoiceData(params) {
    this.appState.dispatch(new subjectActions.FeeInvoiceData(params));
  }

}
