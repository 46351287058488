export const menu = [
  {
    path: '/dashboard',
    title: 'Dashboard',
    image: 'assets/imgs/dashboard.svg',
    actImg: 'assets/imgs/dashboard-active.svg',
    submenu: [],
    slug: 'dash_board',
    extralink: true,
    class: ''
  },
  {
    path: '',
    title: 'Master Setup',
    image: 'assets/imgs/settings.svg',
    actImg: 'assets/imgs/settings-active.svg',
    extralink: true,
    class: 'has-arrow',
    slug: 'master_setup',
    submenu: [
      {
        path: '/master-setup/standard',
        title: 'Standard',
        submenu: [],
        extralink: false,
        slug: 'standard',
        class: ''
      },
      {
        path: '/master-setup/subjects',
        title: 'Subject',
        submenu: [],
        extralink: false,
        slug: 'subjects',
        class: ''
      },
      {
        path: '/master-setup/group',
        title: 'Groups',
        submenu: [],
        extralink: false,
        slug: 'group',
        class: ''
      },
      {
        path: '/master-setup/user-previleges',
        title: 'User Privileges',
        submenu: [],
        extralink: false,
        slug: 'user_privileges',
        class: ''
      },
      {
        path: '/master-setup/feedback',
        title: 'Feedback Form',
        submenu: [],
        class: '',
        slug: 'feedback_form',
        extralink: false
      },
      {
        path: '/master-setup/wishlist',
        title: 'Wishlist',
        submenu: [],
        class: '',
        slug: 'wishlist',
        extralink: false
      },
      {
        path: '/master-setup/cclist/cclist',
        title: 'CC List',
        submenu: [],
        class: '',
        slug: 'cc_list',
        extralink: false
      }
    ]
  },
  {
    path: '/staff-management/list',
    title: 'Staff Management',
    image: 'assets/imgs/staff.svg',
    actImg: 'assets/imgs/class-active.svg',
    extralink: true,
    slug: 'staff_management',
    class: 'has-arrow',
    submenu: []
  },
  {
    path: '',
    title: 'Student Management',
    image: 'assets/imgs/Student.svg',
    actImg: 'assets/imgs/study-active.svg',
    extralink: true,
    slug: 'student_management',
    class: 'has-arrow',
    submenu: [
      {
        path: '/student-management/student-list',
        title: 'Manage Student Records',
        submenu: [],
        extralink: false,
        slug: 'student',
        class: ''
      },
      {
        path: '/student-management/promote-student',
        title: 'Promote Students',
        submenu: [],
        extralink: false,
        slug: 'promote_students',
        class: ''
      },
      {
        path: '/student-management/idCard-Generate',
        title: 'ID Card Generator',
        submenu: [],
        extralink: false,
        slug: 'id_card_generate',
        class: ''
      },
      {
        path: '/student-management/leave-request',
        title: 'Leave Request',
        submenu: [],
        extralink: false,
        slug: 'leave_request',
        class: ''
      }
    ]
  },
  {
    path: '',
    title: 'Admission',
    image: 'assets/imgs/admission.svg',
    actImg: 'assets/imgs/school-active.svg',
    extralink: true,
    slug: 'admission',
    class: 'has-arrow',
    submenu: [
      {
        path: '/admission/pre-admission',
        title: 'Pre Admission',
        submenu: [],
        extralink: false,
        slug: 'pre_admission',
        class: ''
      },
      {
        path: '/admission/pre-admission-selection',
        title: 'Pre Admission Selection',
        submenu: [],
        extralink: false,
        slug: 'pre_admission_selection',
        class: ''
      },
      {
        path: '/admission/main-admission',
        title: 'Main Admission',
        submenu: [],
        extralink: false,
        slug: 'main_admission',
        class: ''
      },
      {
        path: '/admission/import-student',
        title: 'Import Admission Enquiry',
        submenu: [],
        extralink: false,
        slug: 'import_student',
        class: ''
      },
      {
        path: '/admission/section-allotment',
        title: 'Section Allotment',
        submenu: [],
        extralink: false,
        slug: 'section_allotment',
        class: ''
      }
    ]
  },
  {
    path: '',
    title: 'Communication',
    image: 'assets/imgs/message.svg',
    actImg: 'assets/imgs/email-active.svg',
    extralink: true,
    slug: 'messages',
    class: 'has-arrow',
    submenu: [
      {
        path: '/messages/sms-template',
        title: 'Text Template',
        submenu: [],
        extralink: false,
        slug: 'sms_template',
        class: ''
      },
      {
        path: '/messages/text-file',
        title: 'Text/Pdf/Image',
        submenu: [],
        extralink: false,
        slug: 'text_file',
        class: ''
      },
      {
        path: '/messages/home-work',
        title: 'Home Work',
        submenu: [],
        extralink: false,
        slug: 'home_work',
        class: ''
      },
      {
        path: '/messages/schedule',
        title: 'Schedule Voice',
        submenu: [],
        extralink: false,
        slug: 'schedule',
        class: ''
      },
      {
        path: '/messages/attendance',
        title: 'Attendance',
        submenu: [],
        extralink: false,
        slug: 'attendance',
        class: ''
      },
      {
        path: '/messages/noticeboard',
        title: 'Notice Board',
        submenu: [],
        extralink: false,
        slug: 'noticeboard',
        class: ''
      },
      {
        path: '/messages/assignment',
        title: 'Assignment',
        submenu: [],
        extralink: false,
        slug: 'assignment',
        class: ''
      }
    ]
  },
  {
    path: '',
    title: 'Exam Management',
    image: 'assets/imgs/Student.svg',
    actImg: 'assets/imgs/study-active.svg',
    extralink: true,
    class: 'has-arrow',
    slug: 'exam_management',
    submenu: [
      {
        path: '/Mytest/exams',
        title: 'Exams',
        slug: 'exams',
        submenu: [],
        extralink: false,
        class: '',
      },
      {
        path: '/Mytest/marks',
        title: 'Exam Marks',
        slug: 'marks',
        submenu: [],
        extralink: false,
        class: '',
      },
    ]
  },
  {
    path: '',
    title: 'Progress Card Generator',
    image: 'assets/imgs/Student.svg',
    actImg: 'assets/imgs/study-active.svg',
    extralink: true,
    class: 'has-arrow',
    slug: 'progress_card_generator',
    submenu: [
      {
        path: '/Mytest/examresultanalysis',
        title: 'Exam Result Analysis',
        slug: 'results_exams',
        submenu: [],
        extralink: false,
        class: '',
      },
      {
        path: '/Mytest/progresscard',
        title: 'Progess Card',
        slug: 'progress_exams',
        submenu: [],
        extralink: false,
        class: '',
      },
    ]
  },
  {
    path: '',
    title: 'Transport Management',
    image: 'assets/imgs/bus.svg',
    actImg: 'assets/imgs/bus-active.svg',
    extralink: true,
    slug: 'vehicle_management',
    class: 'has-arrow',
    submenu: [
      {
        path: '/vehicle-management/busfee-list',
        title: 'Transport Fee Payment',
        submenu: [],
        extralink: false,
        slug: 'bus_fee_payment',
        class: '',
    },
      {
        path: '/vehicle-management/vehicles',
        title: 'Vehicles',
        submenu: [],
        extralink: false,
        slug: 'vehicles',
        class: ''
      },
      {
        path: '/vehicle-management/driver',
        title: 'Drivers / Assistants',
        submenu: [],
        extralink: false,
        slug: 'driver_details',
        class: ''
      },
      {
        path: '/vehicle-management/stopping-points',
        title: 'Stopping Points',
        submenu: [],
        extralink: false,
        slug: 'stopping_points',
        class: ''
      },
      {
        path: '/vehicle-management/route-master',
        title: 'Route Master',
        submenu: [],
        extralink: false,
        slug: 'route_master',
        class: ''
      }
    ]
  },
  {
    path: '',
    title: 'Communication Report',
    image: 'assets/imgs/reports.svg',
    actImg: 'assets/imgs/report-active.svg',
    extralink: true,
    slug: 'report',
    class: 'has-arrow',
    submenu: [
      {
        path: '/report/sms',
        title: 'Text',
        submenu: [],
        extralink: false,
        slug: 'sms',
        class: ''
      },
      {
        path: '/report/voice',
        title: 'Voice',
        submenu: [],
        extralink: false,
        slug: 'voice',
        class: ''
      },
      {
        path: '/report/files',
        title: 'Image / PDF',
        submenu: [],
        extralink: false,
        slug: 'files',
        class: ''
      },
      {
        path: '/report/appusereport',
        title: 'App Usage',
        submenu: [],
        extralink: false,
        slug: 'app_use_report',
        class: ''
      },
      {
        path: '/report/assignment',
        title: 'Assignment',
        submenu: [],
        extralink: false,
        slug: 'assignment_report',
        class: ''
      }
    ]
  },
  {
    path: '',
    title: 'Fees Management',
    image: 'assets/imgs/fee.svg',
    actImg: 'assets/imgs/fee-active.svg',
    extralink: true,
    slug: 'fees_management',
    class: 'has-arrow',
    submenu: [
      {
        path: '/fees-management/payment',
        title: 'Fee Payment',
        submenu: [],
        extralink: false,
        slug: 'fee_payment',
        class: ''
      },
      {
        path: '/fees-management/discount',
        title: 'Discounts',
        submenu: [],
        extralink: false,
        slug: 'fee_discount',
        class: ''
      },
      {
        path: '/fees-management/ex-curricular',
        title: 'Specific Student Fee',
        submenu: [],
        extralink: false,
        slug: 'extra_curricular',
        class: ''
      },
      {
        path: '/fees-management/rate',
        title: 'Fee Rate',
        submenu: [],
        extralink: false,
        slug: 'fee_rate',
        class: ''
      },
      {
        path: '/fees-management/group',
        title: 'Fee Group',
        submenu: [],
        extralink: false,
        slug: 'fee_group',
        class: ''
      },
      {
        path: '/fees-management/discount-type',
        title: 'Discount Type',
        submenu: [],
        extralink: false,
        slug: 'fee_discount_type',
        class: ''
      },
      {
        path: '',
        title: 'Term Setup',
        submenu: [],
        extralink: false,
        slug: 'fee_term_setup',
        class: '',
        isModal: true
      },
    ]
  },
  {
    path: '',
    title: 'Fees Report',
    image: 'assets/imgs/settings.svg',
    actImg: 'assets/imgs/settings-active.svg',
    extralink: true,
    slug: 'fees_report',
    class: 'has-arrow',
    submenu: [
        {
            path: '/feesreport/term-report',
            title: 'Term',
            submenu: [],
            extralink: false,
            slug: 'term_report',
            class: '',
        },
        {
            path: '/feesreport/other-report',
            title: 'Others',
            submenu: [],
            extralink: false,
            slug: 'other_fee',
            class: '',
        },
        {
            path: '/feesreport/transport',
            title: 'Transport Fee',
            submenu: [],
            extralink: false,
            slug: 'transport_fee',
            class: '',
        },
        {
            path: '/feesreport/daily-collection',
            title: 'Daily Collection',
            submenu: [],
            extralink: false,
            slug: 'daily_collection',
            class: '',
        },
        {
            path: '/feesreport/overall-student-fee',
            title: 'Overall Student',
            submenu: [],
            extralink: false,
            slug: 'overall_student_fee',
            class: '',
        },
        {
            path: '/feesreport/overall-invoice',
            title: 'Overall Invoice',
            submenu: [],
            extralink: false,
            slug: 'overall_invoice',
            class: '',
        },
        {
            path: '/feesreport/reject-invoice',
            title: 'Reject Invoice',
            submenu: [],
            extralink: false,
            slug: 'reject_invoice',
            class: '',
        },
        {
            path: '/feesreport/fee-discount',
            title: 'Discount',
            submenu: [],
            extralink: false,
            slug: 'fee_discount',
            class: '',
        },
        {
          path: '',
          title: 'Old Fees',
          submenu: [],
          extralink: false,
          slug: 'old_fees',
          class: '',
          isModal: true
        },
    ]
},
  {

    path: '',
    title: 'Certificates',
    image: 'assets/imgs/certificate.svg',
    actImg: 'assets/imgs/certificate-active.svg',
    extralink: true,
    slug: 'certificates',
    class: 'has-arrow',
    submenu: [
        {
            path: '/certificates/conduct',
            title: 'Conduct Certificate',
            submenu: [],
            extralink: false,
            slug: 'conduct_certificate',
            class: '',
        },
        {
        path: '/certificates/study',
        title: 'Study Certificate',
        submenu: [],
        extralink: false,
        slug: 'study_certificate',
        class: '',
      },
      {
        path: '/certificates/transfer',
        title: 'Transfer Certificate',
        submenu: [],
        extralink: false,
        slug: 'transfer_certificate',
        class: '',
      },
    ]
},
  {
    path: '',
    title: 'ID Card Generator',
    image: 'assets/imgs/certificate.svg',
    actImg: 'assets/imgs/certificate-active.svg',
    extralink: true,
    slug: 'id_card_generate',
    class: 'has-arrow',
    submenu: [
        {
            path: '/idcard/Studentid',
            title: 'Student',
            submenu: [],
            extralink: false,
            slug: 'student_id_card',
            class: '',
        },
        {
        path: '/student-management/idCard-Generate',
        title: 'ID Card Generator',
        submenu: [],
        extralink: false,
        slug: 'id_card_generate',
        class: ''
      }
    ]
  },
  {
    path: '/inventory',
    title: 'Inventory',
    image: 'assets/imgs/settings.svg',
    actImg: 'assets/imgs/settings.svg',
    extralink: true,
    slug: 'inventory_mgmt',
    class: 'has-arrow',
    submenu: [
      // // item-master-start
      // {
      //   path: '/inventory/item-master',
      //   title: 'Master',
      //   extralink: true,
      //   // slug: 'inventory-master',
      //   submenu: [
          {
            path: '/inventory/item-master/category-list',
            title: 'Category',
            submenu: [],
            // slug: 'category',
            extralink: true,
            class: '',
          }, {
            path: '/inventory/item-master/uom-list',
            title: 'UOM',
            submenu: [],
            extralink: true,
            slug: 'uom',
            class: '',
          },
          {
            path: '/inventory/item-master/brand-list',
            title: 'Brand',
            submenu: [],
            extralink: true,
            slug: 'brand',
            class: '',
          },
          {
            path: '/inventory/manage-combo',
            title: 'Manage Combo',
            submenu: [],
            extralink: false,
            slug: 'manage-combo',
            class: '',
          },
      //   ],
      //   class: '',
      // },
      {
        path: '/inventory/manage-items',
        title: 'Manage Items',
        submenu: [],
        slug: 'manage-items',
        extralink: true,
        class: '',
      },
      {
        path: '/inventory/suppliers',
        title: 'Suppliers',
        submenu: [],
        slug: 'supplier',
        extralink: false,
        class: '',
      },
      {
        path: '/inventory/closing-stock',
        title: 'System-Stock',
        submenu: [],
        slug: 'system-Stock',
        extralink: false,
        class: '',
      },
      {
        path: '/inventory/manage-open-stock',
        title: 'Manage Open Stock',
        submenu: [],
        slug: 'manage-open-stock',
        extralink: false,
        class: '',
      },
      {
        path: '/inventory/manage-po-pr',
        title: 'Manage PO/PR',
        submenu: [],
        slug: 'manage-po-pr',
        extralink: false,
        class: '',
      },
      {
        path: '/inventory/manage-grn',
        title: 'Manage GRN',
        submenu: [],
        slug: 'manage-grn',
        extralink: false,
        class: '',
      },
      {
        path: '/inventory/manage-distribution',
        title: 'Manage Distribution',
        submenu: [],
        slug: 'manage-distribution',
        extralink: false,
        class: '',
      },
    ]
  },
  {
    path: '/payroll',
    image: 'assets/imgs/support.svg',
    actImg: 'assets/imgs/support.svg',
    title: 'Payroll Management',
    slug: 'payroll',
    extralink: true,
    submenu: [
      {
        path: '/payroll/bank-master',
        title: 'Bank Master',
        submenu: [],
        slug: 'bank_master',
        extralink: false,
        class: '',
      },
      {
        path: '/payroll/manage-payroll',
        title: 'Payroll Master',
        submenu: [],
        slug: 'manage-payroll',
        extralink: false,
        class: '',
      },
      {
        path: '/payroll/manage-expenses',
        title: 'Manage Expenses',
        submenu: [],
        slug:'manage-expenses',
        extralink: false,
        class: '',
      },
      {
        path: '/payroll/manage-employee-salary',
        title: 'Manage Employee Salary',
        submenu: [],
        slug:'manage-employee-salary',
        extralink: false,
        class: '',
      },
      {
        path: '/payroll/make-payment',
        title: 'Manage Payment',
        submenu: [],
        slug: 'make-payment',
        extralink: false,
        class: '',
      },
      {
        path: '/payroll/manage-payslip',
        title: 'Print Payslip',
        submenu: [],
        slug: 'manage-payslip',
        extralink: false,
        class: '',
      }
    ],
    class: '',
  },
  {
    path: '',
    title: 'Attendance',
    slug: 'staff-attendance',
    image: 'assets/imgs/staff.svg',
    actImg: 'assets/imgs/staff.svg',
    submenu: [
      {
        path: '/attendance/manage-attendance',
        title: 'Manage Attendance',
        submenu: [],
        slug: 'manage-attandance',
        extralink: false,
        class: '',
      },
      {
        path: '/payroll/leave-category-master',
        title: 'Leave Category Master',
        submenu: [],
        slug: 'leave-category-master',
        extralink: false,
        class: '',
      },
      // {
      //   path: '/attendance/manage-attendance-report',
      //   title: 'Manage Attendance Report',
      //   submenu: [],
      //   extralink: false,
      //   class: '',
      // },
      {
        path: '/attendance/manage-holidays',
        title: 'Manage Holidays',
        submenu: [],
        slug: 'manage-holidays',
        extralink: false,
        class: '',
      }
    ],
    extralink: true,
    class: '',
  },
  {
    path: '/reports',
    title: 'Reports',
    image: 'assets/imgs/settings.svg',
    actImg: 'assets/imgs/settings.svg',
    extralink: true,
    slug: 'reports',
    class: 'has-arrow',
    submenu: [
      {
        path: '/reports/attendance-reports',
        title: 'Attendance-Reports',
        submenu: [],
        extralink: true,
        class: '',
      },
      {
        path: '/reports/inventory-reports',
        title: 'Inventory-Reports',
        submenu: [],
        extralink: true,
        class: '',
      },
      {
        path: '/reports/payroll-reports',
        title: 'Payroll-Reports',
        submenu: [],
        extralink: true,
        class: '',
      }
    ]
  },
  {
    path: '',
    title: 'Uploads',
    image: 'assets/imgs/feedback.svg',
    actImg: 'assets/imgs/feedback.svg',
    class: 'has-arrow',
    extralink: true,
    slug: 'uploads',
    submenu: [
      {
        path: '',
        title: 'Upload Staff',
        submenu: [],
        extralink: false,
        slug: 'upload_staff',
        class: '',
        isModal: true
      },
      {
        path: '',
        title: 'Upload Student',
        submenu: [],
        extralink: false,
        slug: 'upload_student',
        class: '',
        isModal: true
      }
    ]
  },

{

  path: '',
  title: 'Help',
  image: 'assets/imgs/helpNormal.svg',
  actImg: 'assets/imgs/help.svg',

  extralink: true,
  slug: 'help',
  class: 'has-arrow',
  submenu: [
      {
          path: '',
          title: 'Call Flow',
          submenu: [],
          extralink: false,
          slug: 'call_flow',
          class: '',
          isModal: true
      },
      {
          path: '',
          title: 'STD Sec Code',
          submenu: [],
          extralink: false,
          slug: 'std_sec_code',
          class: '',
          isModal: true
      },
      {
        path: '',
        title: 'App User Manual',
        submenu: [],
        extralink: false,
        slug: 'app_user_manual',
        class: '',
        isModal: true
    }
    ,
      {
        path: '',
        title: 'Youtube Upload Manual',
        submenu: [],
        extralink: false,
        slug: 'youtube_upload_manual',
        class: '',
        isModal: true
    }
  ]
},
{
  path: '/activity-log',
  title: 'Activity Log',
  image: 'assets/imgs/activityNormal.svg',
  actImg: 'assets/imgs/activity.svg',
  extralink: true,
  slug: 'audit_log',
  class: 'has-arrow',
  submenu: []
},

];
