import {Map, Record} from 'immutable';

export interface AssignmentListState extends Map<string, any> {

  
    getFilesReportList: any;
    getFilesReportDetails: any;
    tempGetFilesReportDetails: any;
    tempDetailsCount:any;
    fileSentGroupList: any;
    exportFileList: any;
    memberExportList: any;
    reportCount: any;
    getImagefiles: any;
   deleteAssignment  :any;

    reportCountLoading: boolean;
    reportCountLoaded: boolean;
    reportCountFailed: boolean;

    memberExportListLoading: boolean;
    memberExportListLoaded: boolean;
    memberExportListFailed: boolean;

    exportFileListLoading: boolean;
    exportFileListLoaded: boolean;
    exportFileListFailed: boolean;

    getFilesReportListLoading: boolean;
    getFilesReportListLoaded: boolean;
    getFilesReportListFailed: boolean;

    getFilesReportDetailsLoading: boolean;
    getFilesReportDetailsLoaded: boolean;
    getFilesReportDetailsFailed: boolean;

    fileSentGroupListLoading: boolean;
    fileSentGroupListLoaded: boolean;
    fileSentGroupListFailed: boolean;

    getImagefilesLoading: boolean;
    getImagefilesLoaded: boolean;
    getImagefilesFailed: boolean;

    deleteAssignmentLoading: boolean;
    deleteAssignmentLoaded: boolean;
    deleteAssignmentFailed: boolean;

    getAssignmentList: any;
    getAssignmentListLoading: boolean;
    getAssignmentListLoaded: boolean;
    getAssignmentListFailed: boolean;
    
}

export const AssignmentListStateRecord = Record({

    getFilesReportList: [],
    getFilesReportDetails: [],
    fileSentGroupList: [],
    exportFileList: [],
    memberExportList: [],
    tempGetFilesReportDetails: [],
    reportCount: [],
    getImagefiles:[],
    deleteAssignment  :[],
    tempDetailsCount: {},

    reportCountLoading: false,
    reportCountLoaded: false,
    reportCountFailed: false,

    memberExportListLoading: false,
    memberExportListLoaded: false,
    memberExportListFailed: false,

    exportFileListLoading: false,
    exportFileListLoaded: false,
    exportFileListFailed: false,

    getFilesReportListLoading: false,
    getFilesReportListLoaded: false,
    getFilesReportListFailed: false,

    getFilesReportDetailsLoading: false,
    getFilesReportDetailsLoaded: false,
    getFilesReportDetailsFailed: false,

    fileSentGroupListLoading: false,
    fileSentGroupListLoaded: false,
    fileSentGroupListFailed: false,

    getImagefilesLoading: false,
    getImagefilesLoaded: false,
    getImagefilesFailed: false,
    
   deleteAssignmentLoading: false,
   deleteAssignmentLoaded: false,
   deleteAssignmentFailed: false,

   getAssignmentList: [],
    getAssignmentListLoading: false,
    getAssignmentListLoaded: false,
    getAssignmentListFailed: false,
});

