import {Map, Record} from 'immutable';

export interface VideoReportState extends Map<string, any> {

    videoReportList: any;
    videoReportListCount: any;

    getvideoDetails: any;
    videoMemberDetails: any;
    tempvideoMemberDetails: any;

    getvideoDetailsLoading: boolean;
    getvideoDetailsLoaded: boolean;
    getvideoDetailsFailed: boolean;

    videoMemberDetailsLoading: boolean;
    videoMemberDetailsLoaded: boolean;
    videoMemberDetailsFailed: boolean;

    videoReportListLoading: boolean;
    videoReportListLoaded: boolean;
    videoReportListFailed: boolean;

    videoReportListCountLoading: boolean;
    videoReportListCountLoaded: boolean;
    videoReportListCountFailed: boolean;
}

export const VideoReportStateRecord = Record({
    videoReportList: [],
    videoReportListCount: [],
    getvideoDetails: [],
    videoMemberDetails: [],
    tempvideoMemberDetails: [],

    getvideoDetailsLoading: false,
    getvideoDetailsLoaded: false,
    getvideoDetailsFailed: false,

    videoMemberDetailsLoading: false,
    videoMemberDetailsLoaded: false,
    videoMemberDetailsFailed: false,

    videoReportListLoading: false,
    videoReportListLoaded: false,
    videoReportListFailed: false,

    videoReportListCountLoading: false,
    videoReportListCountLoaded: false,
    videoReportListCountFailed: false,

});

