import { Component, OnInit } from '@angular/core';
import { menu } from './side-menu';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { filter, map, mergeMap } from 'rxjs/operators';
import { CommonSandbox } from 'src/app/common/common.sandbox';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { environment } from '../../../../environments/environment';
import {get} from 'lodash';
import {AuthGuard} from '../../../providers/guards/auth.guard';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  menuItem: any;
  userMenu: any;
  showMenu = 'Dashboard';
  finalMenu = [];
  activeMenu = '';
  showSubMenu = '';
  instituteLogo: any;
  admin: any;
  tempMenuItem = [];
  redirectUrl = environment.url;
  MultiChildDetails: any;

  defaultInstituteLogo = 'assets/imgs/school-logo.png';
  constructor(public router: Router,
    public activatedRoute: ActivatedRoute,
    public commonSandBox: CommonSandbox,
    public modalService: NgbModal,
    public authGuard: AuthGuard) {
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .pipe(map(() => this.activatedRoute))
      .pipe(
        map(route => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        })
      )
      .pipe(filter(route => route.outlet === 'primary'))
      .pipe(mergeMap(route => route.data))
      .subscribe(event => {
        this.showMenu = event['title'];
        this.activeMenu = event['title'];
      });
  }

  ngOnInit() {
    debugger;
    this.instituteLogo = JSON.parse(localStorage.getItem('instituteDetails'));
    this.menuItem = menu;
    this.admin = localStorage.getItem('superAdmin');
    this.getUserMenu();
    this.disableMenu();
    const ChildList = localStorage.getItem('ChildListdetails');
    console.log('ChildList',ChildList)
    this.MultiChildDetails = ChildList == '1' ? 1 : 2;
    console.log('MultiChildDetails',this.MultiChildDetails)
  }

  openDropdown(event) {
    event.stopPropagation();
  }
  // this is for the open close
  addExpandClass(element: any) {
    this.activeMenu = element.title;
    if (element.title === this.showMenu) {
      this.showMenu = '0';
    } else {
      this.showMenu = element.title;
    }
    if (element.title === 'Exit') {
      localStorage.removeItem('instituteToken');
      localStorage.removeItem('superAdmin');
      localStorage.removeItem('instituteDetails');
      localStorage.removeItem('userMenu');
      window.open(this.redirectUrl, '_self');
    }
  }

  addActiveClass(element: any) {

    if (element === this.showSubMenu) {
      this.showSubMenu = '0';
    } else {
      this.showSubMenu = element;
    }
  }
  searchMenu(keyword) {
    // this.menuItem = JSON.parse(JSON.stringify(menu));
    this.menuItem = this.tempMenuItem.filter(item => {
      return item.title.toLowerCase().includes(keyword);
    });
  }
  uploadModal(data) {


    // if (data.title === 'Old Fees' && data.isModal) {
    //   const modalRef = this.modalService.open(ViewOldFeesComponent, { backdrop: 'static' , windowClass: 'my-upload'});

    //   modalRef.componentInstance.standardDetails = data;

    //   modalRef.result.then((result) => {
    //     if (result) {
    //     }
    //   });
    // }
    
    // if (data.title === 'Upload Staff' && data.isModal) {
    //   const modalRef = this.modalService.open(UploadStaffComponent, { backdrop: 'static', windowClass: 'my-upload' });
    //   modalRef.componentInstance.standardDetails = data;
    //   modalRef.result.then((result) => {
    //     if (result) {
    //     }
    //   });

    // }
    // if (data.title === 'Upload Student' && data.isModal) {
    //   const modalRef = this.modalService.open(UploadStudentComponent, { backdrop: 'static', windowClass: 'my-upload' });
    //   modalRef.componentInstance.standardDetails = data;
    //   modalRef.result.then((result) => {
    //     if (result) {
    //     }
    //   });

    // }
    // if (data.title === 'Term Setup' && data.isModal) {
    //   const modalRef = this.modalService.open(TermSetupComponent, { backdrop: 'static' });

    //   modalRef.componentInstance.standardDetails = '';

    //   modalRef.result.then((result) => {
    //     if (result) {
    //     }
    //   });
    // }
    if (data.title === 'Call Flow' && data.isModal) {
       this.commonSandBox.getCallFlowPdf({});
    }
    if (data.title === 'STD Sec Code' && data.isModal) {
       this.commonSandBox.getIvrCodePdf({});
   }
   if (data.title === 'App User Manual' && data.isModal) {
       this.commonSandBox.getAppUserPdf({});
   }
   if (data.title === 'Youtube Upload Manual' && data.isModal) {
    this.commonSandBox.getyoutubemanualPdf({});
  }

   
  }

  getUserMenu() {
    const tempMenu = localStorage.getItem('userMenu');
    if (tempMenu !== 'undefined') {
      this.userMenu = JSON.parse(localStorage.getItem('userMenu'));
    } else {
      this.userMenu = [];
    }
  }
  disableMenu() {
    console.log('this.userMenu', this.userMenu);
    if (this.userMenu && this.userMenu.length > 0) {
      this.userMenu.forEach(value => {
        this.menuItem.forEach(data => {
            if (get(value.child, 'length') > 0) {
              if (data.slug === value.menuSlug) {
                let tempArray = [];
                data['is_access'] = value['is_access'] || 0;
                data['sortOrder'] = value['sortOrder'];
                value.child.forEach(child => {
                  data.submenu.forEach((sub) => {
                      if (sub.slug === child.menuSlug) {
                        sub['is_access'] = child['is_access'] || 0;
                        sub['sortOrder'] = child['sortOrder'];
                        tempArray.push(sub);
                      }
                  });
                });
                data.submenu = tempArray;
                this.finalMenu.push(data);
              }
            } else {
              if (value.menuSlug === data.slug) {
                 data['is_access'] = value['is_access'] || 0;
                 data['sortOrder'] = value['sortOrder'];
                this.finalMenu.push(data);
              }
            }
        });
      });
    } else {
      this.finalMenu = [];
    }
// console.log('this.finalMenu', this.finalMenu);
if (this.admin && this.admin === 'yes') {
  // this.finalMenu.forEach(data => {
  //   const ccmenu = {
  //     path: '/master-setup/cclist',
  //     title: 'CC List',
  //     submenu: [],
  //     class: '',
  //     slug: 'cc_list',
  //     extralink: false
  //    };
  //  if (data.slug === 'master_setup') {
  //      data.submenu.push(ccmenu);
  //     }
  //   });

// const upload = {
// path: '',
// title: 'Uploads',
// image: 'assets/imgs/feedback.svg',
// actImg: 'assets/imgs/feedback.svg',
// class: 'has-arrow',
// extralink: true,
// slug: 'uploads',
// submenu: [
//   {
//     path: '',
//     title: 'Upload Staff',
//     submenu: [],
//     extralink: false,
//     slug: 'upload_staff',
//     class: '',
//     isModal: true
//   },
//   {
//     path: '',
//     title: 'Upload Student',
//     submenu: [],
//     extralink: false,
//     slug: 'upload_student',
//     class: '',
//     isModal: true
//   }
// ]
// };

// this.finalMenu.push(upload);

  const obj = {
    path: '',
    title: 'Exit',
    image: 'assets/imgs/support.svg',
    actImg: 'assets/imgs/support-active.svg',
    submenu: [],
    class: '',
    extralink: false,
    is_access: 1,
    sortOrder: 100,
  };
  this.finalMenu.push(obj);
}
    this.finalMenu =  this.finalMenu.sort((a , b) => a['sortOrder'] - b['sortOrder']);
    this.finalMenu = this.finalMenu.map(order => {
      if (get(order , 'submenu.length') > 0) {
        order.submenu.sort((a, b) => a['sortOrder'] - b['sortOrder']);
      }
      return order;
    });
// this.finalMenu.map(data => {
//   if (data.submenu.length > 0) {
//     data.submenu.forEach((val, index) => {
//       if (index === 0) {
//         data.path = val.path;
//       }
//     });
//   }
// });
   console.log('this.finalMenu', this.finalMenu);
    this.menuItem = this.finalMenu;
    this.tempMenuItem = this.finalMenu;

  }
}
