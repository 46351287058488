import { LoginListState, LoginListStateRecord } from './loginlist.state';
import * as actions from '../actions/loginlist.action';

export const initialState: LoginListState = (new LoginListStateRecord() as unknown) as LoginListState;

export function reducer(
  state = initialState,
  { type, payload }: any
): LoginListState {
  if (!type) {
    return state;
  }

  switch (type) {
    /**
     * get school detail reducer case function
     */
    case actions.ActionTypes.DASHBOARD_LIST:
      return Object.assign({}, state, {
        dashboardCountLoading: true,
        dashboardCountLoaded: false,
        dashboardCountFailed: false,
      });
    case actions.ActionTypes.DASHBOARD_LIST_SUCCESS:
    console.log('dash', payload.data);
      return Object.assign({}, state, {
        dashboardCount: payload.data,
        dashboardCountLoading: false,
        dashboardCountLoaded: true,
        dashboardCountFailed: false,
      });
    case actions.ActionTypes.DASHBOARD_LIST_FAIL:
      return Object.assign({}, state, {
        dashboardCountLoading: false,
        dashboardCountLoaded: false,
        dashboardCountFailed: true,
      });

      case actions.ActionTypes.RENEWAL_DETAILS_LIST:
      return Object.assign({}, state, {
        renewalDetailsLoading: true,
        renewalDetailsLoaded: false,
        renewalDetailsFailed: false,
      });
    case actions.ActionTypes.RENEWAL_DETAILS_LIST_SUCCESS:
      return Object.assign({}, state, {
        renewalDetails: payload.data,
        renewalDetailsLoading: false,
        renewalDetailsLoaded: true,
        renewalDetailsFailed: false,
      });
    case actions.ActionTypes.RENEWAL_DETAILS_LIST_FAIL:
      return Object.assign({}, state, {
        renewalDetailsLoading: false,
        renewalDetailsLoaded: false,
        renewalDetailsFailed: true,
      });
    default: {
      return state;
    }
  }
}

/**
 * export values
 */

export const dashboardCount = (state: LoginListState) => state.dashboardCount;
export const dashboardCountLoading = (state: LoginListState) => state.dashboardCountLoading;
export const dashboardCountLoaded = (state: LoginListState) => state.dashboardCountLoaded;
export const dashboardCountFailed = (state: LoginListState) => state.dashboardCountFailed;

export const renewalDetails = (state: LoginListState) => state.renewalDetails;
export const renewalDetailsLoading = (state: LoginListState) => state.renewalDetailsLoading;
export const renewalDetailsLoaded = (state: LoginListState) => state.renewalDetailsLoaded;
export const renewalDetailsFailed = (state: LoginListState) => state.renewalDetailsFailed;
