
import { NgModule} from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutComponent } from './layout/layout.component';
import { SharedModule } from '../shared/shared.module';
import { EffectsModule } from '@ngrx/effects';
import { CommonEffect } from './effects/common.effect';
import { CommonSandbox } from './common.sandbox';
import { CommonService } from './common.service';

export const CONTAINERS = {
    LayoutComponent,
};
@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        EffectsModule.forFeature([CommonEffect])
    ],
    providers: [
        CommonSandbox,
        CommonService
    ]
})
export class CommonLayoutModule {
}
