import { Component, OnInit, Input, OnChanges } from '@angular/core';

@Component({
  selector: 'app-driver-details-form',
  templateUrl: './driver-details-form.component.html',
  styleUrls: ['./driver-details-form.component.scss']
})
export class DriverDetailsFormComponent implements OnInit, OnChanges  {

  @Input() driverDetail: any;
  @Input() sameDriverDetail: any;
  currentDriver: any;
  constructor() {}

  ngOnInit() {
    // this.schoolName = JSON.parse(localStorage.getItem('instituteDetails')).institudeName;
  }
  ngOnChanges() {
    console.log('studentDetail', this.driverDetail);
    console.log('samestudentDetail', this.sameDriverDetail);
    if (this.driverDetail) {
      this.currentDriver = this.driverDetail;
      setTimeout(() => {
        let printContents, popupWin;
        printContents = document.getElementById('invoice').innerHTML;
        popupWin = window.open(
          '',
          '_blank',
          'top=0,left=0,height=100%,width=auto'
        );
        popupWin.document.open();
        popupWin.document.write(`
        <html>
          <head>
            <style>
            </style>
          </head>
          <body onload="window.print();window.close()">${printContents}</body>
        </html>`);
        popupWin.document.close();
      }, 400);
    }
  }

}
