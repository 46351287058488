import * as actions from '../actions/assignment.action';
import {AssignmentListState, AssignmentListStateRecord} from './assignment.state';
import {get, filter, uniqBy} from 'lodash';
//import {AssignmentListResponseModel} from '../models/assignmentlist-response.model';
import{AssignmentListResponseModel} from '../models/assignmentlist-response.model';

import {DetailedReportAssignmentResponseModel} from '../models/detailed-report-assignment-response.model';

export const initialState: AssignmentListState = (new AssignmentListStateRecord() as unknown) as AssignmentListState;

export function reducer(state = initialState, {type, payload}: any): AssignmentListState {
    if (!type) {
        return state;
    }

    switch (type) {
        /**
     * get files list
     */
    case actions.ActionTypes.GET_FILES_REPORT_LIST:
        return Object.assign({}, state, {
          getFilesReportList: [],
          getFilesReportListLoading: true,
          getFilesReportListLoaded: false,
          getFilesReportListFailed: false,
        });
      case actions.ActionTypes.GET_FILES_REPORT_LIST_SUCCESS:
        console.log('payload', payload);
        console.log('payload.data',payload.data);
   
        return Object.assign({}, state, {
         // getFilesReportList: payload.data, // fileReportList, 
        //   getFilesReportList: payload.data,
          getFilesReportListLoading: false,
          getFilesReportListLoaded: true,
          getFilesReportListFailed: false,
        });

      case actions.ActionTypes.GET_FILES_REPORT_LIST_FAIL:
        return Object.assign({}, state, {
          getFilesReportListLoading: false,
          getFilesReportListLoaded: false,
          getFilesReportListFailed: true,
  
        });
  
        /**
       * get files report details
       */
        case actions.ActionTypes.GET_FILES_REPORT_DETAILS:
      return Object.assign({}, state, {
          getFilesReportDetails: [],
          tempGetFilesReportDetails: [],
          getFilesReportDetailsLoading:  true,
          getFilesReportDetailsLoaded: false,
          getFilesReportDetailsFailed: false,
      });
    case actions.ActionTypes.GET_FILES_REPORT_DETAILS_SUCCESS:
    const fileReportDetails = payload.data.map(data => {
      console.log('reducer', data);
      const tempFileReportDetails = new DetailedReportAssignmentResponseModel(data);
       return tempFileReportDetails;
      });
      return Object.assign({}, state, {
          getFilesReportDetails: fileReportDetails,
          tempGetFilesReportDetails: fileReportDetails,
          tempDetailsCount: payload.statusAllCount,
          getFilesReportDetailsLoading:  false,
          getFilesReportDetailsLoaded: true,
          getFilesReportDetailsFailed: false,
      });
    case actions.ActionTypes.GET_FILES_REPORT_DETAILS_FAIL:
      return Object.assign({}, state, {
          getFilesReportDetailsLoading:  false,
          getFilesReportDetailsLoaded: false,
          getFilesReportDetailsFailed: true,
  
      });
  
       /**
       * get files report details
       */
      case actions.ActionTypes.FILE_SENT_GROUP_LIST:
      return Object.assign({}, state, {
          fileSentGroupListLoading:  true,
          fileSentGroupListLoaded: false,
          fileSentGroupListFailed: false,
      });
    case actions.ActionTypes.FILE_SENT_GROUP_LIST_SUCCESS:

    // let x = payload.data.value;
    // const groupList = x.split(',');
    // console.log('groupList', groupList);
      return Object.assign({}, state, {
          fileSentGroupList: payload.data,
          fileSentGroupListLoading:  false,
          fileSentGroupListLoaded: true,
          fileSentGroupListFailed: false,
      });
    case actions.ActionTypes.FILE_SENT_GROUP_LIST_FAIL:
      return Object.assign({}, state, {
          fileSentGroupListLoading:  false,
          fileSentGroupListLoaded: false,
          fileSentGroupListFailed: true,
  
      });
      // file export
        case actions.ActionTypes.EXPORT_FILE_LIST:
            return Object.assign({}, state, {
                exportFileListLoading: true,
                exportFileListLoaded: false,
                exportFileListFailed: false,
            });
        case actions.ActionTypes.EXPORT_FILE_LIST_SUCCESS:
            return Object.assign({}, state, {
                exportFileList: payload.data,
                exportFileListLoading: false,
                exportFileListLoaded: true,
                exportFileListFailed: false,
            });
        case actions.ActionTypes.EXPORT_FILE_LIST_FAIL:
            return Object.assign({}, state, {
                exportFileListLoading: false,
                exportFileListLoaded: false,
                exportFileListFailed: true,
            });
            // member details
        case actions.ActionTypes.MEMBER_EXPORT:
            return Object.assign({}, state, {
                memberExportListLoading: true,
                memberExportListLoaded: false,
                memberExportListFailed: false,
            });
        case actions.ActionTypes.MEMBER_EXPORT_SUCCESS:
            return Object.assign({}, state, {
                memberExportList: payload.data,
                memberExportListLoading: false,
                memberExportListLoaded: true,
                memberExportListFailed: false,
            });
        case actions.ActionTypes.MEMBER_EXPORT_FAIL:
            return Object.assign({}, state, {
                memberExportListLoading: false,
                memberExportListLoaded: false,
                memberExportListFailed: true,
            });
            // report list count
        case actions.ActionTypes.GET_FILES_REPORT_COUNT_LIST:
            return Object.assign({}, state, {
                getFilesReportListLoading: true,
                reportCountLoading: true,
                reportCountLoaded: false,
                reportCountFailed: false,
            });
        case actions.ActionTypes.GET_FILES_REPORT_COUNT_LIST_SUCCESS:
            console.log('payload.data',payload.data);
            console.log('payload.count',payload.count);
            return Object.assign({}, state, {
                getFilesReportList: payload.data,
                getFilesReportListLoading: false,
                reportCount: payload.count,
                reportCountLoading: false,
                reportCountLoaded: true,
                reportCountFailed: false,
            });
        case actions.ActionTypes.GET_FILES_REPORT_COUNT_LIST_FAIL:
            return Object.assign({}, state, {
                reportCountLoading: false,
                getFilesReportListLoading: false,
                reportCountLoaded: false,
                reportCountFailed: true,
            });
            // search member
        case actions.ActionTypes.SEARCH_MEMBER_LIST:
            let tempMemberSearch = state.tempGetFilesReportDetails;
            const search = payload.toLowerCase();
            tempMemberSearch = filter(tempMemberSearch, (item) => {
                    return item.studentName.toLowerCase().includes(search);
            });
            return Object.assign({}, state, {
                getFilesReportDetails: tempMemberSearch,
            });

             // get image file 
        case actions.ActionTypes.GET_IMAGE_FILES:
            return Object.assign({}, state, {
                getImagefilesLoading: true,
                getImagefilesLoaded: false,
                getImagefilesFailed: false,
            });
        case actions.ActionTypes.GET_IMAGE_FILES_SUCCESS:
            return Object.assign({}, state, {
                getImagefiles: payload.data,
                getImagefilesLoading: false,
                getImagefilesLoaded: true,
                getImagefilesFailed: false,
            });
        case actions.ActionTypes.GET_IMAGE_FILES_FAIL:
            return Object.assign({}, state, {
                getImagefilesLoading: false,
                getImagefilesLoaded: false,
                getImagefilesFailed: true,
            });

            
             // Delete Assignment
         case actions.ActionTypes.DELETE_ASSIGNMENT:
             return Object.assign({}, state, {
                 deleteAssignmentLoading: true,
                 deleteAssignmentLoaded: false,
                 deleteAssignmentFailed: false,
             });
         case actions.ActionTypes.DELETE_ASSIGNMENT_SUCCESS:
             console.log('payload',payload);
             return Object.assign({}, state, {
                 deleteAssignment: payload,
                deleteAssignmentLoading: false,
                deleteAssignmentLoaded: true,
                deleteAssignmentFailed: false,
             });
         case actions.ActionTypes.DELETE_ASSIGNMENT_FAIL:
             return Object.assign({}, state, {
                 deleteAssignmentLoading: false,
                 deleteAssignmentLoaded: false,
                 deleteAssignmentFailed: true,
             });


        case actions.ActionTypes.GET_ASSIGNMENT_LIST:
        return Object.assign({}, state, {
            getAssignmentListLoading: true,
            getAssignmentListLoaded: false,
            getAssignmentListFailed: false,
        });
        case actions.ActionTypes.GET_ASSIGNMENT_LIST_SUCCESS:
            console.log('GET_ASSIGNMENT_LIST_SUCCESS',payload)
        return Object.assign({}, state, {
            getAssignmentList: payload.data,
            getAssignmentListLoading: false,
            getAssignmentListLoaded: true,
            getAssignmentListFailed: false,
        });
        case actions.ActionTypes.GET_ASSIGNMENT_LIST_FAIL:
        return Object.assign({}, state, {
            getAssignmentListLoading: false,
            getAssignmentListLoaded: false,
            getAssignmentListFailed: true,
        });

      default: {
        return state;
      }
    }
  }
  
  /**
   * export values (files list)
   */
  export const getFilesReportList = (state: AssignmentListState) => state.getFilesReportList;
  export const getFilesReportListLoading = (state: AssignmentListState) => state.getFilesReportListLoading;
  export const getFilesReportListLoaded = (state: AssignmentListState) => state.getFilesReportListLoaded;
  export const getFilesReportListFailed = (state: AssignmentListState) => state.getFilesReportListFailed;
  
  /**
   * export values (files sent details)
   */
  export const getFilesReportDetails = (state: AssignmentListState) => state.getFilesReportDetails;
  export const tempDetailsCount = (state: AssignmentListState) => state.tempDetailsCount;  
  export const getFilesReportDetailsLoading = (state: AssignmentListState) => state.getFilesReportDetailsLoading;
  export const getFilesReportDetailsLoaded = (state: AssignmentListState) => state.getFilesReportDetailsLoaded;
  export const getFilesReportDetailsFailed = (state: AssignmentListState) => state.getFilesReportDetailsFailed;
  
  /**
   * export values (files sent group list)
   */
  export const fileSentGroupList = (state: AssignmentListState) => state.fileSentGroupList;
  export const fileSentGroupListLoading = (state: AssignmentListState) => state.fileSentGroupListLoading;
  export const fileSentGroupListLoaded = (state: AssignmentListState) => state.fileSentGroupListLoaded;
  export const fileSentGroupListFailed = (state: AssignmentListState) => state.fileSentGroupListFailed;
  /**
   * export file list
   */
  export const exportFileList = (state: AssignmentListState) => state.exportFileList;
  export const exportFileListLoading = (state: AssignmentListState) => state.exportFileListLoading;
  export const exportFileListLoaded = (state: AssignmentListState) => state.exportFileListLoaded;
  export const exportFileListFailed = (state: AssignmentListState) => state.exportFileListFailed;
  /**
   * member export file list
   */
  export const memberExportList = (state: AssignmentListState) => state.memberExportList;
  export const memberExportListLoading = (state: AssignmentListState) => state.memberExportListLoading;
  export const memberExportListLoaded = (state: AssignmentListState) => state.memberExportListLoaded;
  export const memberExportListFailed = (state: AssignmentListState) => state.memberExportListFailed;
  /**
   * member export file list
   */
  export const reportCount = (state: AssignmentListState) => state.reportCount;
  export const reportCountLoading = (state: AssignmentListState) => state.reportCountLoading;
  export const reportCountLoaded = (state: AssignmentListState) => state.reportCountLoaded;
  export const reportCountFailed = (state: AssignmentListState) => state.reportCountFailed;
  /**
   *Get image list
   */
  export const getImagefiles = (state: AssignmentListState) => state.getImagefiles;
  export const getImagefilesLoading = (state: AssignmentListState) => state.getImagefilesLoading;
  export const getImagefilesLoaded = (state: AssignmentListState) => state.getImagefilesLoaded;
  export const getImagefilesFailed = (state: AssignmentListState) => state.getImagefilesFailed;

  //deleteAssignment

export const deleteAssignment = (state: AssignmentListState) => state.deleteAssignment;
export const deleteAssignmentLoading = (state: AssignmentListState) => state.deleteAssignmentLoading;
export const deleteAssignmentLoaded = (state: AssignmentListState) => state.deleteAssignmentLoaded;
export const deleteAssignmentFailed = (state: AssignmentListState) => state.deleteAssignmentFailed;

export const getAssignmentList = (state: AssignmentListState) => state.getAssignmentList;
export const getAssignmentListLoading = (state: AssignmentListState) => state.getAssignmentListLoading;
export const getAssignmentListLoaded = (state: AssignmentListState) => state.getAssignmentListLoaded;
export const getAssignmentListFailed = (state: AssignmentListState) => state.getAssignmentListFailed;