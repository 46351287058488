import { Action } from '@ngrx/store';
import { type } from '../../../shared/utility';
import {ResponseInterface} from '../../../shared/interfaces/interface';

export const ActionTypes = {
  LOGIN: type('[LOGIN] LOGIN'),
  LOGIN_SUCCESS: type('[LOGIN] LOGIN Success'),
  LOGIN_FAIL: type('[LOGIN] LOGIN Fail'),
  // // renewal details
  // RENEWAL_DETAILS_LIST: type('[DASHBOARD] RENEWAL_DETAILS_LIST'),
  // RENEWAL_DETAILS_LIST_SUCCESS: type('[DASHBOARD] RENEWAL_DETAILS_LIST Success'),
  // RENEWAL_DETAILS_LIST_FAIL: type('[DASHBOARD] RENEWAL_DETAILS_LIST Fail'),
};

/**
 * login
 */
export class Login implements Action {
  type = ActionTypes.LOGIN;
  constructor(public payload: any) {}
}

export class LoginSuccess implements Action {
  type = ActionTypes.LOGIN_SUCCESS;
  constructor(public payload: ResponseInterface) {
  }
}
export class LoginFail implements Action {
  type = ActionTypes.LOGIN_FAIL;
  constructor(public payload: any) {}
}
// renewal details
// export class RenewalDetails implements Action {
//   type = ActionTypes.RENEWAL_DETAILS_LIST;
//   constructor(public payload: any) {}
// }

// export class RenewalDetailsSuccess implements Action {
//   type = ActionTypes.RENEWAL_DETAILS_LIST_SUCCESS;
//   constructor(public payload: ResponseInterface) {
//   }
// }
// export class RenewalDetailsFail implements Action {
//   type = ActionTypes.RENEWAL_DETAILS_LIST_FAIL;
//   constructor(public payload: any) {}
// }
export type Actions =
  | Login
  | LoginSuccess
  | LoginFail;
