export class PDFFileListResponseModel {
    public Date: any;
    public count: any;
    public createdBy: any;
    public downloadUrl: any;
    public id: any;
    public question: any;
    public sendTo: any;
    public targetId: any;


    constructor(smsList: any) {
     this.Date = smsList.Date || '';
     this.count = smsList.count || 0;
     this.createdBy = smsList.createdBy || '';
     this.downloadUrl = smsList.downloadUrl || '';
     this.id = smsList.id || '';
     this.question = smsList.question || '';
     this.sendTo = smsList.sendTo || '';
     this.targetId = smsList.targetId || '';
    }
}
