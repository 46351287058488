import {Map, Record} from 'immutable';

export interface AuthState extends Map<string, any> {

    loginLoading: boolean;
    loginLoaded: boolean;
    loginFailed: boolean;

    recoverLoading: boolean;
    recoverLoaded: boolean;
    recoverFailed: boolean;
}

export const authStateRecord = Record({

    loginLoading: false,
    loginLoaded: false,
    loginFailed: false,

    recoverLoading: false,
    recoverLoaded: false,
    recoverFailed: false
});

