import { Component, OnInit, AfterViewInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder, FormControl } from '@angular/forms';
import { CommonSandbox } from '../../common/common.sandbox';
import { matchingPasswords } from '../../shared/utility/app-validators';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {
  changePasswordForm: FormGroup;
  constructor(public fb: FormBuilder, public commonSandbox: CommonSandbox) {}

  ngOnInit() {
    this.initChangePasswordForm();
  }
  public initChangePasswordForm(): void {
    this.changePasswordForm = this.fb.group({
      oldPassword: ['',  Validators.required],
      newPassword: ['', Validators.required],
      confirmPassword: ['', Validators.required],
    },
      { validator: matchingPasswords('newPassword', 'confirmPassword') }
    );
  }
  public dochangePassword() {
    if (!this.changePasswordForm.valid) {
      console.log('this.loginForm', this.changePasswordForm);
      this.validateAllFormFields(this.changePasswordForm);
      return;
    }
    this.commonSandbox.changePassword(this.changePasswordForm.value);
  }
  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({onlySelf: true});
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });

  }
}
