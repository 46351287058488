export class LeaveRequestListRequestModel {
    limit: Number;
    offset: Number;
    keyword: string;
    count: Number;
    instituteClassId: Number;
    instituteSectionId: Number;
    constructor(value: any) {
        this.limit = value.limit || 0;
        this.offset = value.offset || 0;
        this.keyword = value.keyword || '';
        this.count = value.count || 0;
        this.instituteClassId = value.standardId || '';
        this.instituteSectionId = value.sectionId || '';
    }
}
