import { Action } from '@ngrx/store';
import { type } from '../../../../shared/utility';
import { ResponseInterface } from '../../../../shared/interfaces/interface';

export const ActionTypes = {
    GET_FILES_REPORT_LIST: type('[assignment-report] get assignment report list'),
    GET_FILES_REPORT_LIST_SUCCESS: type('[assignment-report] get assignment report list Success'),
    GET_FILES_REPORT_LIST_FAIL: type('[assignment-report] get assignment report list Fail'),

    GET_FILES_REPORT_DETAILS: type('[assignment-report] get assignment report details'),
    GET_FILES_REPORT_DETAILS_SUCCESS: type('[assignment-report] get assignment report details Success'),
    GET_FILES_REPORT_DETAILS_FAIL: type('[assignment-report] get assignment report details Fail'),

    FILE_SENT_GROUP_LIST: type('[assignment-report] assignment sent group list'),
    FILE_SENT_GROUP_LIST_SUCCESS: type('[assignment-report] assignment sent group list Success'),
    FILE_SENT_GROUP_LIST_FAIL: type('[assignment-report] assignment sent group list Fail'),

    EXPORT_FILE_LIST: type('[assignment-report] EXPORT_FILE list'),
    EXPORT_FILE_LIST_SUCCESS: type('[assignment-report] EXPORT_FILE list Success'),
    EXPORT_FILE_LIST_FAIL: type('[assignment-report] EXPORT_FILE list Fail'),

    MEMBER_EXPORT: type('[assignment-report] MEMBER EXPORT list'),
    MEMBER_EXPORT_SUCCESS: type('[assignment-report] MEMBER EXPORT list Success'),
    MEMBER_EXPORT_FAIL: type('[assignment-report] MEMBER EXPORT list Fail'),

    SEARCH_MEMBER_LIST: type('[assignment-report] SEARCH_MEMBER_LIST'),

    GET_FILES_REPORT_COUNT_LIST: type('[assignment-report] get assignment report count list'),
    GET_FILES_REPORT_COUNT_LIST_SUCCESS: type('[assignment-report] get assignment report count list Success'),
    GET_FILES_REPORT_COUNT_LIST_FAIL: type('[assignment-report] get assignment report count list Fail'),

    GET_IMAGE_FILES: type('[assignment-imagefiles] get assignment imagefiles'),
    GET_IMAGE_FILES_SUCCESS: type('[assignment-imagefiles] get aassignment imagefiles Success'),
    GET_IMAGE_FILES_FAIL: type('[assignment-imagefiles] get assignmentimagefiles Fail'),

//DELETE_ASSIGNMENT
DELETE_ASSIGNMENT: type('[delete-assignment] delete assignment'),
DELETE_ASSIGNMENT_SUCCESS: type('[delete-assignment] delete assignment Success'),
DELETE_ASSIGNMENT_FAIL: type('[delete-assignment] delete assignment Fail'),

//ASSIGNMENTlIST
GET_ASSIGNMENT_LIST: type('[assignment-list] get GET_ASSIGNMENT_LIST'),
GET_ASSIGNMENT_LIST_SUCCESS: type('[assignment-list] get GET_ASSIGNMENT_LIST_SUCCESS'),
GET_ASSIGNMENT_LIST_FAIL: type('[assignment-list] get GET_ASSIGNMENT_LIST_FAIL'),
};

// voice report List
export class getAssignmentList implements Action {
    type = ActionTypes.GET_ASSIGNMENT_LIST;
    constructor(public payload: any) {
    }
}
export class getAssignmentListSuccess implements Action {
    type = ActionTypes.GET_ASSIGNMENT_LIST_SUCCESS;
    constructor(public payload: ResponseInterface) {
    }
}
export class getAssignmentListFail implements Action {
    type = ActionTypes.GET_ASSIGNMENT_LIST_FAIL;
    constructor(public payload: any) {
    }
}

// get files report list
export class GetFilesReportListAction implements Action {
    type = ActionTypes.GET_FILES_REPORT_LIST;
    constructor(public payload: any) {
    }
}
export class GetFilesReportListSuccess implements Action {
    type = ActionTypes.GET_FILES_REPORT_LIST_SUCCESS;
    constructor(public payload: ResponseInterface) {
    }
}
export class GetFilesReportListFail implements Action {
    type = ActionTypes.GET_FILES_REPORT_LIST_FAIL;
    constructor(public payload: any) {
    }
}
// get files report count
export class GetFilesReportCountAction implements Action {
    type = ActionTypes.GET_FILES_REPORT_COUNT_LIST;
    constructor(public payload: any) {
    }
}
export class GetFilesReportCountSuccess implements Action {
    type = ActionTypes.GET_FILES_REPORT_COUNT_LIST_SUCCESS;
    constructor(public payload: ResponseInterface) {
    }
}
export class GetFilesReportCountFail implements Action {
    type = ActionTypes.GET_FILES_REPORT_COUNT_LIST_FAIL;
    constructor(public payload: any) {
    }
}
// get member export
export class MemberExportAction implements Action {
    type = ActionTypes.MEMBER_EXPORT;
    constructor(public payload: any) {
    }
}
export class MemberExportSuccess implements Action {
    type = ActionTypes.MEMBER_EXPORT_SUCCESS;
    constructor(public payload: ResponseInterface) {
    }
}
export class MemberExportFail implements Action {
    type = ActionTypes.MEMBER_EXPORT_FAIL;
    constructor(public payload: any) {
    }
}

// export file list
export class ExportFileAction implements Action {
    type = ActionTypes.EXPORT_FILE_LIST;
    constructor(public payload: any) {
    }
}
export class ExportFileSuccess implements Action {
    type = ActionTypes.EXPORT_FILE_LIST_SUCCESS;
    constructor(public payload: ResponseInterface) {
    }
}
export class ExportFileFail implements Action {
    type = ActionTypes.EXPORT_FILE_LIST_FAIL;
    constructor(public payload: any) {
    }
}

// get files report details
export class GetFilesReportDetailsAction implements Action {
    type = ActionTypes.GET_FILES_REPORT_DETAILS;
    constructor(public payload: any) {
    }
}
export class GetFilesReportDetailsSuccess implements Action {
    type = ActionTypes.GET_FILES_REPORT_DETAILS_SUCCESS;
    constructor(public payload: ResponseInterface) {
    }
}
export class GetFilesReportDetailsFail implements Action {
    type = ActionTypes.GET_FILES_REPORT_DETAILS_FAIL;
    constructor(public payload: any) {
    }
}

// file sent group list
export class FileSentGroupListAction implements Action {
    type = ActionTypes.FILE_SENT_GROUP_LIST;
    constructor(public payload: any) {
    }
}
export class FileSentGroupLisSuccess implements Action {
    type = ActionTypes.FILE_SENT_GROUP_LIST_SUCCESS;
    constructor(public payload: ResponseInterface) {
    }
}
export class FileSentGroupLisFail implements Action {
    type = ActionTypes.FILE_SENT_GROUP_LIST_FAIL;
    constructor(public payload: any) {
    }
}
// search member list
export class SearchMemberListAction implements Action {
    type = ActionTypes.SEARCH_MEMBER_LIST;
    constructor(public payload: any) {
    }
}
// get imagefiles 
export class GetImageFilesAction implements Action {
    type = ActionTypes.GET_IMAGE_FILES;
    constructor(public payload: any) {
    }
}
export class GetImageFilesActionSuccess implements Action {
    type = ActionTypes.GET_IMAGE_FILES_SUCCESS;
    constructor(public payload: ResponseInterface) {
    }
}
export class GetImageFilesActionFail implements Action {
    type = ActionTypes.GET_IMAGE_FILES_FAIL;
    constructor(public payload: any) {
    }
}

//DELETE_ASSIGNMENT
export class DeleteAssignmentAction implements Action {
    type = ActionTypes.DELETE_ASSIGNMENT;
    constructor(public payload: any) {
    }
}
export class DeleteAssignmentActionSuccess implements Action {
    type = ActionTypes.DELETE_ASSIGNMENT_SUCCESS;
    constructor(public payload: ResponseInterface) {
    }
}
export class DeleteAssignmentActionFail implements Action {
    type = ActionTypes.DELETE_ASSIGNMENT_FAIL;
    constructor(public payload: any) {
    }
}


export type Actions =
    | GetFilesReportListAction
    | GetFilesReportListSuccess
    | GetFilesReportListFail
    | GetFilesReportDetailsAction
    | GetFilesReportDetailsSuccess
    | GetFilesReportDetailsFail
    | FileSentGroupListAction
    | FileSentGroupLisSuccess
    | FileSentGroupLisFail
    | ExportFileAction
    | ExportFileSuccess
    | ExportFileFail
    | MemberExportAction
    | MemberExportSuccess
    | MemberExportFail
    | GetFilesReportCountAction
    | GetFilesReportCountSuccess
    | GetFilesReportCountFail
    | GetImageFilesAction
    | GetImageFilesActionSuccess
    | GetImageFilesActionFail
    |DeleteAssignmentAction
    | DeleteAssignmentActionSuccess
    | DeleteAssignmentActionFail
    | getAssignmentList
    | getAssignmentListSuccess
    | getAssignmentListFail;
   
