import { Action } from '@ngrx/store';
import {type} from '../../shared/utility';

export const ActionTypes = {
    DO_AUTO_LOGIN: type('[Common] do Auto login'),
    DO_AUTO_LOGIN_SUCCESS: type('[Common] login Auto Success'),
    DO_AUTO_LOGIN_FAIL: type('[Common] login Auto Fail'),
};

/**
 * auto login actions.
 */
export class DoAutoLoginAction implements Action {
    type = ActionTypes.DO_AUTO_LOGIN;

    constructor(public payload: any) {
    }
}

export class DoAutoLoginSuccess implements Action {
    type = ActionTypes.DO_AUTO_LOGIN_SUCCESS;

    constructor(public payload: any) {
    }
}

export class DoAutoLoginFail implements Action {
    type = ActionTypes.DO_AUTO_LOGIN_FAIL;
    constructor(public payload: any) {
    }
}


export type Actions =
    | DoAutoLoginAction
    | DoAutoLoginSuccess
    | DoAutoLoginFail;

