import { Action } from '@ngrx/store';
import { type } from '../../../../shared/utility';
import { ResponseInterface } from '../../../../shared/interfaces/interface';

export const ActionTypes = {
    GET_FILES_REPORT_LIST: type('[files-report] get files report list'),
    GET_FILES_REPORT_LIST_SUCCESS: type('[files-report] get files report list Success'),
    GET_FILES_REPORT_LIST_FAIL: type('[files-report] get files report list Fail'),

    GET_FILES_REPORT_DETAILS: type('[files-report] get files report details'),
    GET_FILES_REPORT_DETAILS_SUCCESS: type('[files-report] get files report details Success'),
    GET_FILES_REPORT_DETAILS_FAIL: type('[files-report] get files report details Fail'),

    FILE_SENT_GROUP_LIST: type('[files-report] file sent group list'),
    FILE_SENT_GROUP_LIST_SUCCESS: type('[files-report] file sent group list Success'),
    FILE_SENT_GROUP_LIST_FAIL: type('[files-report] file sent group list Fail'),

    EXPORT_FILE_LIST: type('[files-report] EXPORT_FILE list'),
    EXPORT_FILE_LIST_SUCCESS: type('[files-report] EXPORT_FILE list Success'),
    EXPORT_FILE_LIST_FAIL: type('[files-report] EXPORT_FILE list Fail'),

    MEMBER_EXPORT: type('[files-report] MEMBER EXPORT list'),
    MEMBER_EXPORT_SUCCESS: type('[files-report] MEMBER EXPORT list Success'),
    MEMBER_EXPORT_FAIL: type('[files-report] MEMBER EXPORT list Fail'),

    SEARCH_MEMBER_LIST: type('[files-report] SEARCH_MEMBER_LIST'),

    GET_FILES_REPORT_COUNT_LIST: type('[files-report] get files report count list'),
    GET_FILES_REPORT_COUNT_LIST_SUCCESS: type('[files-report] get files report count list Success'),
    GET_FILES_REPORT_COUNT_LIST_FAIL: type('[files-report] get files report count list Fail'),


};


// get files report list
export class GetFilesReportListAction implements Action {
    type = ActionTypes.GET_FILES_REPORT_LIST;
    constructor(public payload: any) {
    }
}
export class GetFilesReportListSuccess implements Action {
    type = ActionTypes.GET_FILES_REPORT_LIST_SUCCESS;
    constructor(public payload: ResponseInterface) {
    }
}
export class GetFilesReportListFail implements Action {
    type = ActionTypes.GET_FILES_REPORT_LIST_FAIL;
    constructor(public payload: any) {
    }
}
// get files report count
export class GetFilesReportCountAction implements Action {
    type = ActionTypes.GET_FILES_REPORT_COUNT_LIST;
    constructor(public payload: any) {
    }
}
export class GetFilesReportCountSuccess implements Action {
    type = ActionTypes.GET_FILES_REPORT_COUNT_LIST_SUCCESS;
    constructor(public payload: ResponseInterface) {
    }
}
export class GetFilesReportCountFail implements Action {
    type = ActionTypes.GET_FILES_REPORT_COUNT_LIST_FAIL;
    constructor(public payload: any) {
    }
}
// get member export
export class MemberExportAction implements Action {
    type = ActionTypes.MEMBER_EXPORT;
    constructor(public payload: any) {
    }
}
export class MemberExportSuccess implements Action {
    type = ActionTypes.MEMBER_EXPORT_SUCCESS;
    constructor(public payload: ResponseInterface) {
    }
}
export class MemberExportFail implements Action {
    type = ActionTypes.MEMBER_EXPORT_FAIL;
    constructor(public payload: any) {
    }
}

// export file list
export class ExportFileAction implements Action {
    type = ActionTypes.EXPORT_FILE_LIST;
    constructor(public payload: any) {
    }
}
export class ExportFileSuccess implements Action {
    type = ActionTypes.EXPORT_FILE_LIST_SUCCESS;
    constructor(public payload: ResponseInterface) {
    }
}
export class ExportFileFail implements Action {
    type = ActionTypes.EXPORT_FILE_LIST_FAIL;
    constructor(public payload: any) {
    }
}

// get files report details
export class GetFilesReportDetailsAction implements Action {
    type = ActionTypes.GET_FILES_REPORT_DETAILS;
    constructor(public payload: any) {
    }
}
export class GetFilesReportDetailsSuccess implements Action {
    type = ActionTypes.GET_FILES_REPORT_DETAILS_SUCCESS;
    constructor(public payload: ResponseInterface) {
    }
}
export class GetFilesReportDetailsFail implements Action {
    type = ActionTypes.GET_FILES_REPORT_DETAILS_FAIL;
    constructor(public payload: any) {
    }
}

// file sent group list
export class FileSentGroupListAction implements Action {
    type = ActionTypes.FILE_SENT_GROUP_LIST;
    constructor(public payload: any) {
    }
}
export class FileSentGroupLisSuccess implements Action {
    type = ActionTypes.FILE_SENT_GROUP_LIST_SUCCESS;
    constructor(public payload: ResponseInterface) {
    }
}
export class FileSentGroupLisFail implements Action {
    type = ActionTypes.FILE_SENT_GROUP_LIST_FAIL;
    constructor(public payload: any) {
    }
}
// search member list
export class SearchMemberListAction implements Action {
    type = ActionTypes.SEARCH_MEMBER_LIST;
    constructor(public payload: any) {
    }
}



export type Actions =
    | GetFilesReportListAction
    | GetFilesReportListSuccess
    | GetFilesReportListFail
    | GetFilesReportDetailsAction
    | GetFilesReportDetailsSuccess
    | GetFilesReportDetailsFail
    | FileSentGroupListAction
    | FileSentGroupLisSuccess
    | FileSentGroupLisFail
    | ExportFileAction
    | ExportFileSuccess
    | ExportFileFail
    | MemberExportAction
    | MemberExportSuccess
    | MemberExportFail
    | GetFilesReportCountAction
    | GetFilesReportCountSuccess
    | GetFilesReportCountFail;

