import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import * as studentManagementActions from '../actions/student-management.action';
import { Store } from '@ngrx/store';

import * as actions from '../actions/student-management.action';
import { StudentManagementService } from '../student-management.service';
import * as store from '../../../app.state';
import { saveAs } from 'file-saver';
import printJS from 'print-js';


@Injectable()
export class StudentManagementEffect {
    studentdata: any;
  constructor(
    private action$: Actions,
    protected api: StudentManagementService,
    public router: Router,
    protected appState: Store<store.AppState>
  ) {}

  // add student
  @Effect()
  addStudent$: Observable<Action> = this.action$.pipe(
    ofType(actions.ActionTypes.ADD_STUDENT),
    map((action: actions.AddStudentAction) => action.payload),
    switchMap(state => {
      const redirect = state.redirect;
      delete state.redirect;
      return this.api.addStudent(state).pipe(
        tap((success: any) => {
          if (success && success.status === 1) {
            if (redirect === 'student') {
              this.router.navigate(['student-management/student-list']);
            } else if (redirect === 'admit') {
              this.router.navigate(['/admission/main-admission/students']);
            }
          }
        }),
        map(user => new actions.AddStudentSuccess(user)),
        catchError(error => of(new actions.AddStudentFail(error.error)))
      );
    })
  );
  // add student
  @Effect()
  editStudent$: Observable<Action> = this.action$.pipe(
    ofType(actions.ActionTypes.EDIT_STUDENT),
    map((action: actions.EditStudentAction) => action.payload),
    switchMap(state => {
      return this.api.editStudent(state).pipe(
        tap((success: any) => {
          if (success && success.status === 1) {
            if (success.redirect === 0) {
              this.router.navigate(['student-management/student-list']);
            } else {
              this.router.navigate(['student-management/invalid-student']);
            }
          }
        }),
        map(user => new actions.EditStudentSuccess(user)),
        catchError(error => of(new actions.EditStudentFail(error.error)))
      );
    })
  );

  // student list
  @Effect()
  studentList$: Observable<Action> = this.action$.pipe(
    ofType(actions.ActionTypes.STUDENT_LIST),
    map((action: actions.StudentListAction) => action.payload),
    switchMap(state => {
      return this.api.studentList(state).pipe(
        map(user => new actions.StudentListSuccess(user)),
        catchError(error => of(new actions.StudentListFail(error.error)))
      );
    })
  );

  // leaverequest list
  @Effect()
  LeaveRequestList$: Observable<Action> = this.action$.pipe(
    ofType(actions.ActionTypes.LEAVEREQUEST_LIST),
    map((action: actions.LeaveRequestListAction) => action.payload),
    switchMap(state => {
      return this.api.leaveRequestList(state).pipe(
        tap(data => {
          console.log('leave', data.data);
          
      }),
        map(user => new actions.LeaveRequestListSuccess(user)),
        catchError(error => of(new actions.LeaveRequestListFail(error.error)))
      );
    })
  );


// get leaverequestlist count
@Effect()
getLeaveRequestListCount$: Observable<Action> = this.action$.pipe(
  ofType(actions.ActionTypes.GET_LEAVEREQUESTLIST_COUNT),
  map((action: actions.GetLeaveRequestListCountAction) => action.payload),
  switchMap(state => 
    {
      let users;
    return this.api.getLeaverequestlistCount(state).pipe(
      tap(count => {
        console.log('effectsleavecount',count);
        users = count.count;
        console.log('effectsleaveusers',users);
        
    }),
      map(users => new actions.GetLeaveRequestListCountSuccess(users)),
      catchError(error => of(new actions.GetLeaveRequestListCountFail(error.error)))
    );
  })
);

  // students standard (class they are studying) list
  @Effect()
  standardList$: Observable<Action> = this.action$.pipe(
    ofType(actions.ActionTypes.STANDARD_LIST),
    map((action: actions.StandardListAction) => action.payload),
    switchMap(state => {
      return this.api.standardList().pipe(
        map(user => new actions.StandardListSuccess(user)),
        catchError(error => of(new actions.StandardListFail(error.error)))
      );
    })
  );

  // students sections list
  @Effect()
  sectionList$: Observable<Action> = this.action$.pipe(
    ofType(actions.ActionTypes.SECTION_LIST),
    map((action: actions.SectionListAction) => action.payload),
    switchMap(state => {
      return this.api.sectionList(state).pipe(
        map(user => new actions.SectionListSuccess(user)),
        catchError(error => of(new actions.SectionListFail(error.error)))
      );
    })
  );

  // students sections list
  @Effect()
  sectionSiblingList$: Observable<Action> = this.action$.pipe(
    ofType(actions.ActionTypes.SECTION_LIST_SIBLING),
    map((action: actions.SectionListSiblingAction) => action.payload),
    switchMap(state => {
      return this.api.sectionList(state).pipe(
        map(user => new actions.SectionListSiblingSuccess(user)),
        catchError(error => of(new actions.SectionListSiblingFail(error.error)))
      );
    })
  );

  // medium of student(In which language they are studying e.g tamil, english..)
  @Effect()
  mediumOfStudent: Observable<Action> = this.action$.pipe(
    ofType(actions.ActionTypes.MEDIUM_OF_STUDENT),
    map((action: actions.MediumOfStudentAction) => action.payload),
    switchMap(state => {
      return this.api.mediumOfStudent().pipe(
        map(user => new actions.MediumOfStudentSuccess(user)),
        catchError(error => of(new actions.MediumOfStudentFail(error.error)))
      );
    })
  );

  // get religion list
  @Effect()
  religionList: Observable<Action> = this.action$.pipe(
    ofType(actions.ActionTypes.GET_RELIGION_LIST),
    map((action: actions.ReligionListAction) => action.payload),
    switchMap(state => {
      return this.api.religionList().pipe(
        map(user => new actions.ReligionListSuccess(user)),
        catchError(error => of(new actions.ReligionListFail(error.error)))
      );
    })
  );

  // get community list
  @Effect()
  communityList: Observable<Action> = this.action$.pipe(
    ofType(actions.ActionTypes.GET_COMMUNITY_LIST),
    map((action: actions.CommunityListAction) => action.payload),
    switchMap(state => {
      return this.api.communityList().pipe(
        map(user => new actions.CommunityListSuccess(user)),
        catchError(error => of(new actions.CommunityListFail(error.error)))
      );
    })
  );

  // get blood group list
  @Effect()
  bloodGroupList: Observable<Action> = this.action$.pipe(
    ofType(actions.ActionTypes.BLOOD_GROUP_LIST),
    map((action: actions.BloodGroupListAction) => action.payload),
    switchMap(state => {
      return this.api.bloodGroupList().pipe(
        map(user => new actions.BloodGroupListSuccess(user)),
        catchError(error => of(new actions.BloodGroupListFail(error.error)))
      );
    })
  );

  // get fees payment type
  @Effect()
  paymentType: Observable<Action> = this.action$.pipe(
    ofType(actions.ActionTypes.FEES_PAYMENT_TYPE),
    map((action: actions.FeesPaymentTypeAction) => action.payload),
    switchMap(state => {
      return this.api.feesPaymentType().pipe(
        map(user => new actions.FeesPaymentTypeSuccess(user)),
        catchError(error => of(new actions.FeesPaymentTypeFail(error.error)))
      );
    })
  );

  // get student details
  @Effect()
  getStudentDetails: Observable<Action> = this.action$.pipe(
    ofType(actions.ActionTypes.GET_STUDENT_DETAILS),
    map((action: actions.GetStudentDetailsAction) => action.payload),
    switchMap(state => {
      tap(val => {
        if (val['data'] && val['data'].classId) {
          this.appState.dispatch(
            new studentManagementActions.GetStudentDetailsAction({
              standardId: val['data'].classId
            })
          );
        }
      });
      return this.api.getStudentDetails(state).pipe(
        map(user => new actions.GetStudentDetailsSuccess(user)),
        catchError(error => of(new actions.GetStudentDetailsFail(error.error)))
      );
    })
  );

  // get sibling details
  @Effect()
  getSiblingDetails: Observable<Action> = this.action$.pipe(
    ofType(actions.ActionTypes.GET_SIBLING_DETAIL),
    map((action: actions.GetSiblingDetail) => action.payload),
    switchMap(state => {
      return this.api.getStudentDetails(state).pipe(
        map(user => new actions.GetSiblingDetailSuccess(user)),
        catchError(error => of(new actions.GetSiblingDetailFail(error.error)))
      );
    })
  );

  // get student count
  @Effect()
  getStudentCount: Observable<Action> = this.action$.pipe(
    ofType(actions.ActionTypes.GET_STUDENT_COUNT),
    map((action: actions.GetStudentCountAction) => action.payload),
    switchMap(state => {
      return this.api.getStudentCount(state).pipe(
        map(user => new actions.GetStudentCountSuccess(user)),
        catchError(error => of(new actions.GetStudentCountFail(error.error)))
      );
    })
  );

  // delete student
  @Effect()
  deleteStudent: Observable<Action> = this.action$.pipe(
    ofType(actions.ActionTypes.DELETE_STUDENT),
    map((action: actions.DeleteStudentAction) => action.payload),
    switchMap(state => {
      return this.api.deleteStudent(state).pipe(
        map(user => new actions.DeleteStudentSuccess(user)),
        catchError(error => of(new actions.DeleteStudentFail(error.error)))
      );
    })
  );

  // upload documents
  @Effect()
  uploadDocument: Observable<Action> = this.action$.pipe(
    ofType(actions.ActionTypes.UPLOAD_DOCUMENT),
    map((action: actions.UploadDocumentAction) => action.payload),
    switchMap(state => {
      return this.api.uploadDocument(state).pipe(
        map(user => new actions.UploadDocumentSuccess(user)),
        catchError(error => of(new actions.UploadDocumentFail(error.error)))
      );
    })
  );
  
  // deleted Student list
  @Effect()
  deletedStudentList: Observable<Action> = this.action$.pipe(
    ofType(actions.ActionTypes.DELETED_STUDENT_LIST),
    map((action: actions.DeletedStudentListAction) => action.payload),
    switchMap(state => {
      return this.api.deletedStudentList(state).pipe(
        map(user => new actions.DeletedStudentListSuccess(user)),
        catchError(error => of(new actions.DeletedStudentListFail(error.error)))
      );
    })
  );

  // revert student
  @Effect()
  revertStudent: Observable<Action> = this.action$.pipe(
    ofType(actions.ActionTypes.REVERT_STUDENT),
    map((action: actions.RevertStudentAction) => action.payload),
    switchMap(state => {
      return this.api.revertStudent(state).pipe(
        map(user => new actions.RevertStudentSuccess(user)),
        catchError(error => of(new actions.RevertStudentFail(error.error)))
      );
    })
  );

  // get route master list
  @Effect()
  routeMasterList: Observable<Action> = this.action$.pipe(
    ofType(actions.ActionTypes.GET_ROUTE_MASTER_LIST),
    map((action: actions.RouteMasterList) => action.payload),
    switchMap(state => {
      return this.api.routeList(state).pipe(
        map(user => new actions.RouteMasterListSuccess(user)),
        catchError(error => of(new actions.RouteMasterListFail(error.error)))
      );
    })
  );

  // get route master list
  @Effect()
  stopingPointList: Observable<Action> = this.action$.pipe(
    ofType(actions.ActionTypes.STOPING_POINT_LIST),
    map((action: actions.StopingPointList) => action.payload),
    switchMap(state => {
      return this.api.stopingPointList(state).pipe(
        map(user => new actions.StopingPointListSuccess(user)),
        catchError(error => of(new actions.StopingPointListFail(error.error)))
      );
    })
  );

  // get export student list
  @Effect()
  exportStudentList: Observable<Action> = this.action$.pipe(
    ofType(actions.ActionTypes.EXPORT_STUDENT_LIST),
    map((action: actions.ExportStudentAction) => action.payload),
    switchMap(state => {
        this.studentdata = state;
      return this.api.exportStudentList(state).pipe(
        tap(data => {
            // tslint:disable-next-line:max-line-length
            const instituteDetails = localStorage.getItem('instituteDetails') ? JSON.parse(localStorage.getItem('instituteDetails')) : false;
            let fileName = '';
            if (instituteDetails) {
              fileName +=  instituteDetails.institudeCode + '_';
            }
            if (this.studentdata['invalidStudent'] === '') {
              fileName += 'StudentsList.xlsx';
            }
            if (+this.studentdata['invalidStudent'] === 1) {
              fileName += 'InvalidStudentsList.xlsx';
            }
            if (+this.studentdata['isDelete'] === 1) {
              fileName += 'DeletedStudentsList.xlsx';
            }
            if (+this.studentdata['history'] === 1) {
              fileName = instituteDetails.institudeCode +'_StudentsHistory.xlsx';
            }
          const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
          saveAs(blob, fileName);
        }),
        map(user => new actions.ExportStudentSuccess(user)),
        catchError(error => of(new actions.ExportStudentFail(error.error)))
      );
    })
  );
  /**
   *  route master fee list effect
   */
  @Effect()
  routeFeeList$: Observable<Action> = this.action$.pipe(
    ofType(actions.ActionTypes.GET_ROUTE_FEE_LIST),
    map((action: actions.RouteMasterFeeList) => action.payload),
    switchMap(state => {
      return this.api.routeMasterFeeList(state).pipe(
        map(admission => new actions.RouteMasterFeeListSuccess(admission)),
        catchError(error => of(new actions.RouteMasterFeeListFail(error.error)))
      );
    })
  );
  @Effect()
  monthList$: Observable<Action> = this.action$.pipe(
    ofType(actions.ActionTypes.GET_MONTH_LIST),
    map((action: actions.MonthListAction) => action.payload),
    switchMap(state => {
      return this.api.monthList(state).pipe(
        tap(data => {
          console.log('studenteffect', data);

        }),
        map(user => new actions.MonthListActionSuccess(user)),
        catchError(error => of(new actions.MonthListActionFail(error.error)))
      );
    })
  );
  @Effect()
  PromoteStudentList$: Observable<Action> = this.action$.pipe(
    ofType(actions.ActionTypes.PROMOTE_STUDENT_LIST),
    map((action: actions.PromoteStudentAction) => action.payload),
    switchMap(state => {
      return this.api.promoteStudent(state).pipe(
        map(user => new actions.PromoteStudentSuccess(user)),
        catchError(error => of(new actions.PromoteStudentFail(error.error)))
      );
    })
  );

  @Effect()
  academicList$: Observable<Action> = this.action$.pipe(
    ofType(actions.ActionTypes.ACADEMIC_YEAR_LIST),
    map((action: actions.AcademicAction) => action.payload),
    switchMap(state => {
      return this.api.academicyear(state).pipe(
        map(user => new actions.AcademicSuccess(user)),
        catchError(error => of(new actions.AcademicFail(error.error)))
      );
    })
  );
  // promote student
  @Effect()
  promotestudent$: Observable<Action> = this.action$.pipe(
    ofType(actions.ActionTypes.CREATE_PROMOTE_STUDENT),
    map((action: actions.CreatePromoteStudentAction) => action.payload),
    switchMap(state => {
      return this.api.createPromoteStudent(state).pipe(
        map(user => new actions.CreatePromoteStudentSuccess(user)),
        catchError(error => of(new actions.CreatePromoteStudentFail(error.error)))
      );
    })
  );
  // student text report
  @Effect()
  studentTextDetails$: Observable<Action> = this.action$.pipe(
    ofType(actions.ActionTypes.STUDENT_MESSAGE_DETAILS),
    map((action: actions.StudentMessageAction) => action.payload),
    switchMap(state => {
      return this.api.studentTextReport(state).pipe(
        map(user => new actions.StudentMessageSuccess(user)),
        catchError(error => of(new actions.StudentMessageFail(error.error)))
      );
    })
  );
  // student file report
  @Effect()
  studentFileReport$: Observable<Action> = this.action$.pipe(
    ofType(actions.ActionTypes.STUDENT_MESSAGE_FILE),
    map((action: actions.StudentMessageFileAction) => action.payload),
    switchMap(state => {
      return this.api.studentMessageReport(state).pipe(
        map(user => new actions.StudentMessageFileSuccess(user)),
        catchError(error => of(new actions.StudentMessageFileFail(error.error)))
      );
    })
  );
  // invalid import sty
  @Effect()
  invalidStudentImport$: Observable<Action> = this.action$.pipe(
    ofType(actions.ActionTypes.IMPORT_INVALID_STUDENT),
    map((action: actions.ImportInvalidStudentAction) => action.payload),
    switchMap(state => {
      return this.api.importInvalidStudent(state).pipe(
          tap( data => {
             if (data) {
                 this.printPdfFile(data.data);
             }
          }),
        map(user => new actions.ImportInvalidStudentSuccess(user)),
        catchError(error => of(new actions.ImportInvalidStudentFail(error.error)))
      );
    })
  );

  @Effect()
  idcardGenerate$: Observable<Action> = this.action$.pipe(
    ofType(actions.ActionTypes.ID_CARD_GENERATE),
    map((action: actions.IdCardGenerateAction) => action.payload),
    switchMap(state => {
      return this.api.idCardGenerate(state).pipe(
        tap(data => {
          console.log('responseeffect', data.data);
          this.printIdCard(data.data);
      }),
        map(user => new actions.IdCardGenerateSuccess(user)),
        catchError(error => of(new actions.IdCardGenerateFail(error.error)))
      );
    })
  );
  @Effect()
  studentVoiceReport$: Observable<Action> = this.action$.pipe(
    ofType(actions.ActionTypes.STUDENT_VOICE_REPORT),
    map((action: actions.StudentVoiceReport) => action.payload),
    switchMap(state => {
      return this.api.studentVoiceReport(state).pipe(
        map(user => new actions.StudentVoiceReportSuccess(user)),
        catchError(error => of(new actions.StudentVoiceReportFail(error.error)))
      );
    })
  );

@Effect()
  subjectList$: Observable<Action> = this.action$.pipe(
    ofType(actions.ActionTypes.SUBJECT_LIST),
    map((action: actions.SubjectListAction) => action.payload),
    switchMap(state => {
      return this.api.subjectList(state).pipe(
        map(user => new actions.SubjectListActionSuccess(user)),
        catchError(error => of(new actions.SubjectListActionFail(error.error)))
      );
    })
  );

@Effect()
  GetExamNames$: Observable<Action> = this.action$.pipe(
      ofType(actions.ActionTypes.GET_EXAMNAMES),
      map((action: actions.GetExamNamesAction) => action.payload),
      switchMap(state => {
          return this.api.getexamListListApi().pipe(
              map(user => new actions.GetExamNamesSuccess(user)),
              catchError(error => of(new actions.GetExamNamesFail(error.error)))
          );
      })
  );

  @Effect()
  GetStdSecProgress$: Observable<Action> = this.action$.pipe(
      ofType(actions.ActionTypes.STDSEC_PROGRESS),
      map((action: actions.GetStdSecProgressAction) => action.payload),
      switchMap(state => {
          return this.api.getStdSecApi(state).pipe(
              map(user => new actions.GetStdSecProgressSuccess(user)),
              catchError(error => of(new actions.GetStdSecProgressFail(error.error)))
          );
      })
  );

  @Effect()
  StudentListProgress$: Observable<Action> = this.action$.pipe(
      ofType(actions.ActionTypes.STUDENT_LIST_PROGRESS),
      map((action: actions.StudentListforProgressAction) => action.payload),
      switchMap(state => {
          return this.api.getStudentsforprogressApi(state).pipe(
              map(user => new actions.StudentListforProgressActionSuccess(user)),
              catchError(error => of(new actions.StudentListforProgressActionFail(error.error)))
          );
      })
  );

  @Effect()
  examResultAnalysis$: Observable<Action> = this.action$.pipe(
      ofType(actions.ActionTypes.EXAM_RESULT_ANALYSIS),
      map((action: actions.getResultAnalysisAction) => action.payload),
      switchMap(state => {
          return this.api.getResultAnalysisApi(state).pipe(
              map(user => new actions.getResultAnalysisSuccess(user)),
              catchError(error => of(new actions.getResultAnalysisFail(error.error)))
          );
      })
  );

  @Effect()
  progressCard$: Observable<Action> = this.action$.pipe(
      ofType(actions.ActionTypes.PROGRESS_CARD),
      map((action: actions.progressCardAction) => action.payload),
      switchMap(state => {
          return this.api.getProgressCardApi(state).pipe(
              map(user => new actions.progressCardSuccess(user)),
              catchError(error => of(new actions.progressCardFail(error.error)))
          );
      })
  );
  //STUDY_CERTIFICATE
  @Effect()
  studyCertificate$: Observable<Action> = this.action$.pipe(
      ofType(actions.ActionTypes.STUDY_CERTIFICATE),
      map((action: actions.studyCertificateAction) => action.payload),
      switchMap(state => {
        console.log('EffectState',state);
          return this.api.getStudyCertificateApi(state).pipe(
              map(user => new actions.studyCertificateSuccess(user)),
              catchError(error => of(new actions.studyCertificateFail(error.error)))
          );
      })
  );

       // country list
    @Effect()
    countryList$: Observable<Action> = this.action$
        .pipe(
            ofType(actions.ActionTypes.COUNTRY_LIST),

            map((action: actions.CountryListAction) => action),
            switchMap((state) => {
                return this.api.countryListApi()
                    .pipe(
                        switchMap((user) => [
                            new actions.CountryListSuccess(user),
                        ]),
                        catchError(error => of(new actions.CountryListFail(error)))
                    );
            })
        );
    // region List
    @Effect()
    regionList$: Observable<Action> = this.action$
        .pipe(
            ofType(actions.ActionTypes.REGION_LIST),

            map((action: actions.RegionListAction) => action.payload),
            switchMap((state) => {
                return this.api.regionListApi(state)
                    .pipe(
                        switchMap((user) => [
                            new actions.RegionListSuccess(user),
                        ]),
                        catchError(error => of(new actions.RegionListFail(error)))
                    );
            })
        );
    // city list
    @Effect()
    cityList$: Observable<Action> = this.action$
        .pipe(
            ofType(actions.ActionTypes.CITY_LIST),

            map((action: actions.CityListAction) => action.payload),
            switchMap((state) => {
                return this.api.cityListApi(state)
                    .pipe(
                        switchMap((user) => [
                            new actions.CityListSuccess(user),
                        ]),
                        catchError(error => of(new actions.CityListFail(error)))
                    );
            })
        );

    public convertBase64PDFToBlobData(base64Data: string, 
      contentType: string = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', sliceSize = 512) {
        const byteCharacters = atob(base64Data.replace(/^data:([A-Za-z-+\/]+);base64,/, ''));
        const byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);

            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);

            byteArrays.push(byteArray);
        }

        const blob = new Blob(byteArrays, { type: contentType });
        return blob;
    }
    public convertBase64PDFToBlobData1(base64Data: string, contentType: string = 'text/html', sliceSize = 512) {
      const byteCharacters = atob(base64Data.replace(/^data:([A-Za-z-+\/]+);base64,/, ''));
      const byteArrays = [];

      for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
          const slice = byteCharacters.slice(offset, offset + sliceSize);

          const byteNumbers = new Array(slice.length);
          for (let i = 0; i < slice.length; i++) {
              byteNumbers[i] = slice.charCodeAt(i);
          }

          const byteArray = new Uint8Array(byteNumbers);

          byteArrays.push(byteArray);
      }

      const blob = new Blob(byteArrays, { type: contentType });
      return blob;
  }
    printPdfFile(base64content: any) {
        const blobData = this.convertBase64PDFToBlobData(base64content);
        const blob = new Blob([blobData], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        const instituteDetails = localStorage.getItem('instituteDetails') ? JSON.parse(localStorage.getItem('instituteDetails')) : false;
        let fileName = 'invalidStudent.xlsx';
        if (instituteDetails) {
          fileName =  instituteDetails.institudeCode + '_invalidStudent.xlsx';
        }
        // const fileName = 'invalidStudent.xlsx';
        document.body.appendChild(a);
        a.setAttribute('style', 'display: none');
        a.setAttribute('target', '_blank  ');
        a.href = url;
        // a.setAttribute('download', url);
        a.download = fileName;
        a.click();
        URL.revokeObjectURL(url);
        a.remove();
    }
     // id card generate
     printIdCard(base64content: any) {
        const blobData = this.convertBase64PDFToBlobData1(base64content);
        const blob = new Blob([blobData], { type: 'text/html' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        document.body.appendChild(a);
        a.setAttribute('style', 'display: none');
        a.setAttribute('target', '_blank  ');
        a.href = url;
        a.click();
        URL.revokeObjectURL(url);
        a.remove();
    }
}
