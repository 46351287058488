import {Map, Record} from 'immutable';

export interface ActivityState extends Map<string, any> {
    activityAllLoading: boolean;
    activityList: any;
    activityListCount: any;
    activityListAll: any;
    instituteListLoading: boolean;
    instituteList: any;
}

export const activityStateRecord = Record({
    activityAllLoading: false,
    activityList: [],
    activityListCount: {},
    activityListAll: [],
    instituteListLoading: false,
    instituteList: []

});
