import {AutoLogonState, autoStateRecord} from './auto-logon.state';
import * as actions from '../actions/auto-login.action';

export const initialState: AutoLogonState = new autoStateRecord() as unknown as AutoLogonState;

export function reducer(state = initialState, {type, payload}: any): AutoLogonState {
  if (!type) {
    return state;
  }

  switch (type) {
    /**
     * login reducer case function
     */
    case actions.ActionTypes.DO_AUTO_LOGIN:

      return Object.assign({}, state, {
        autoLogin: [],
        autoLoginLoading: true,
        autoLoginLoaded: false,
        autoLoginFailed: false,
      });

    case actions.ActionTypes.DO_AUTO_LOGIN_SUCCESS:
      return Object.assign({}, state, {
        autoLogin: payload,
        autoLoginLoading: false,
        autoLoginLoaded: true,
        autoLoginFailed: false,
      });

    case actions.ActionTypes.DO_AUTO_LOGIN_FAIL:

      return Object.assign({}, state, {
        autoLogin: [],
        autoLoginLoading: false,
        autoLoginLoaded: false,
        autoLoginFailed: true,

      });
    default: {
      return state;
    }
  }
}

/**
 * export values
 */

export const autoLogin = (state: AutoLogonState) => state.autoLogin;
export const autoLoginLoading = (state: AutoLogonState) => state.autoLoginLoading;
export const autoLoginLoaded = (state: AutoLogonState) => state.autoLoginLoaded;
export const autoLoginFailed = (state: AutoLogonState) => state.autoLoginFailed;

