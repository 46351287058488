export const environment = {
  production: true,
  // sampleUrl: 'http://vs4.voicesnapforschools.com/nodejs/csv',
  // fileUrl: 'http://vs4.voicesnapforschools.com/nodejs/api/file-report/file-download',
  // baseUrl: 'http://vs4.voicesnapforschools.com/nodejs/api',
  // url: 'http://vs6.voicesnapforschools.com',
  // downloadTemplateUrl: 'http://vs4.voicesnapforschools.com/nodejs/api/exam/download-exam-template',

  //  sampleUrl: 'http://localhost:8008/csv',
  //   fileUrl: 'http://localhost:8008/api/file-report/file-download',
  //   baseUrl: 'http://localhost:8008/api',
  //   url: 'http://vs6.voicesnapforschools.com',
  //   downloadTemplateUrl: 'http://localhost:8008/api/exam/download-exam-template',


  sampleUrl: 'http://vs5.voicesnapforschools.com/nodejs/csv',
  fileUrl: 'http://vs5.voicesnapforschools.com/nodejs/api/file-report/file-download',
  baseUrl: 'http://vs5.voicesnapforschools.com/nodejs/api',
  url: 'http://vs7.voicesnapforschools.com',
  downloadTemplateUrl: 'http://vs5.voicesnapforschools.com/nodejs/api/exam/download-exam-template',
};
