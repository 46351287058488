import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {StudentManagementSandbox} from '../../../pages/student-management/student-management.sandbox';
import {environment} from '../../../../environments/environment';
import moment from 'moment';


@Component({
  selector: 'app-file-details-report',
  templateUrl: './file-details-report.component.html',
  styleUrls: ['./file-details-report.component.scss']
})
export class FileDetailsReportComponent implements OnInit {
  standardDetailsId: any;
  fileUrl: any;
  constructor(
      public activeModal: NgbActiveModal,
      public studentManagementSandbox: StudentManagementSandbox) { }

  ngOnInit() {
    this.fileUrl = environment.baseUrl;
    if (this.standardDetailsId) {
      this.studentManagementSandbox.studentFileReport({studentId: this.standardDetailsId});
    }
  }

  getDate(date: any) {
    return moment(new Date(date)).add(5, 'hours')
    .add(30, 'minutes').format('DD-MM-YYYY hh:mm A');
  }

  downloadFile(file) {
    const documentUrl = this.fileUrl + '/file-report/file-download/?id=' + file.circularId;
    const a = document.createElement('a');
    // const filename = 'File_' + Date.now() + '.xlsx';
    document.body.appendChild(a);
    a.setAttribute('style', 'display: none');
    a.href = documentUrl;
    a.download = '_file';
    a.click();
    URL.revokeObjectURL(documentUrl);
    a.remove();
  }
  close() {
    this.activeModal.close(false);
  }

}
