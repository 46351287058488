import { Action } from '@ngrx/store';
import { type } from '../../../shared/utility';
import {ResponseInterface} from '../../../shared/interfaces/interface';

export const ActionTypes = {
  GET_ACTIVITY_LIST: type('[common] Get Activity List'),
  GET_ACTIVITY_LIST_SUCCESS: type('[common] Get Activity List Success'),
  GET_ACTIVITY_LIST_FAIL: type('[common] Get Activity List Fail'),

  GET_ACTIVITY_LIST_COUNT: type('[common] Get Activity List Count'),
  GET_ACTIVITY_LIST_COUNT_SUCCESS: type('[common] Get Activity List Count Success'),
  GET_ACTIVITY_LIST_COUNT_FAIL: type('[common] Get Activity List Count Fail'),

  GET_ACTIVITY_LIST_ALL: type('[common] Get Activity List all'),
  GET_ACTIVITY_LIST_ALL_SUCCESS: type('[common] Get Activity List all Success'),
  GET_ACTIVITY_LIST_ALL_FAIL: type('[common] Get Activity List all Fail'),

  INSTITUTE_LIST: type('[common] Institute List '),
  INSTITUTE_LIST_SUCCESS: type('[common] Institute List  Success'),
  INSTITUTE_LIST_FAIL: type('[common] Institute List  Fail'),
};

/**
 *  get school detail actions.
 */
// Get Activity List
export class GetActivityList implements Action {
  type = ActionTypes.GET_ACTIVITY_LIST;

  constructor(public payload: any) {}
}
export class GetActivityListSuccess implements Action {
  type = ActionTypes.GET_ACTIVITY_LIST_SUCCESS;

  constructor(public payload: ResponseInterface) {}
}

export class GetActivityListFail implements Action {
  type = ActionTypes.GET_ACTIVITY_LIST_FAIL;

  constructor(public payload: any) {}
}
// Get Activity List Count
export class GetActivityListCount implements Action {
  type = ActionTypes.GET_ACTIVITY_LIST_COUNT;

  constructor(public payload: any) {}
}
export class GetActivityListCountSuccess implements Action {
  type = ActionTypes.GET_ACTIVITY_LIST_COUNT_SUCCESS;

  constructor(public payload: ResponseInterface) {}
}

export class GetActivityListCountFail implements Action {
  type = ActionTypes.GET_ACTIVITY_LIST_COUNT_FAIL;

  constructor(public payload: any) {}
}

// Get Activity List All
export class GetActivityListAll implements Action {
  type = ActionTypes.GET_ACTIVITY_LIST_ALL;

  constructor(public payload: any) {}
}
export class GetActivityListAllSuccess implements Action {
  type = ActionTypes.GET_ACTIVITY_LIST_ALL_SUCCESS;

  constructor(public payload: ResponseInterface) {}
}

export class GetActivityListAllFail implements Action {
  type = ActionTypes.GET_ACTIVITY_LIST_ALL_FAIL;

  constructor(public payload: any) {}
}
// Institute List Action
export class InstituteList implements Action {
  type = ActionTypes.INSTITUTE_LIST;
}

export class InstituteListSuccess implements Action {
  type = ActionTypes.INSTITUTE_LIST_SUCCESS;

  constructor(public payload: ResponseInterface) {
  }
}

export class InstituteListActionFail implements Action {
  type = ActionTypes.INSTITUTE_LIST_FAIL;

  constructor(public payload: ResponseInterface) {
  }
}
// export type Actions =
//   | Activity
//   | ActivitySuccess
//   | ActivityFail;
