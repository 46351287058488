import { Action } from '@ngrx/store';
import { type } from '../../../shared/utility';
import {ResponseInterface} from '../../../shared/interfaces/interface';

export const ActionTypes = {
  DASHBOARD_LIST: type('[DASHBOARD] DASHBOARD_LIST'),
  DASHBOARD_LIST_SUCCESS: type('[DASHBOARD] DASHBOARD_LIST Success'),
  DASHBOARD_LIST_FAIL: type('[DASHBOARD] DASHBOARD_LIST Fail'),
  // renewal details
  RENEWAL_DETAILS_LIST: type('[DASHBOARD] RENEWAL_DETAILS_LIST'),
  RENEWAL_DETAILS_LIST_SUCCESS: type('[DASHBOARD] RENEWAL_DETAILS_LIST Success'),
  RENEWAL_DETAILS_LIST_FAIL: type('[DASHBOARD] RENEWAL_DETAILS_LIST Fail'),
};

/**
 *  get school detail actions.
 */
export class Dashboard implements Action {
  type = ActionTypes.DASHBOARD_LIST;
  constructor(public payload: any) {}
}

export class DashboardSuccess implements Action {
  type = ActionTypes.DASHBOARD_LIST_SUCCESS;
  constructor(public payload: ResponseInterface) {
  }
}
export class DashboardFail implements Action {
  type = ActionTypes.DASHBOARD_LIST_FAIL;
  constructor(public payload: any) {}
}
// renewal details
export class RenewalDetails implements Action {
  type = ActionTypes.RENEWAL_DETAILS_LIST;
  constructor(public payload: any) {}
}

export class RenewalDetailsSuccess implements Action {
  type = ActionTypes.RENEWAL_DETAILS_LIST_SUCCESS;
  constructor(public payload: ResponseInterface) {
  }
}
export class RenewalDetailsFail implements Action {
  type = ActionTypes.RENEWAL_DETAILS_LIST_FAIL;
  constructor(public payload: any) {}
}
export type Actions =
  | Dashboard
  | DashboardSuccess
  | DashboardFail;
