import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { CommonSandbox } from 'src/app/common/common.sandbox';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
//import { AcademicYearDetailsComponent } from '../../popup/academic-year-details/academic-year-details.component';
import {environment} from "../../../../environments/environment";

@Component({
  selector: 'app-common-header',
  templateUrl: './common-header.component.html',
  styleUrls: ['./common-header.component.scss']
})
export class CommonHeaderComponent implements OnInit {
  @Input() userdetail: any;
  @Input() institutedetail: any;
  redirectUrl = environment.url;
  login: string;

  constructor(public router: Router, public commonSandBox: CommonSandbox,
    private modalService: NgbModal) { }

  ngOnInit() {
    this.login = localStorage.getItem('islogin');
  }
  doLogout() {
    if (localStorage.getItem('superAdmin') === 'yes') {
      localStorage.removeItem('instituteToken');
      localStorage.removeItem('superAdmin');
      localStorage.removeItem('instituteToken');
      localStorage.removeItem('roleSlug');
      localStorage.removeItem('instituteDetails');
      localStorage.removeItem('userMenu');
      window.open(this.redirectUrl, '_self');
    } else {
      this.router.navigate(['/auth']);
      localStorage.clear();
    }
  }

  doLogout1() {

    localStorage.removeItem('instituteToken');
    localStorage.removeItem('Childdetails');
    window.open('http://messenger.voicesnapforschools.com/', '_self');
}
  academicYear() {
    // const modalRef = this.modalService.open(AcademicYearDetailsComponent, {
    //   windowClass: 'busfeepayment',
    //   centered: true,
    //   size: 'sm'
    // });
    // const modalRef = this.modalService.open(AcademicYearDetailsComponent, {backdrop: 'static', windowClass: 'custom-term-other'});
    // modalRef.result.then(result => {
    //   if (result) {
    //     // this.getOtherFeeList();
    //   }
    // });
    // modalRef.componentInstance.busStudentId = id;

  }
}
