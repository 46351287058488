import {Map, Record} from 'immutable';

export interface AutoLogonState extends Map<string, any> {
    autoLogin: any;
    autoLoginLoading: boolean;
    autoLoginLoaded: boolean;
    autoLoginFailed: boolean;
}

export const autoStateRecord = Record({

    autoLogin: [],
    autoLoginLoading: false,
    autoLoginLoaded: false,
    autoLoginFailed: false,
});

