import { LoginState, LoginStateRecord } from './login.state';
import * as actions from '../actions/login.action';

export const initialState: LoginState = (new LoginStateRecord() as unknown) as LoginState;

export function reducer(
  state = initialState,
  { type, payload }: any
): LoginState {
  if (!type) {
    return state;
  }

  switch (type) {
    /**
     * Login
     */
    case actions.ActionTypes.LOGIN:
      return Object.assign({}, state, {
        loginLoading: true,
        loginLoaded: false,
        loginFailed: false,
      });
    case actions.ActionTypes.LOGIN_SUCCESS:
      return Object.assign({}, state, {
        login: payload.data,
        loginLoading: false,
        loginLoaded: true,
        loginFailed: false,
      });
    case actions.ActionTypes.LOGIN_FAIL:
      return Object.assign({}, state, {
        loginLoading: false,
        loginLoaded: false,
        loginFailed: true,
      });

    //   case actions.ActionTypes.RENEWAL_DETAILS_LIST:
    //   return Object.assign({}, state, {
    //     renewalDetailsLoading: true,
    //     renewalDetailsLoaded: false,
    //     renewalDetailsFailed: false,
    //   });
    // case actions.ActionTypes.RENEWAL_DETAILS_LIST_SUCCESS:
    //   return Object.assign({}, state, {
    //     renewalDetails: payload.data,
    //     renewalDetailsLoading: false,
    //     renewalDetailsLoaded: true,
    //     renewalDetailsFailed: false,
    //   });
    // case actions.ActionTypes.RENEWAL_DETAILS_LIST_FAIL:
    //   return Object.assign({}, state, {
    //     renewalDetailsLoading: false,
    //     renewalDetailsLoaded: false,
    //     renewalDetailsFailed: true,
    //   });
    default: {
      return state;
    }
  }
}

/**
 * export values
 */

export const login = (state: LoginState) => state.login;
export const loginLoading = (state: LoginState) => state.loginLoading;
export const loginLoaded = (state: LoginState) => state.loginLoaded;
export const loginFailed = (state: LoginState) => state.loginFailed;

export const renewalDetails = (state: LoginState) => state.renewalDetails;
export const renewalDetailsLoading = (state: LoginState) => state.renewalDetailsLoading;
export const renewalDetailsLoaded = (state: LoginState) => state.renewalDetailsLoaded;
export const renewalDetailsFailed = (state: LoginState) => state.renewalDetailsFailed;
