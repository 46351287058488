import {Component, Input, OnChanges, OnInit} from '@angular/core';

@Component({
  selector: 'app-certificate-print',
  templateUrl: './certificate-print.component.html',
  styleUrls: ['./certificate-print.component.scss']
})
export class CertificatePrintComponent implements OnInit, OnChanges {
@Input() printData: any;
  instituteLogo: any;
  defaultInstituteLogo = 'assets/imgs/school-logo.png';

  constructor() { }

  ngOnInit() {
    this.instituteLogo = JSON.parse(localStorage.getItem('instituteDetails'));
  }
  ngOnChanges() {
    if (this.printData) {
      setTimeout(() => {
        let printContents, popupWin;
        printContents = document.getElementById('print').innerHTML;
        popupWin = window.open(
            '',
            '_blank',
            'top=0,left=0,height=100%,width=auto'
        );
        popupWin.document.open();
        popupWin.document.write(`
          <html>
            <head>
              <style>
              </style>
            </head>
            <body onload="window.print();window.close()">${printContents}</body>
          </html>`);
        popupWin.document.close();
      }, 400);
    }
  }

}
