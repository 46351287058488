import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ResponseInterface } from '../shared/interfaces/interface';
import { Api } from '../providers/api/api';
import { HttpParams } from '@angular/common/http';

@Injectable()

export class CommonService extends Api {

  private URL: string = this.getBaseUrl();

  getAvatharDetail(): Observable<ResponseInterface> {
    return this.http.get<ResponseInterface>(this.URL + '/auth/me');
  }
  changePassword(params): Observable<ResponseInterface> {
    return this.http.post<ResponseInterface>(this.URL + '/auth/change-password', params);
  }
  studentBulkUpload(params) {
    const formData = new FormData();
    formData.append('studentData', params.studentDocument);
    return this.http.post<ResponseInterface>(this.URL + '/import/import-student', formData);
  }
  staffBulkUpload(params) {
    const formData = new FormData();
    formData.append('staffData', params.staffDocument);
    return this.http.post<ResponseInterface>(this.URL + '/import/import-staff', formData);
  }
  monthList(params) {
    return this.http.get<ResponseInterface>(this.URL + '/institute/institute-months-list');
  }
  AcademicList(params) {
    return this.http.post<ResponseInterface>(this.URL + '/academic-year/list', params);
  }
  AcademicMonthList(params) {
    return this.http.get<ResponseInterface>(this.URL + '/institute/months-list');
  }
  GetAcademicYearSetting(params) {
    return this.http.get<ResponseInterface>(this.URL + '/academic-year/get-institute-setting');
  }
  ChangeAcademicYearSetting(params): Observable<ResponseInterface> {
    return this.http.post<ResponseInterface>(this.URL + '/academic-year/institute-setting', params);
  }
  getStudentTemplate(): Observable<any> {
    const reqOpts: any = {};
    reqOpts.responseType = 'arraybuffer';
    // if (params) {
    //   reqOpts.params = new HttpParams();
    //   for (const k in params) {
    //     if (k) {
    //       reqOpts.params = reqOpts.params.set(k, params[k]);
    //     }
    //   }
    // }
    return this.http.get<ResponseInterface>(this.URL + '/student/upload-student-template', reqOpts);
  }
  GetCallFlowPdf(params): Observable<ResponseInterface> {
    return this.http.get<ResponseInterface>(this.URL + '/help/call-flow-pdf');
  }
  GetIvrCodePdf(params): Observable<ResponseInterface> {
    return this.http.get<ResponseInterface>(this.URL + '/help/ivr-code-pdf');
  }
  GetAppUserPdf(params): Observable<ResponseInterface> {
    return this.http.get<ResponseInterface>(this.URL + '/help/voice-pdf');
  }
  GetYoutubeManualPdf(params): Observable<ResponseInterface> {
    return this.http.get<ResponseInterface>(this.URL + '/help/youtube-manual-pdf');
  }

  feesInvoiceData(params) {
    console.log('fiid', params);
    const id = params;
    return this.http.get<ResponseInterface>(this.URL + '/fee-report/download-invoice/' + id);
  }

  getdownloadedfees(): Observable<any> {
    const reqOpts: any = {};
    reqOpts.responseType = 'arraybuffer';
    return this.http.get<ResponseInterface>(this.URL + '/fee-report/downloadfees/', reqOpts);
  }
}
