export class DetailedReportAssignmentResponseModel {
    public memberid: any;
    public studentName: any;
    public Mobileno: any;
    public assignmentid : any;
    public appviewed: any;



    constructor(textMsgDetails: any) {
     this.memberid = textMsgDetails.memberid || '';
     this.assignmentid = textMsgDetails.assignmentid || '';
     this.studentName = textMsgDetails.studentName || '';
     this.Mobileno = textMsgDetails.Mobileno || '';
     this.appviewed = textMsgDetails.appviewed || '';
    
    }
}
