import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { SwiperModule } from 'ngx-swiper-wrapper';

// components
import { CommonHeaderComponent } from './components/common-header/common-header.component';
import { FooterComponent } from './components/footer/footer.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { BreadcrumbsComponent } from './components/breadcrumbs/breadcrumbs.component';
import { LoaderComponent } from './components/loader/loader.component';
import { PageLoaderComponent } from './components/page-loader/page-loader.component';
import { SelectDropDownModule } from 'ngx-select-dropdown';
import {
  MatDatepickerModule,
  MatNativeDateModule,
  MatFormFieldModule,
  MatInputModule,
  MAT_DATE_LOCALE,
  MatTooltipModule
} from '@angular/material';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { NoDataAvailableComponent } from './components/no-data-available/no-data-available.component';

import { EffectsModule } from '@ngrx/effects';
import { StudentManagementEffect } from '../pages/student-management/effects/student-management.effects';
import { StudentManagementSandbox } from '../pages/student-management/student-management.sandbox';
import { StudentManagementService } from '../pages/student-management/student-management.service';

import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NumberAcceptModule } from './validation-directives/onlyNumber.module';




import { SideScroll } from './utility/sidebar.directive';

import { InvoiceFormComponent } from './components/invoice-form/invoice-form.component';
import { DriverDetailsFormComponent } from './components/driver-details-form/driver-details-form.component';

// import { ApplyDiscountComponent } from './popup/apply-discount/apply-discount.component';

import { PipeComponent } from './components/pipes/pipe.component';
import {NgSelectModule} from '@ng-select/ng-select';
import { ChartsModule } from 'ng2-charts';

// import { OtherReportListComponent } from './popup/other-report-list/other-report-list.component';
import { JwPaginationComponent } from 'jw-angular-pagination';
import { FileDetailsReportComponent } from './popup/file-details-report/file-details-report.component';
import { CertificatePrintComponent } from './components/certificate-print/certificate-print.component';
import { StudentPrintComponent } from './components/student-print/student-print.component';

@NgModule({

  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    SwiperModule,
    SelectDropDownModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatFormFieldModule,
    MatInputModule,
    NgbModule,
    NumberAcceptModule,
    MatTooltipModule,
    NgSelectModule,
    EffectsModule.forFeature([StudentManagementEffect])
  ],
  exports: [
    CommonModule,
    RouterModule,
    SwiperModule,
    SelectDropDownModule,
    CommonHeaderComponent,
    FooterComponent,
    SidebarComponent,
    BreadcrumbsComponent,
    LoaderComponent,
    PageLoaderComponent,
    MatDatepickerModule,
    MatNativeDateModule,
    MatFormFieldModule,
    MatInputModule,
    NgbModule,
    NoDataAvailableComponent,
    SideScroll,
    InvoiceFormComponent,
    DriverDetailsFormComponent,
    MatTooltipModule,
    PipeComponent,
    NgSelectModule,
    ChartsModule,
    JwPaginationComponent
  ],
  declarations: [
   
    CommonHeaderComponent,
    FooterComponent,
    SidebarComponent,
    BreadcrumbsComponent,
    LoaderComponent,
    PageLoaderComponent,
   
    NoDataAvailableComponent,
    
    SideScroll,
    InvoiceFormComponent,
    DriverDetailsFormComponent,
    
    PipeComponent,
    
    JwPaginationComponent,
    FileDetailsReportComponent,
    StudentPrintComponent,
    CertificatePrintComponent
    
    ],
  entryComponents: [
   
    // ApplyDiscountComponent,
    

  ],
  providers: [
    StudentManagementSandbox,
    StudentManagementService,
   

   

    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' }
  ]
})
export class SharedModule {}
